<template>
  <v-card :loading='loading'>
    <v-card-title>
      <h4>マネージャー情報</h4>
    </v-card-title>
    <v-divider />
    <v-simple-table
      class='mouse-over-highlight-disabled'
    >
      <tbody>
        <tr>
          <td class='font-weight-bold'>Eメール</td>
          <td>{{ manager.email }}</td>
        </tr>
        <tr>
          <td class='font-weight-bold'>担当者名</td>
          <td>{{ manager.profile.staff_name }}</td>
        </tr>
        <tr>
          <td class='font-weight-bold'>ステータス</td>
          <td>{{ $$getEnumText('account_status', manager.account_status) }}</td>
        </tr>
        <tr>
          <td class='font-weight-bold'>電話番号</td>
          <td>{{ manager.profile.phone }}</td>
        </tr>
        <tr>
          <td class='font-weight-bold'>会社名</td>
          <td>{{ manager.profile.corporation_name }}</td>
        </tr>
        <tr>
          <td class='font-weight-bold'>ホームページ</td>
          <td>{{ manager.profile.homepage_url }}</td>
        </tr>
        <tr>
          <td class='font-weight-bold'>コミュニティ</td>
          <td>
            <v-chip
              v-for='club in manager.clubs'
              :key='club.id'
              class='mr-1'
              v-on:click='clubShow(club)'
            >
              {{ club.name }}
            </v-chip>
          </td>
        </tr>
        <tr>
          <td class='font-weight-bold'>更新日</td>
          <td>{{ manager.updated_at | moment_format('lll') }}</td>
        </tr>
        <tr>
          <td class='font-weight-bold'>作成日</td>
          <td>{{ manager.created_at | moment_format('lll') }}</td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-card>
</template>

<script>
  export default {
    props: {
      manager: { type: Object, required: true },
      loading: { type: Boolean, required: true },
    },
    methods: {
      clubShow(club) {
        this.$router.push({ name: 'admin-clubs-show', params: { id: club.id } })
      },
    }
  }
</script>
