var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-10"},[_c('v-row',{staticClass:"mb-8"},[_c('v-col',{attrs:{"align":"center"}},[_c('h2',[_vm._v("Fums 運営アカウント登録")])])],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('validation-observer',{ref:"observer"},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.signup($event)}}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|max:256"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"email","error-messages":errors,"outlined":""},model:{value:(_vm.auth.email),callback:function ($$v) {_vm.$set(_vm.auth, "email", $$v)},expression:"auth.email"}})]}}])}),_c('validation-provider',{attrs:{"name":"担当者名","rules":"required|max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"担当者名","error-messages":errors,"outlined":""},model:{value:(_vm.auth.staff_name),callback:function ($$v) {_vm.$set(_vm.auth, "staff_name", $$v)},expression:"auth.staff_name"}})]}}])}),_c('validation-provider',{attrs:{"name":"電話番号","rules":"required|min:10|max:13"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"電話番号","error-messages":errors,"outlined":""},model:{value:(_vm.auth.phone),callback:function ($$v) {_vm.$set(_vm.auth, "phone", $$v)},expression:"auth.phone"}})]}}])}),_c('validation-provider',{attrs:{"name":"会社名","rules":"max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"会社名","error-messages":errors,"outlined":""},model:{value:(_vm.auth.corporation_name),callback:function ($$v) {_vm.$set(_vm.auth, "corporation_name", $$v)},expression:"auth.corporation_name"}})]}}])}),_c('validation-provider',{attrs:{"name":"ホームページ","rules":"max:2048"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"ホームページ","error-messages":errors,"outlined":""},model:{value:(_vm.auth.homepage_url),callback:function ($$v) {_vm.$set(_vm.auth, "homepage_url", $$v)},expression:"auth.homepage_url"}})]}}])}),_c('v-divider',{staticClass:"mb-8"}),_c('validation-provider',{attrs:{"name":"password","rules":"required|min:8|max:256|confirmed:confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"password","label":"password","error-messages":errors,"outlined":""},model:{value:(_vm.auth.password),callback:function ($$v) {_vm.$set(_vm.auth, "password", $$v)},expression:"auth.password"}})]}}])}),_c('validation-provider',{attrs:{"vid":"confirmation","name":"password（再入力）","rules":"required|min:8|max:256"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"password","label":"password（再入力）","error-messages":errors,"outlined":""},model:{value:(_vm.auth.password_confirmation),callback:function ($$v) {_vm.$set(_vm.auth, "password_confirmation", $$v)},expression:"auth.password_confirmation"}})]}}])}),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-btn',{attrs:{"type":"submit","color":"primary","block":"","outlined":""}},[_vm._v("SIGNUP")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }