<template>
  <v-card :loading='loading'>
    <v-card-title>
      <v-row>
        <v-col cols='12' md='8'>
          <h5>デフォルトで加入するコミュニティ</h5>
        </v-col>
        <v-col cols='12' md='4' align='right'>
          <v-btn
            outlined
            small
            v-on:click='$emit("edit")'
          >
            編集
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider />
    <v-card-text v-if='club.name'>
      {{ club.name }}
    </v-card-text>
    <v-card-text v-else>
      登録されていません
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    props: {
      club: { type: Object, required: true },
      loading: { type: Boolean, required: true },
    },
  }
</script>
