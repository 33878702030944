<template>
  <v-navigation-drawer
    v-model='internalValue'
    app
    clipped
    floating
    color="grey lighten-3"
  >
    <AdminDrawerList v-if='isSignInType.admin' />
    <ManagerDrawerList v-if='isSignInType.manager' />
  </v-navigation-drawer>
</template>

<script>
  import AdminDrawerList from '../globals/admin/DrawerList.vue';
  import ManagerDrawerList from '../globals/manager/DrawerList.vue';

  export default {
    components: {
      AdminDrawerList,
      ManagerDrawerList
    },
    props: {
      value: { required: true },
    },
    computed: {
      internalValue: {
        get() {return this.value},
        set(value) {this.$emit('input', value)},
      },
      isSignInType() {
        return {
          admin: this.$store.getters.admin,
          manager: this.$store.getters.manager
        }
      }
    }
  };
</script>
