<template>
  <div class='pa-10'>
    <v-row class='mb-4'>
      <v-col cols='12' md='8'>
        <h2>Event投稿</h2>
      </v-col>
      <v-col cols='12' md='4' align='right'>
        <v-btn
          color='primary'
          text
          v-on:click='toIndex'
        >
          一覧に戻る
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <validation-observer ref='observer' v-if='$$exist(timeline)'>
      <v-form v-on:submit.prevent='save'>
        <v-card
          :loading='loading'
          class='mb-8'
        >
          <v-card-text class='pb-0'>
            <v-row>
              <v-col cols='12' md='12' class='py-0'>
                <validation-provider
                  v-slot='{ errors }'
                  name='タイトル'
                  rules='required|max:100'
                >
                  <v-text-field
                    v-model='timeline.activity.title'
                    label='タイトル'
                    :error-messages='errors'
                    outlined
                    counter='100'
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col cols='12' md='12' class='py-0'>
                <validation-provider
                  v-slot='{ errors }'
                  name='サブタイトル'
                  rules='required|max:100'
                >
                  <v-text-field
                    v-model='timeline.activity.subtitle'
                    label='サブタイトル'
                    :error-messages='errors'
                    outlined
                    counter='100'
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col cols='12' md='6' class='py-0'>
                <validation-provider
                  v-slot='{ errors }'
                  name='イベント開催日'
                  rules='required'
                >
                  <datetime
                    v-model='timeline.activity.open_st_at'
                    label='イベント開催日'
                    :error-messages='errors'
                    outlined
                    type='date'
                  />
                </validation-provider>
              </v-col>
              <v-col cols='12' md='6' class='py-0'>
                <validation-provider
                  v-slot='{ errors }'
                  name='イベント終了日'
                  rules=''
                >
                  <datetime
                    v-model='timeline.activity.open_ed_at'
                    label='イベント終了日'
                    :error-messages='errors'
                    outlined
                    type='date'
                  />
                </validation-provider>
              </v-col>
              <v-col cols='12' md='6' class='py-0'>
                <validation-provider
                  v-slot='{ errors }'
                  name='チケットお申し込み開始日'
                  rules=''
                >
                  <datetime
                    v-model='timeline.activity.ticket_st_at'
                    label='チケットお申し込み開始日'
                    :error-messages='errors'
                    outlined
                    type='date'
                  />
                </validation-provider>
              </v-col>
              <v-col cols='12' md='6' class='py-0'>
                <validation-provider
                  v-slot='{ errors }'
                  name='チケットお申し込み終了日'
                  rules=''
                >
                  <datetime
                    v-model='timeline.activity.ticket_ed_at'
                    label='チケットお申し込み終了日'
                    :error-messages='errors'
                    outlined
                    type='date'
                  />
                </validation-provider>
              </v-col>
              <v-col cols='12' md='12' class='py-0'>
                <validation-provider
                  v-slot='{ errors }'
                  name='投稿内容'
                  rules='required|max:1000'
                >
                  <v-textarea
                    v-model='timeline.activity.desc'
                    label='投稿内容'
                    :error-messages='errors'
                    :rows='3'
                    auto-grow
                    outlined
                    counter='1000'
                  ></v-textarea>
                </validation-provider>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <FormTransmission
          class='mb-8'
          v-model='timeline.transmissions'
          :loading='loading'
          :initial-content-type='timeline.gallery_type'
          :max-photo-files='4'
        />
        <FormReserveSend
          class='mb-8'
          v-model='timeline.disp_st_at'
          :loading='loading'
        />
        <v-row justify='center'>
          <v-btn
            :loading='loading'
            type='submit'
            color='primary'
            class='px-16'
            outlined
          >
            投稿する
          </v-btn>
        </v-row>
      </v-form>
    </validation-observer>
  </div>
</template>

<script>
  import FormTransmission from '@/components/parts/Form/Transmission'
  import FormReserveSend from '@/components/parts/Form/ReserveSend'
  export default {
    components: {
      FormTransmission,
      FormReserveSend,
    },
    data: () => ({
      loading: false,
      timeline: {
        disp_st_at: '',
        activity: {
          title: '',
          subtitle: '',
          desc: '',
          open_st_at: '',
          open_ed_at: '',
          ticket_st_at: '',
          ticket_ed_at: '',
        },
        transmissions: []
      },
    }),
    created() {
      this.getTimeline()
    },
    computed: {
      isNewForm() {
        return !this.$route.params.id
      },
      PATH() {
        const clubId = this.$store.getters.club.id
        return {
          create: `/api/v1/manager/clubs/${clubId}/timelines/activities`,
          edit:   `/api/v1/manager/clubs/${clubId}/timelines/activities/${this.$route.params.id}`,
          update: `/api/v1/manager/clubs/${clubId}/timelines/activities/${this.$route.params.id}`,
        }
      }
    },
    methods: {
      getTimeline() {
        if (this.isNewForm) { return }
        this.loading = true
        this.axios.get(this.PATH.edit).then(res => {
          this.timeline = res.data.timeline
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          this.loading = false
        })
      },
      async save() {
        if (this.loading) { return }
        const isValid = await this.$refs.observer.validate()
        if (!isValid) { return this.$toasted.error(this.$t('errors.params')) }
        this.loading = true
        const path = this.isNewForm ? this.PATH.create : this.PATH.update
        const saveFunction = this.isNewForm ? this.axios.post : this.axios.patch
        saveFunction(
          path,
          this.$$castToFormData({ timeline: this.timeline }),
          { headers: { "content-type": "multipart/form-data" } }
        ).then(res => {
          this.timeline = res.data.timeline
          this.$router.push({ name: 'manager-activities-index' })
          this.$toasted.success(this.$t('success.update'))
        }).catch(err => {
          if (err.response.status !== 422) { return }
          this.$toasted.error(err.response.data.errors.join("\n"))
        }).finally(() => {
          this.loading = false
        })
      },
      toIndex() {
        this.$router.push({ name: 'manager-activities-index' })
      },
    },
    metaInfo() { return this.$t(`meta_tags.${this.$route.name}`) },
  }
</script>
