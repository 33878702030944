import VueToasted from 'vue-toasted'

const PluginVueToasted = {
  install(Vue) {
    Vue.use(VueToasted, {
      position: 'top-center',
      fullWidth: true,
      fitToScreen: true,
      keepOnHover: true,
      className: 'word-breaker',
      duration: 5000,
      action : {
        text : 'close',
        onClick : (e, toastObject) => {
          toastObject.goAway(0);
        }
      },
    })
  }
}

export default PluginVueToasted
