<template>
  <v-dialog v-model='dialog'>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        outlined
        small
      >
        {{ title }}
      </v-btn>
    </template>
    <v-card class='pa-4'>
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text>
        <v-form
          v-if='showSelect'
          v-on:submit.prevent='searchStart'
          class='mb-10'
        >
          <v-row>
            <v-col cols='12' md='6'>
              <v-text-field
                v-model='search.keyword'
                label='キーワード'
                hide-details
                clearable
              ></v-text-field>
            </v-col>
            <v-col cols='12' md='3'>
              <datetime
                v-model='search.birthday'
                label='生年月日'
                hide-details
                type='date'
              />
            </v-col>
            <v-col cols='12' md='3'>
              <v-select
                v-model='search.gender'
                label='性別'
                hide-details
                clearable
                :items='selectItems.gender'
              ></v-select>
            </v-col>
          </v-row>
          <v-row class='mb-4'>
            <v-col cols='12' md='3'>
              <v-select
                v-model='search.pref'
                label='都道府県'
                hide-details
                clearable
                :items='selectItems.pref'
              ></v-select>
            </v-col>
            <v-col cols='12' md='3'>
              <datetime
                v-model='search.created_at'
                label='入会年月'
                hide-details
                type='date'
              />
            </v-col>
            <v-col cols='12' md='3'>
              <v-select
                v-model='search.plan_type'
                label='入会プラン'
                hide-details
                clearable
                :items='selectItems.plan_type'
              ></v-select>
            </v-col>
            <v-col cols='12' md='3' align='right'>
              <v-btn
                type='submit'
                class='mt-3 black white--text'
              >
                絞り込む
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
        <v-card>
          <v-data-table
            :loading='loading'
            v-model='selectedUsersClone'
            :headers='headers'
            :items='users'
            item-key='user.id'
            :show-select='showSelect'
            v-on:click:row='clickRow'
          >
            <template #item.user.profile.birthday='{ item }'>
              {{ item.user.profile.birthday | moment_format('ll') }}
            </template>
            <template #item.user.profile.gender='{ item }'>
              {{ $$getEnumText('gender', item.user.profile.gender) }}
            </template>
            <template #item.user.profile.pref='{ item }'>
              {{ $$getEnumText('pref', item.user.profile.pref) }}
            </template>
            <template #item.user_item_status='{ item }'>
              {{ $$getEnumText('user_item_status', item.user_item_status) }}
            </template>
            <template #item.created_at='{ item }'>
              {{ item.created_at | moment_format('ll') }}
            </template>
          </v-data-table>
        </v-card>
      </v-card-text>
      <v-card-actions class='mt-4 mr-2'>
        <v-spacer />
          <template v-if='showSelect'>
            <v-btn
              v-on:click='dialog = false'
              outlined
            >
              キャンセル
            </v-btn>
            <v-btn
              color='primary'
              v-on:click='decide'
              outlined
            >
              決定
            </v-btn>
          </template>
          <template v-else>
            <v-btn
              v-on:click='dialog = false'
              outlined
            >
              close
            </v-btn>
          </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: {
      title: { type: String, required: true },
      loading: { type: Boolean, default: false },
      selectedUsers: { type: Array, default: () => {[]} },
      users: { type: Array, default: () => {[]} },
      showSelect: { type: Boolean, default: false }
    },
    data: () => ({
      dialog: false,
      selectItems: {},
      search: {},
      headers: [],
      selectedUsersClone: [],
    }),
    created() {
      this.init()
    },
    watch: {
      dialog(value) {
        if (!value) { return }
        this.$emit('init')
        if (!this.showSelect) { return }
        const shapedParams = this.selectedUsers.map(user => ({ user: { id: user.id }}))
        this.selectedUsersClone = shapedParams
      },
    },
    computed: {
      internalValue: {
        get() { return this.value },
        set(value) { this.$emit('input', value) }
      },
    },
    methods: {
      init() {
        this.selectItems = {
          gender: this.$$selectEnums('gender'),
          pref: this.$$selectEnums('pref'),
          plan_type: [
            { text: '未選択', value: 'none' },
            { text: 'あああ', value: 'aaa' },
            { text: 'いいい', value: 'bbb' }
          ]
        }
        this.headers = [
          { text: 'ニックネーム', value: 'user.profile.nickname', sortable: false },
          { text: '生年月日', value: 'user.profile.birthday' },
          { text: '性別', value: 'user.profile.gender' },
          { text: 'お住まいの都道府県', value: 'user.profile.pref' },
          { text: '入会年月日', value: 'created_at' },
          { text: 'ステータス', value: 'user_item_status' }
        ]
      },
      clickRow(item, opts) {
        opts.select(!opts.isSelected)
      },
      decide() {
        const userIds = this.selectedUsersClone.map(userClub => ({ id: userClub.user.id }))
        this.$emit('decide', userIds)
        this.dialog = false
      },
      searchStart() {
        this.$emit('search', this.search)
      },
    },
    metaInfo() { return this.$t(`meta_tags.${this.$route.name}`) },
  }
</script>
