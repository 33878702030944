<template>
  <v-card :loading='loading'>
    <v-card-subtitle>
      予約投稿日時
    </v-card-subtitle>
    <VDivider />
    <v-card-text>
      <v-row>
        <v-col cols='12' md='6'>
          <v-radio-group
            v-model='selectReserve'
            row
            mandatory
          >
            <v-radio
              label='すぐに投稿する'
              value='soon'
            ></v-radio>
            <v-radio
              label='時間を指定して投稿する'
              value='reserve'
            ></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols='12' md='6' v-if='showDispStAt'>
          <validation-provider
            v-slot='{ errors }'
            name='投稿日時'
            rules='required'
          >
            <datetime
              v-model='internalValue'
              label='投稿日時'
              :error-messages='errors'
              outlined
            />
          </validation-provider>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    props: {
      value: { required: true },
      loading: { type: Boolean, required: true },
    },
    data: () => ({
      selectReserve: null,
      formattedValue: null,
    }),
    computed: {
      internalValue: {
        get() { return this.value },
        set(value) { this.$emit('input', value) }
      },
      showDispStAt() {
        return this.selectReserve === 'reserve'
      },
    },
  }
</script>
