<template>
  <v-dialog
    v-model='internalValue'
    :max-width='400'
  >
    <v-card>
      <v-card-title>
        プロダクトID
      </v-card-title>
      <VDivider class='mb-4'/>
      <v-card-text>
        <v-row>
          <v-col align='center'>
            プロダクトIDをSTOREに登録してください
            <v-btn
              class='ma-4'
              v-for='store in stores'
              :key='store.name'
              :href='store.href'
              target='_blank'
              :width='100'
              outlined
            >
              {{ store.name }}
            </v-btn>
          </v-col>
        </v-row>
        <v-text-field
          label='プロダクトID'
          :value='productId'
          readonly
          append-outer-icon='mdi-content-copy'
          v-on:click:append-outer='clipboardCopy'
        />
      </v-card-text>
      <v-card-actions>
        <VSpacer />
        <v-btn
          v-on:click='internalValue = false'
        >
          閉じる
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: {
      value: { type: Boolean, required: true },
      productId: { type: String, required: true }
    },
    computed: {
      internalValue: {
        get() {return this.value},
        set(value) {this.$emit('input', value)},
      },
      stores() {
        return [
          {
            name: 'apple',
            href: 'https://appstoreconnect.apple.com/apps'
          },
          {
            name: 'google',
            href: 'https://play.google.com/console/u/0/developers/6270782913037590638/app/4974074906425604671/app-dashboard'
          },
        ]
      }
    },
    methods: {
      clipboardCopy() {
        navigator.clipboard.writeText(this.productId).then(() => {
          this.$toasted.success(this.$t('success.clipboard_copy'))
        }).catch(err => {
          console.log(err)
        })
      },
    }
  }
</script>
