<template>
  <div class='pa-10'>
    <v-row class='mb-4'>
      <v-col cols='12' md='8'>
        <h2>マネージャー情報編集</h2>
      </v-col>
    </v-row>

    <v-card
      class='mb-6 pa-6'
    >
      <validation-observer ref='observer'>
        <v-form
          v-on:submit.prevent='update'
        >
          <validation-provider
            v-slot='{ errors }'
            name='担当者氏名'
            rules='required'
          >
            <v-text-field
              v-model='profile.staff_name'
              outlined
              label='担当者氏名'
              :error-messages='errors'
            ></v-text-field>
          </validation-provider>
          <validation-provider
            v-slot='{ errors }'
            name='電話番号'
            rules='required'
          >
            <v-text-field
              v-model='profile.phone'
              outlined
              label='電話番号'
              :error-messages='errors'
            ></v-text-field>
          </validation-provider>
          <validation-provider
            v-slot='{ errors }'
            name='会社名'
            rules='required'
          >
            <v-text-field
              v-model='profile.corporation_name'
              outlined
              label='会社名'
              :error-messages='errors'
            ></v-text-field>
          </validation-provider>
          <validation-provider
            v-slot='{ errors }'
            name='会社名'
            rules='required'
          >
            <v-text-field
              v-model='profile.homepage_url'
              outlined
              label='ホームページURL'
              :error-messages='errors'
            ></v-text-field>
          </validation-provider>
          <v-row justify='center'>
            <v-btn
              type='submit'
              color='primary'
              class='px-16 mt-5 font-weight-bold'
            >
              編集内容を保存する
            </v-btn>
          </v-row>
        </v-form>
      </validation-observer>
    </v-card>
  </div>
</template>

<script>
  export default {
    data: () => ({
      profile: {}
    }),
    created() {
      this.getProfile()
    },
    methods: {
      getProfile() {
        this.axios.get('/api/v1/manager/profile').then(res => {
          this.profile = res.data.profile
        }).catch(err => {
          console.log(err)
        })
      },
      update() {
        this.axios.patch('/api/v1/manager/profile', this.profile).then(res => {
          // TODO: Notice系で成功を教えてあげる
          console.log(res)
          this.$router.push({ name: 'manager-profiles-show' })
        }).catch(err => {
          // TODO: Notice系で失敗を教えてあげる
          console.log(err)
        })
      }
    },
    metaInfo() { return this.$t(`meta_tags.${this.$route.name}`) },
  }
</script>
