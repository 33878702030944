<template>
  <div class='pa-10'>
    <v-row class='mb-4'>
      <v-col cols='12' md='8'>
        <h2>会員一覧</h2>
      </v-col>
      <v-col cols='12' md='4' align='right'>
      </v-col>
    </v-row>

    <v-form
      v-on:submit.prevent='searchUsers'
      class='mb-10'
    >
      <v-row>
        <v-col cols='12' md='6'>
          <v-text-field
            v-model='search.keyword'
            label='キーワード'
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols='12' md='3'>
          <datetime
            v-model='search.created_at_from'
            label='入会年月From'
            hide-details
            type='date'
          />
        </v-col>
        <v-col cols='12' md='3'>
          <datetime
            v-model='search.created_at_to'
            label='入会年月To'
            hide-details
            type='date'
          />
        </v-col>
        <v-col cols='12' md='3'>
          <datetime
            v-model='search.birthday_from'
            label='生年月日From'
            hide-details
            type='date'
          />
        </v-col>
        <v-col cols='12' md='3'>
          <datetime
            v-model='search.birthday_to'
            label='生年月日To'
            hide-details
            type='date'
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols='12' md='3'>
          <v-select
            v-model='search.gender'
            label='性別'
            hide-details
            clearable
            :items='selectItems.gender'
          ></v-select>
        </v-col>
        <v-col cols='12' md='3'>
          <v-select
            v-model='search.pref'
            label='都道府県'
            hide-details
            clearable
            :items='selectItems.pref'
          ></v-select>
        </v-col>
        <v-col cols='12' md='3'>
          <v-select
            v-model='search.user_item_status'
            label='ステータス'
            hide-details
            clearable
            :items='selectItems.user_item_status'
          ></v-select>
        </v-col>
        <v-col cols='12' md='3' align='right'>
          <v-btn
            outlined
            type='submit'
            class='mt-3'
          >
            絞り込む
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <v-card>
      <v-data-table
        :loading='loading'
        :headers='headers'
        :items='users'
        v-on:click:row='clickRow'
      >
        <template #item.user.profile.birthday='{ item }'>
          {{ item.user.profile.birthday | moment_format('ll') }}
        </template>
        <template #item.user.profile.gender='{ item }'>
          {{ $$getEnumText('gender', item.user.profile.gender) }}
        </template>
        <template #item.user.profile.pref='{ item }'>
          {{ $$getEnumText('pref', item.user.profile.pref) }}
        </template>
        <template #item.user_item_status='{ item }'>
          {{ $$getEnumText('user_item_status', item.user_item_status) }}
        </template>
        <template #item.created_at='{ item }'>
          {{ item.created_at | moment_format('ll') }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
  export default {
    data: () => ({
      loading: false,
      selectItems: {},
      search: {},
      headers: [],
      users: []
    }),
    computed: {
      PATH() {
        const clubId = this.$store.getters.club.id
        const path = {}
        path.index = `/api/v1/manager/clubs/${clubId}/users`
        path.search = `${path.index}/search`
        return path
      }
    },
    created() {
      this.init()
      this.getUsers(this.PATH.index)
    },
    methods: {
      init() {
        this.selectItems = {
          gender: this.$$selectEnums('gender'),
          pref: this.$$selectEnums('pref'),
          user_item_status: this.$$selectEnums('user_item_status'),
        },
        this.headers = [
          { text: 'ニックネーム', value: 'user.profile.nickname', sortable: false },
          { text: '生年月日', value: 'user.profile.birthday' },
          { text: '性別', value: 'user.profile.gender' },
          { text: 'お住まいの都道府県', value: 'user.profile.pref' },
          { text: '入会年月日', value: 'created_at' },
          { text: 'ステータス', value: 'user_item_status' }
        ]
      },
      getUsers(path, params = null) {
        this.loading = true
        this.axios.get(
          path, { params: params }
        ).then(res => {
          this.users = res.data.users
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          this.loading = false
        })
      },
      searchUsers() {
        this.getUsers(this.PATH.search, this.search)
      },
      clickRow(item) {
        this.$router.push({ name: 'manager-users-show', params: { id: item.user.id } })
      },
    },
    metaInfo() { return this.$t(`meta_tags.${this.$route.name}`) },
  }
</script>
