<template>
  <v-dialog
    v-model='dialog'
    :max-width='400'
  >
    <template v-slot:activator='{ on, attrs }'>
      <v-btn
        color='primary'
        v-bind='attrs'
        v-on='on'
        v-on:click='$emit("create-token")'
        outlined
      >
        トークン発行
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        トークンURL
      </v-card-title>
      <VDivider class='mb-4'/>
      <v-card-text>
        <v-row>
          <v-col align='center' class='mb-8'>
            マネージャー登録用URLを発行しました。<br />
            このURLは１度使うと使えなくなります。
          </v-col>
        </v-row>
        <v-text-field
          label='マネージャー登録用URL'
          :value='tokenUrl'
          readonly
          append-outer-icon='mdi-content-copy'
          v-on:click:append-outer='clipboardCopy'
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <VSpacer />
        <v-btn
          v-on:click='dialog = false'
        >
          閉じる
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: {
      tokenUrl: { type: String, required: true }
    },
    data: () => ({
      dialog: false,
    }),
    methods: {
      clipboardCopy() {
        navigator.clipboard.writeText(this.tokenUrl).then(() => {
          this.$toasted.success(this.$t('success.clipboard_copy'))
        }).catch(err => {
          console.log(err)
        })
      },
    }
  }
</script>
