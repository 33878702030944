<template>
  <v-card :loading='loading'>
    <v-simple-table
      class='mouse-over-highlight-disabled'
    >
      <tbody>
        <tr>
          <td class='font-weight-bold'>ニックネーム</td>
          <td>
            <v-avatar
              class='mr-2'
              size='36'
            >
              <v-img :src='user.profile.icon.url' />
            </v-avatar>
            {{ user.profile.nickname }}
          </td>
        </tr>
        <tr>
          <td class='font-weight-bold'>メールアドレス</td>
          <td>{{ user.email }}</td>
        </tr>
        <tr>
          <td class='font-weight-bold'>作成日</td>
          <td>{{ user.created_at | moment_format('lll')}}</td>
        </tr>
        <tr>
          <td class='font-weight-bold'>ユーザ情報更新日</td>
          <td>{{ user.updated_at | moment_format('lll') }}</td>
        </tr>
        <tr>
          <td class='font-weight-bold'>生年月日</td>
          <td>{{ user.profile.birthday | moment_format('ll') }}</td>
        </tr>
        <tr>
          <td class='font-weight-bold'>性別</td>
          <td>{{ $$getEnumText('gender', user.profile.gender) }}</td>
        </tr>
        <tr>
          <td class='font-weight-bold'>お住まいの都道府県</td>
          <td>{{ $$getEnumText('pref', user.profile.pref) }}</td>
        </tr>
        <tr>
          <td class='font-weight-bold'>プロフィール</td>
          <td class='word-breaker'>{{ user.profile.desc }}</td>
        </tr>
        <tr>
          <td class='font-weight-bold'>プロフィール情報更新日</td>
          <td>{{ user.profile.updated_at | moment_format('lll') }}</td>
        </tr>
        <tr>
          <td class='font-weight-bold'>所属しているコミュニティ</td>
          <td>
            <v-chip
              v-for='club in user.clubs'
              :key='club.id'
              class='mr-2'
              v-on:click='clubShow(club)'
            >
              {{ club.name }}
            </v-chip>
          </td>
        </tr>
        <tr>
          <td class='font-weight-bold'>アカウントタイプ</td>
          <td>{{ $$getEnumText('user_type', user.profile.user_type) }}</td>
        </tr>
        <tr>
          <td class='font-weight-bold'>アカウントステータス</td>
          <td>{{ $$getEnumText('account_status', user.account_status) }}</td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-card>
</template>

<script>
  export default {
    props: {
      user: { type: Object, required: true },
      loading: { type: Boolean, required: true },
    },
    methods: {
      clubShow(club) {
        this.$router.push({ name: 'admin-clubs-show', params: { id: club.id } })
      },
    }
  }
</script>
