<template>
  <div class='pa-10'>
    <v-row class='mb-4'>
      <v-col cols='12' md='8'>
        <h2>Voice 一覧</h2>
      </v-col>
    </v-row>

    <v-form
      v-on:submit.prevent='searchVoices'
      class='mb-10'
    >
      <v-row>
        <v-col cols='12' md='3'>
          <v-text-field
            v-model='search.name'
            label='コミュニティ名'
            hide-details
            clearable
          />
        </v-col>
        <v-col cols='12' md='3'>
          <v-text-field
            v-model='search.nickname'
            label='ユーザー名'
            hide-details
            clearable
          />
        </v-col>
        <v-col cols='12' md='3'>
          <v-select
            v-model='search.gallery_type'
            label='メディア'
            :items='selectItems.gallery_type'
            hide-details
            clearable
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols='12' md='3'>
          <datetime
            v-model='search.created_at_from'
            label='投稿日時From'
            hide-details
            clearable
            type='datetime'
          />
        </v-col>
        <v-col cols='12' md='3'>
          <datetime
            v-model='search.created_at_to'
            label='投稿日時To'
            hide-details
            clearable
            type='datetime'
          />
        </v-col>
        <v-col cols='12' md='3'>
          <v-select
            v-model='search.user_item_status'
            label='ステータス'
            hide-details
            clearable
            :items='selectItems.user_item_status'
          />
        </v-col>
        <v-col cols='12' md='3' align='right'>
          <v-btn
            type='submit'
            class='mt-3'
          >
            絞り込む
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <v-card>
      <v-data-table
        :loading='loading'
        :headers='headers'
        :items='voices'
        v-on:click:row='clickRow'
      >
        <template #item.club='{ item }'>
          <v-avatar size='36' class='mr-2'>
            <v-img :src='item.club.icon.url' />
          </v-avatar>
          {{ item.club.name }}
        </template>
        <template #item.user='{ item }'>
          <v-avatar size='36' class='mr-2'>
            <v-img :src='item.user.profile.icon.url' />
          </v-avatar>
          {{ item.user.profile.nickname }}
        </template>
        <template #item.desc='{ item }'>
          <div class='word-breaker'>{{ item.msg }}</div>
        </template>
        <template #item.gallery_type='{ item }'>
          {{ $$getEnumText('gallery_type', item.gallery_type) }}
        </template>
        <template #item.created_at='{ item }'>
          {{ item.created_at | moment_format('LLL') }}
        </template>
        <template #item.user_item_status='{ item }'>
          {{ $$getEnumText('user_item_status', item.user_item_status) }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
  export default {
    data: () => ({
      loading: false,
      search: {
        user_item_status: 'active'
      },
      selectItems: {},
      headers: [],
      voices: [],
    }),
    computed: {
      PATH() {
        const path = {}
        path.index = `/api/v1/admin/voices`
        path.search = `${path.index}/search`
        return path
      }
    },
    created() {
      this.init()
      this.getVoices(this.PATH.index)
    },
    methods: {
      init() {
        this.headers = [
          { text: 'コミュニティ', value: 'club' },
          { text: 'ユーザー', value: 'user' },
          { text: '内容', value: 'desc' },
          { text: 'メディア', value: 'gallery_type' },
          { text: '投稿日時', value: 'created_at' },
          { text: 'ステータス', value: 'user_item_status' }
        ]
        this.selectItems = {
          gallery_type: this.$$selectEnums('gallery_type'),
          user_item_status: this.$$selectEnums('user_item_status')
        }
      },
      getVoices(path, params) {
        this.loading = true
        this.axios.get(
          path, { params: params }
        ).then(res => {
          this.voices = res.data.voices
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          this.loading = false
        })
      },
      searchVoices() {
        this.getVoices(this.PATH.search, this.search)
      },
      clickRow(item) {
        this.$router.push({ name: 'admin-voices-show', params: { id: item.id } })
      }
    },
    metaInfo() { return this.$t(`meta_tags.${this.$route.name}`) },
  }
</script>
