<template>
  <file-pond-with-plugins
    ref='pond'
    :class-name='filepondCols'
    :max-files='maxFiles'
    :allow-multiple='true'
    :accepted-file-types='acceptedFileTypes'
    :files='valueForFilepond'
    :server='server'
    :disabled='disabled'
    item-insert-location='after'
    :allow-reorder='true'
    v-on:updatefiles='updatefiles'
    v-on:reorderfiles='reorderfiles'
  />
</template>

<script>
  import VueFilePond from 'vue-filepond'
  import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
  import FilePondPluginMediaPreview from 'filepond-plugin-media-preview'
  import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
  import 'filepond/dist/filepond.min.css'
  import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'
  import 'filepond-plugin-media-preview/dist/filepond-plugin-media-preview.min.css'

  const FilePondWithPlugins = VueFilePond(
    FilePondPluginImagePreview,
    FilePondPluginMediaPreview,
    FilePondPluginFileValidateType,
  )

  export default {
    components: {
      FilePondWithPlugins
    },
    props: {
      value: { type: Array, required: true },
      maxFiles: { type: Number, default: null },
      acceptedFileTypes: { type: String, default: null },
      disabled: { type: Boolean, default: false },
    },
    computed: {
      internalValue: {
        get() { return this.value },
        set(value) { this.$emit('input', value) }
      },
      server() {
        return {
          process: null,
          load: async (source, load) => {
            const headers = { 'Cache-Control': 'no-cache' }
            await this.axios.get(
              source, { responseType: 'blob', headers: headers }
            ).then(res => {
              load(res.data)
            })
          }
        }
      },
      valueForFilepond() {
        const contents = this.internalValue.map(val => val.content)
        const contentPaths = contents.map(val => {
          if ('url' in val) {
            return {
              source: val.url,
              options: { type: 'local' }
            }
          } else {
            return val
          }
        })
        return contentPaths
      },
      filepondCols() {
        const visibleBeautifulMax = 4
        let cols = visibleBeautifulMax
        if (!this.maxFiles) {
          cols = 1
        } else {
          cols = this.maxFiles > visibleBeautifulMax ? cols : this.maxFiles
        }
        return `filepond-col${cols}`
      },
    },
    methods: {
      updatefiles(filePondObjects) {
        this.convertFilepondToTransmissions(filePondObjects)
      },
      reorderfiles(filePondObjects) {
        this.convertFilepondToTransmissions(filePondObjects)
      },
      convertFilepondToTransmissions(filePondObjects) {
        this.internalValue = filePondObjects.map((filePondObject, index) => {
          const s3url = filePondObject.serverId
          let transmission = {}
          if (!s3url) {
            transmission = { id: '', content: filePondObject.file, content_type: '' }
          } else {
            transmission =  this.internalValue.find(transmission => transmission.content.url === s3url)
          }
          transmission.position = index + 1
          return transmission
        })
      },
    },
  }
</script>

<style scope lang='scss'>
  .filepond-col1 .filepond--item { width: calc(100% - .5em) }
  .filepond-col2 .filepond--item { width: calc(50% - .5em) }
  .filepond-col3 .filepond--item { width: calc(33.33% - .5em) }
  .filepond-col4 .filepond--item { width: calc(25% - .5em) }
</style>
