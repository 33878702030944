<template>
  <v-dialog
    v-model='dialog'
    :max-width='500'
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        color='primary'
        outlined
      >
        テストでコミュニティに加入
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        コミュニティを選択
      </v-card-title>
      <VDivider class='mb-4' />
      <v-card-text>
        <v-data-table
          :loading='loading'
          v-model='selectedClubs'
          :headers='headers'
          :items='clubs'
          show-select
          v-on:click:row='clickRow'
        />
      </v-card-text>
      <v-card-actions>
        <VSpacer/>
        <v-btn
          v-on:click='dialog = false'
        >
          キャンセル
        </v-btn>
        <v-btn
          color='primary'
          v-on:click='save'
        >
          加入する
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: {
      loading: { type: Boolean, required: true },
      clubs: { type: Array, required: true },
      joinedClubs: { type: Array, required: true },
    },
    data: () => ({
      dialog: false,
      selectedClubs: [],
      headers: [],
    }),
    created() {
      this.init()
    },
    watch: {
      dialog(val) {
        if (!val) { return }
        this.$emit('init')
        this.selectedClubs = this.joinedClubs.map(club => ({ id: club.id }))
      }
    },
    methods: {
      init() {
        this.headers = [
          { text: 'コミュニティ名', value: 'name', sortable: false },
        ]
      },
      save() {
        const clubIds = this.selectedClubs.map(club => ({ id: club.id }))
        this.$emit('save', clubIds)
        this.dialog = false
      },
      clickRow(item, opts) {
        opts.select(!opts.isSelected)
      },
    }
  }
</script>
