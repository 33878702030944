<template>
  <v-card>
    <v-card-title>Clubs</v-card-title>
    <v-divider />
    <v-data-table
      :loading='loading'
      :headers='headers'
      :items='monthlyClubsCounts'
      hide-default-footer
    >
      <template #item.month='{ item }'>
        {{ item.month | moment_format('YYYY/MM') }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
  export default {
    props: {
      monthlyClubsCounts: { type: Array, required: true },
      loading: { type: Boolean, required: true },
    },
    data: () => ({
      headers: []
    }),
    created() {
      this.init()
    },
    methods: {
      init() {
        this.headers = [
          { text: '年月', value: 'month' },
          { text: 'カウント', value: 'count' },
        ]
      },
    },
  }
</script>
