<template>
  <div class='pa-10'>
    <v-row class='mb-4'>
      <v-col cols='12' md='8'>
        <h2>マネージャー 一覧</h2>
      </v-col>
    </v-row>

    <v-form
      v-on:submit.prevent='searchManagers'
      class='mb-10'
    >
      <v-row>
        <v-col cols='12' md='6'>
          <v-text-field
            v-model='search.staff_name'
            label='担当者名'
            hide-details
            clearable
          />
        </v-col>
        <v-col cols='12' md='6'>
          <v-text-field
            v-model='search.email'
            label='メールアドレス'
            hide-details
            clearable
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols='12' md='3'>
          <datetime
            v-model='search.created_at_from'
            label='作成日From'
            hide-details
            type='datetime'
          />
        </v-col>
        <v-col cols='12' md='3'>
          <datetime
            v-model='search.created_at_to'
            label='作成日To'
            hide-details
            type='datetime'
          />
        </v-col>
        <v-col cols='12' md='3'>
          <v-select
            v-model='search.account_status'
            label='ステータス'
            hide-details
            clearable
            :items='selectItems.account_status'
          />
        </v-col>
        <v-col cols='12' md='3' align='right'>
          <v-btn
            type='submit'
            class='mt-3'
          >
            絞り込む
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <v-card>
      <v-data-table
        :loading='loading'
        :headers='headers'
        :items='managers'
        v-on:click:row='clickRow'
      >
        <template #item.created_at='{ item }'>
          {{ item.created_at | moment_format('lll') }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
  export default {
    data: () => ({
      loading: false,
      search: {
        account_status: 'active'
      },
      selectItems: {},
      headers: [],
      managers: []
    }),
    computed: {
      PATH() {
        const path = {}
        path.index = `/api/v1/admin/managers`
        path.search = `${path.index}/search`
        return path
      }
    },
    created() {
      this.init()
      this.getManagers(this.PATH.index)
    },
    methods: {
      init() {
        this.headers = [
          { text: '担当者名', value: 'profile.staff_name' },
          { text: 'メールアドレス', value: 'email' },
          { text: '作成日', value: 'created_at' }
        ]
        this.selectItems.account_status = this.$$selectEnums('account_status')
      },
      getManagers(path, params) {
        this.loading = true
        this.axios.get(
          path, { params: params }
        ).then(res => {
          this.managers = res.data.managers
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          this.loading = false
        })
      },
      searchManagers() {
        this.getManagers(this.PATH.search, this.search)
      },
      clickRow(item) {
        this.$router.push({ name: 'admin-managers-show', params: { id: item.id } })
      }
    },
    metaInfo() { return this.$t(`meta_tags.${this.$route.name}`) },
  }
</script>
