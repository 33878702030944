import Vue from 'vue';
import VueRouter from 'vue-router';
import VueCookies from 'vue-cookies';
import Top from '@/views/Top';
import NotFound from '@/views/error/NotFound';
import Admin from '@/router/admin.js';
import Manager from '@/router/manager.js';
import User from '@/router/user.js';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'top',
      component: Top,
      meta: { isPublic: true }
    },
    ...Admin,
    ...Manager,
    ...User,
    {
      path: '*',
      name: 'not-found',
      component: NotFound,
      meta: { isPublic: true }
    },
  ]
});

router.beforeEach((to, from, next) => {
  const authInfos = VueCookies.get('authInfos')

  if (to.matched.some(record => !record.meta.isPublic) && authInfos === null) {
    next({ name: 'top' })
  } else {
    next()
  }
})
router.afterEach(() => {
  window.scrollTo({top: 0})
})

export default router;
