var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-10"},[_c('v-row',{staticClass:"mb-4"},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('h2',[_vm._v("お知らせ投稿")])]),_c('v-col',{attrs:{"cols":"12","md":"4","align":"right"}},[_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.toIndex}},[_vm._v(" 一覧に戻る "),_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)],1),_c('validation-observer',{ref:"observer"},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.save($event)}}},[_c('v-card',{staticClass:"mb-8",attrs:{"loading":_vm.loading}},[_c('v-card-text',[_c('validation-provider',{attrs:{"name":"タイトル","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"タイトル","error-messages":errors,"outlined":"","counter":"100"},model:{value:(_vm.notice.title),callback:function ($$v) {_vm.$set(_vm.notice, "title", $$v)},expression:"notice.title"}})]}}])}),_c('validation-provider',{attrs:{"name":"詳細","rules":"required|max:1000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"詳細","error-messages":errors,"rows":3,"auto-grow":"","outlined":"","counter":"1000"},model:{value:(_vm.notice.desc),callback:function ($$v) {_vm.$set(_vm.notice, "desc", $$v)},expression:"notice.desc"}})]}}])}),_c('validation-provider',{attrs:{"name":"表示対象","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"表示対象","error-messages":errors,"items":_vm.selectItems.target_type,"outlined":""},model:{value:(_vm.notice.target_type),callback:function ($$v) {_vm.$set(_vm.notice, "target_type", $$v)},expression:"notice.target_type"}})]}}])}),_c('validation-provider',{attrs:{"name":"表示日時","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('datetime',{attrs:{"label":"表示日時","error-messages":errors,"outlined":""},model:{value:(_vm.notice.disp_st_at),callback:function ($$v) {_vm.$set(_vm.notice, "disp_st_at", $$v)},expression:"notice.disp_st_at"}})]}}])})],1)],1),_c('FormTransmission',{staticClass:"mb-8",attrs:{"loading":_vm.loading,"initial-content-type":_vm.notice.gallery_type,"max-photo-files":4},model:{value:(_vm.notice.transmissions),callback:function ($$v) {_vm.$set(_vm.notice, "transmissions", $$v)},expression:"notice.transmissions"}}),_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"px-16",attrs:{"loading":_vm.loading,"type":"submit","color":"primary","outlined":""}},[_vm._v(" 投稿する ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }