<template>
  <v-dialog
    v-model='dialog'
    :max-width='400'
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :class='btnClass'
        outlined
        color='primary'
        v-bind="attrs"
        v-on="on"
      >
        {{title}}する
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        {{title}}確認
      </v-card-title>
      <VDivider class='mb-4' />
      <v-card-text>
        {{title}}します。よろしいですか？
      </v-card-text>
      <v-card-actions>
        <VSpacer/>
        <v-btn
          v-on:click='dialog = false'
        >
          キャンセル
        </v-btn>
        <v-btn
          color='primary'
          v-on:click='save'
        >
          {{title}}する
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: {
      title: { type: String, default: '復元' },
      btnClass: { type: String, default: '' },
    },
    data: () => ({
      dialog: false
    }),
    methods: {
      save() {
        this.dialog = false
        this.$emit('save')
      }
    }
  }
</script>
