<template>
  <div class='pa-10'>
    <v-row class='mb-4'>
      <v-col cols='12' md='8'>
        <h2>マネージャー住所編集</h2>
      </v-col>
    </v-row>

    <v-card
      class='mb-6 pa-6'
    >
      <validation-observer ref='observer'>
        <v-form
          v-on:submit.prevent='update'
        >
          <v-row>
            <v-col
              cols='12'
              md='8'
            >
              <validation-provider
                v-slot='{ errors }'
                name='郵便番号'
                rules='required|max:7|min:7'
              >
                <v-text-field
                  v-model='address.zip'
                  outlined
                  label='郵便番号'
                  type='number'
                  :error-messages='errors'
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col
              cols='6'
              md='4'
              class='mt-2'
            >
              <v-btn
                outlined
                v-on:click.stop='getAddressFromZip'
              >郵便番号から検索</v-btn>
            </v-col>
          </v-row>
          <validation-provider
            v-slot='{ errors }'
            name='都道府県'
            rules='required'
          >
            <v-select
              v-model='address.pref'
              outlined
              label='都道府県'
              :items='selectItems.pref'
              :error-messages='errors'
            ></v-select>
          </validation-provider>
          <validation-provider
            v-slot='{ errors }'
            name='市区町村・番地'
            rules='required'
          >
            <v-text-field
              v-model='address.city'
              outlined
              label='市区町村・番地'
              :error-messages='errors'
            ></v-text-field>
          </validation-provider>
          <validation-provider
            name='建物名・号室'
          >
            <v-text-field
              v-model='address.sub_address'
              outlined
              label='建物名・号室'
            ></v-text-field>
          </validation-provider>
          <v-row justify='center'>
            <v-btn
              type='submit'
              color='primary'
              class='px-16 mt-5 font-weight-bold'
            >
              編集内容を保存する
            </v-btn>
          </v-row>
        </v-form>
      </validation-observer>
    </v-card>
  </div>
</template>

<script>
  export default {
    data: () => ({
      selectItems: {
        pref: []
      },
      address: {
        zip: '',
        pref: '',
        city: '',
        sub_address: ''
      }
    }),
    created() {
      this.init()
      this.getAddress()
    },
    methods: {
      init() {
        this.selectItems.pref = this.$$selectEnums('pref')
      },
      getAddress() {
        this.axios.get('/api/v1/manager/address').then(res => {
          this.address = res.data.address
        }).catch(err => {
          console.log(err)
        })
      },
      getAddressFromZip() {
        this.axios.get('/api/v1/manager/address/search_for_zip', { params: this.address }).then(res => {
          const resultPref = res.data.results[0].address1
          const resultCity = res.data.results[0].address2
          const enumPref = this.$$selectEnums('pref').find(pref => pref.text === resultPref)
          this.$set(this.address, 'pref', enumPref.value)
          this.address.city = resultCity
        }).catch(err => {
          console.log(err)
        })
      },
      update() {
        this.axios.patch('/api/v1/manager/address', this.address).then(res => {
          // TODO: Notice系で成功を教えてあげる
          console.log(res)
          this.$router.push({ name: 'manager-profiles-show' })
        }).catch(err => {
          // TODO: Notice系で失敗を教えてあげる
          console.log(err)
        })
      }
    },
    metaInfo() { return this.$t(`meta_tags.${this.$route.name}`) },
  }
</script>
