<template>
  <v-card :loading='loading'>
    <v-simple-table
      class='mouse-over-highlight-disabled'
    >
      <tbody>
        <tr>
          <td class='font-weight-bold'>タイトル</td>
          <td>{{ notice.title }}</td>
        </tr>
        <tr>
          <td class='font-weight-bold'>詳細</td>
          <td class='word-breaker'>{{ notice.desc }}</td>
        </tr>
        <tr>
          <td class='font-weight-bold'>メディア</td>
          <td>
            <FilePondForTransmissions
              v-if='notice.transmissions.length > 0'
              v-model='notice.transmissions'
              :maxFiles='notice.transmissions.length'
              :disabled='true'
            />
          </td>
        </tr>
        <tr>
          <td class='font-weight-bold'>対象</td>
          <td>{{ $$getEnumText('target_type', notice.target_type) }}</td>
        </tr>
        <tr>
          <td class='font-weight-bold'>表示開始日時</td>
          <td>{{ notice.disp_st_at | moment_format('lll')}}</td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-card>
</template>

<script>
  import FilePondForTransmissions from '@/components/plugins/FilePondForTransmissions'
  export default {
    components: {
      FilePondForTransmissions,
    },
    props: {
      notice: { type: Object, required: true },
      loading: { type: Boolean, required: true },
    }
  }
</script>
