<template>
  <div class='pa-10'>
    <v-row class='mb-4'>
      <v-col cols='12' md='8'>
        <h2>Now投稿詳細</h2>
      </v-col>
      <v-col cols='12' md='4' align='right'>
        <v-btn
          color='primary'
          v-on:click='toIndex'
        >
          一覧に戻る
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <timeline-murmur
      class='mb-8'
      :loading='loading'
      :timeline='timeline'
    />
    <v-row>
      <v-col cols='12' md='4'>
      </v-col>
      <v-col cols='12' md='4' align='center'>
        <v-btn
          outlined
          color='primary'
          v-on:click='editForm'
        >
          投稿内容を編集する
        </v-btn>
      </v-col>
      <v-col cols='12' md='4' align='right'>
        <btn-dialog-destroy
          v-if='timeline.club_item_status === "active"'
          :loading='loading'
          v-on:save='destroySave'
        />
        <btn-dialog-restore
          v-if='timeline.club_item_status === "manager_stop"'
          :loading='loading'
          v-on:save='restoreSave'
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import TimelineMurmur from '@/views/common/timelines/Show/Murmur'
  import BtnDialogDestroy from '@/components/parts/Btn/Dialog/Destroy'
  import BtnDialogRestore from '@/components/parts/Btn/Dialog/Restore'
  export default {
    components: {
      TimelineMurmur,
      BtnDialogDestroy,
      BtnDialogRestore,
    },
    data: () => ({
      loading: false,
      dialog: false,
      timeline: {
        murmur: {},
        transmissions: [],
      },
    }),
    computed: {
      PATH() {
        const clubId = this.$store.getters.club.id
        const timelineId = this.$route.params.id
        const path = {}
        path.show = `/api/v1/manager/clubs/${clubId}/timelines/murmurs/${timelineId}`
        path.stop = `${path.show}/stop`
        path.start = `${path.show}/start`
        return path
      }
    },
    created() {
      this.getTimeline()
    },
    methods: {
      getTimeline() {
        this.loading = true
        this.axios.get(
          this.PATH.show
        ).then(res => {
          this.timeline = res.data.timeline
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          this.loading = false
        })
      },
      destroySave() {
        if (this.loading) { return }
        this.loading = true
        this.axios.post(
          this.PATH.stop
        ).then(res => {
          this.dialog = false
          this.timeline = res.data.timeline
          this.$toasted.success(this.$t('success.update'))
        }).catch(err => {
          if (err.response.status !== 422) { return }
          this.$toasted.error(err.response.data.errors.join("\n"))
        }).finally(() => {
          this.loading = false
        })
      },
      restoreSave() {
        if (this.loading) { return }
        this.loading = true
        this.axios.post(
          this.PATH.start
        ).then(res => {
          this.dialog = false
          this.timeline = res.data.timeline
          this.$toasted.success(this.$t('success.update'))
        }).catch(err => {
          if (err.response.status !== 422) { return }
          this.$toasted.error(err.response.data.errors.join("\n"))
        }).finally(() => {
          this.loading = false
        })
      },
      toIndex() {
        this.$router.push({ name: 'manager-murmurs-index' })
      },
      editForm() {
        this.$router.push({ name: 'manager-murmurs-edit', params: { id: this.$route.params.id } })
      },
    },
    metaInfo() { return this.$t(`meta_tags.${this.$route.name}`) },
  }
</script>
