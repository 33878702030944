<template>
  <div class='pa-10'>
    <v-row class='mb-4'>
      <v-col cols='12' md='8'>
        <h2>コミュニティ一覧</h2>
      </v-col>
      <v-col cols='12' md='4' align='right'>
        <v-btn
          color='primary'
          v-on:click='toNew'
        >
          新規登録
        </v-btn>
      </v-col>
    </v-row>

    <v-form
      v-on:submit.prevent='searchClubs'
      class='mb-10'
    >
      <v-row>
        <v-col cols='12' md='6'>
          <v-text-field
            v-model='search.keyword'
            label='キーワード'
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols='12' md='3'>
          <datetime
            v-model='search.created_at_from'
            label='作成日From'
            hide-details
            type='datetime'
          />
        </v-col>
        <v-col cols='12' md='3'>
          <datetime
            v-model='search.created_at_to'
            label='作成日To'
            hide-details
            type='datetime'
          />
        </v-col>
        <v-col cols='12' md='3'>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols='12' md='3'>
          <datetime
            v-model='search.open_at_from'
            label='公開日From'
            hide-details
            type='datetime'
          />
        </v-col>
        <v-col cols='12' md='3'>
          <datetime
            v-model='search.open_at_to'
            label='公開日To'
            hide-details
            type='datetime'
          />
        </v-col>
        <v-col cols='12' md='3'>
          <v-select
            v-model='search.publish_status'
            label='ステータス'
            hide-details
            clearable
            :items='selectItems.publish_status'
          />
        </v-col>
        <v-col cols='12' md='3' align='right'>
          <v-btn
            type='submit'
            class='mt-3'
            outlined
          >
            絞り込む
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <v-card>
      <v-data-table
        :loading='loading'
        :headers='headers'
        :items='clubs'
        v-on:click:row='clickRow'
      >
        <template #item.name='{ item }'>
          <v-avatar
            class='mr-2'
            size='36'
          >
            <v-img :src='item.icon.url' />
          </v-avatar>
          {{ item.name }}
        </template>
        <template #item.created_at='{ item }'>
          {{ item.created_at | moment_format('lll') }}
        </template>
        <template #item.open_at='{ item }'>
          {{ item.open_at | moment_format('lll') }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
  export default {
    data: () => ({
      loading: false,
      search: {},
      headers: [],
      clubs: [],
      selectItems: [],
    }),
    computed: {
      PATH() {
        const path = {}
        path.index = `/api/v1/admin/clubs`
        path.search = `${path.index}/search`
        return path
      }
    },
    created() {
      this.init()
      this.getClubs(this.PATH.index)
    },
    methods: {
      init() {
        this.headers = [
          { text: 'コミュニティ名', value: 'name' },
          { text: 'コミュニティ名（ひらがな）', value: 'name_hira' },
          { text: '作成日', value: 'created_at' },
          { text: '公開日', value: 'open_at' }
        ]
        this.selectItems.publish_status = [
          { text: '公開前', value: 'draft' },
          { text: '公開中', value: 'published' },
        ]
      },
      getClubs(path, params) {
        this.loading = true
        this.axios.get(
          path, { params: params }
        ).then(res => {
          this.clubs = res.data.clubs
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          this.loading = false
        })
      },
      searchClubs() {
        this.getClubs(this.PATH.search, this.search)
      },
      toNew() {
        this.$router.push({ name: 'admin-clubs-new' })
      },
      clickRow(item) {
        this.$router.push({ name: 'admin-clubs-show', params: { id: item.id }})
      }
    },
    metaInfo() { return this.$t(`meta_tags.${this.$route.name}`) },
  }
</script>
