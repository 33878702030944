<template>
  <v-list
    nav
    expand
  >
    <v-list-item
      link
      v-for="list in drawerLists"
      :key="list.title"
      :to="list.url"
    >
      <v-list-item-action>
        <v-icon>{{ list.icon }}</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>{{ list.title }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
  export default {
    name: 'AdminDrawerList',
    data: () => ({
      drawerLists: [
        { title: 'Home', icon: 'mdi-home', url: { name: 'admin-dashboard' } },
        { title: 'Communities', icon: 'mdi-cards-club', url: { name: 'admin-clubs-index' } },
        { title: 'Communities Updated', icon: '', url: { name: 'admin-clubs-update-histories' } },
        { title: 'Timelines', icon: 'mdi-chat-processing', url: { name: 'admin-timelines-index' } },
        { title: 'Voices', icon: 'mdi-chat', url: { name: 'admin-voices-index' } },
        { title: 'Managers', icon: 'mdi-card-account-details', url: { name: 'admin-managers-index' }},
        { title: 'Users', icon: 'mdi-account-supervisor', url: { name: 'admin-users-index' } },
        { title: 'Notices', icon: 'mdi-bulletin-board', url: { name: 'admin-notices-index' } },
        { title: 'Categories', icon: 'mdi-shape', url: { name: 'admin-categories-index' } },
        { title: 'Setting', icon: 'mdi-cog', url: { name: 'admin-setting' } },
      ]
    })
  };
</script>
