<template>
  <v-card :loading='loading'>
    <v-simple-table
      class='mouse-over-highlight-disabled'
    >
      <tbody>
        <tr>
          <td class='font-weight-bold'>ニックネーム</td>
          <td>
            <v-avatar
              class='mr-2'
              size='36'
            >
              <v-img :src='user.user.profile.icon.url' />
            </v-avatar>
            {{ user.user.profile.nickname }}
          </td>
        </tr>
        <tr>
          <td class='font-weight-bold'>会員番号</td>
          <td>{{ user.club_no }}</td>
        </tr>
        <tr>
          <td class='font-weight-bold'>生年月日</td>
          <td>{{ user.user.profile.birthday | moment_format('ll') }}</td>
        </tr>
        <tr>
          <td class='font-weight-bold'>性別</td>
          <td>{{ $$getEnumText('gender', user.user.profile.gender) }}</td>
        </tr>
        <tr>
          <td class='font-weight-bold'>お住まいの都道府県</td>
          <td>{{ $$getEnumText('pref', user.user.profile.pref) }}</td>
        </tr>
        <tr>
          <td class='font-weight-bold'>入会年月日</td>
          <td>{{ user.created_at | moment_format('ll') }}</td>
        </tr>
        <tr>
          <td class='font-weight-bold'>入会プラン</td>
          <td>
            <template v-if='user.clubs_plan'>
              {{ $$getEnumText('payment_type', user.clubs_plan.payment_type) }}
              {{ user.clubs_plan.price }} 円
            </template>
          </td>
        </tr>
        <tr>
          <td class='font-weight-bold'>コミュニティ所属ステータス</td>
          <td>{{ $$getEnumText('user_item_status', user.user_item_status) }}</td>
        </tr>
        <tr>
          <td class='font-weight-bold'>アカウントステータス</td>
          <td>{{ $$getEnumText('account_status', user.user.account_status) }}</td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-card>
</template>

<script>
  export default {
    props: {
      user: { type: Object, required: true },
      loading: { type: Boolean, required: true }
    }
  }
</script>
