<template>
  <div>
    <div class="d-flex justify-center mt-6 mb-6">
      <h1>Fums</h1>
    </div>

    <v-btn
      to="/admin"
    >
      Admin
    </v-btn>

    <v-btn
      to="/manager"
    >
      Manager
    </v-btn>
  </div>
</template>

<script>
  export default {
    metaInfo() { return this.$t(`meta_tags.${this.$route.name}`) },
  }
</script>
