<template>
  <div class='pa-10'>
    <v-row class='mb-4'>
      <v-col cols='12' md='8'>
        <h2>アカウント詳細</h2>
      </v-col>
    </v-row>

    <manager-profile
      class='mb-8'
      :loading='loading.profile'
      :profile='profile'
      v-on:edit='toProfileEdit'
    />

    <manager-address
      :loading='loading.address'
      :address='address'
      v-on:edit='toAddressEdit'
    />
  </div>
</template>

<script>
  import ManagerProfile from './Show/ManagerProfile'
  import ManagerAddress from './Show/ManagerAddress'
  export default {
    components: {
      ManagerProfile,
      ManagerAddress,
    },
    data: () => ({
      loading: { profile: false, address: false },
      profile: {},
      address: {}
    }),
    created() {
      this.getProfile()
      this.getAddress()
    },
    methods: {
      getProfile() {
        this.loading.profile = true
        this.axios.get('/api/v1/manager/profile').then(res => {
          this.profile = res.data.profile
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          this.loading.profile = false
        })
      },
      getAddress() {
        this.loading.address = true
        this.axios.get('/api/v1/manager/address').then(res => {
          this.address = res.data.address
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          this.loading.address = false
        })
      },
      toProfileEdit() {
        this.$router.push({ name: 'manager-profiles-edit' })
      },
      toAddressEdit() {
        this.$router.push({ name: 'manager-address-form' })
      },
    },
    metaInfo() { return this.$t(`meta_tags.${this.$route.name}`) },
  }
</script>
