<template>
  <div class='pa-10'>
    <v-row class='mb-4'>
      <v-col cols='12' md='8'>
        <h2>お知らせ 一覧</h2>
      </v-col>
    </v-row>
    <v-card>
      <v-data-table
        :loading='loading'
        :headers='headers'
        :items='notices'
        v-on:click:row='clickRow'
      >
        <template #item.disp_st_at='{ item }'>
          {{ item.disp_st_at | moment_format('lll') }}
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-model='dialog'
      :max-width='600'
    >
      <v-card>
        <v-card-title>{{ selectedNotice.title }}</v-card-title>
        <v-divider />
        <v-card-text>
          <v-chip
            class='my-4'
            small
          >
            {{ selectedNotice.disp_st_at | moment_format('lll') }}
          </v-chip>
          <div class='word-breaker'>{{ selectedNotice.desc }}</div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn v-on:click='dialog=false'>close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    data: () => ({
      dialog: false,
      loading: false,
      headers: [],
      notices: [],
      selectedNotice: {}
    }),
    created() {
      this.init()
      this.getNotices()
    },
    methods: {
      init() {
        this.headers = [
          { text: '日時', value: 'disp_st_at' },
          { text: 'タイトル', value: 'title', sortable: false },
        ]
      },
      getNotices() {
        this.loading = true
        this.axios.get(
          `/api/v1/manager/notices`
        ).then(res => {
          this.notices = res.data.notices
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          this.loading = false
        })
      },
      clickRow(notice) {
        this.selectedNotice = notice
        this.dialog = true
      },
    },
    metaInfo() { return this.$t(`meta_tags.${this.$route.name}`) },
  }
</script>
