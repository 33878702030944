import ManagerTop from '@/views/manager/bases/Top.vue';
import ManagerAuthConfirm from '@/views/auth/manager/Confirm';
import ManagerSignup from '@/views/manager/bases/Signup.vue';
import ManagerSignupEmail from '@/views/manager/bases/SignupEmail.vue';
import ManagerPasswordsResetSend from '@/views/manager/passwords/ResetSend.vue';
import ManagerPasswordsReset from '@/views/manager/passwords/Reset.vue';
import ManagerSettingsPassword from '@/views/manager/settings/Password.vue';
import ManagerDashBoard from '@/views/manager/bases/DashBoard.vue';
import ManagerNoticesIndex from '@/views/manager/notices/Index.vue';
import ManagerClubsShow from '@/views/manager/clubs/Show.vue';
import ManagerClubsForm from '@/views/manager/clubs/Form.vue';
import ManagerUsersIndex from '@/views/manager/users/Index.vue';
import ManagerUsersShow from '@/views/manager/users/Show.vue';
import ManagerMurmursIndex from '@/views/manager/timelines/murmurs/Index.vue';
import ManagerMurmursShow from '@/views/manager/timelines/murmurs/Show.vue';
import ManagerMurmursForm from '@/views/manager/timelines/murmurs/Form.vue';
import ManagerInfosIndex from '@/views/manager/timelines/infos/Index.vue';
import ManagerInfosShow from '@/views/manager/timelines/infos/Show.vue';
import ManagerInfosForm from '@/views/manager/timelines/infos/Form.vue';
import ManagerGalleriesIndex from '@/views/manager/timelines/galleries/Index.vue';
import ManagerGalleriesShow from '@/views/manager/timelines/galleries/Show.vue';
import ManagerGalleriesForm from '@/views/manager/timelines/galleries/Form.vue';
import ManagerActivitiesIndex from '@/views/manager/timelines/activities/Index.vue';
import ManagerActivitiesShow from '@/views/manager/timelines/activities/Show.vue';
import ManagerActivitiesForm from '@/views/manager/timelines/activities/Form.vue';
import ManagerVoicesIndex from '@/views/manager/voices/Index.vue';
import ManagerVoicesShow from '@/views/manager/voices/Show.vue';
import ManagerMessageBoxesIndex from '@/views/manager/message_boxes/Index.vue';
import ManagerMessageBoxesShow from '@/views/manager/message_boxes/Show.vue';
import ManagerMessageBoxesForm from '@/views/manager/message_boxes/Form.vue';
import ManagerClubBanksForm from '@/views/manager/club_banks/Form.vue';
import ManagerProfilesShow from '@/views/manager/profiles/Show.vue';
import ManagerProfilesForm from '@/views/manager/profiles/Form.vue';
import ManagerAddressForm from '@/views/manager/address/Form.vue';

export default [
  {
    path: '/manager',
    name: 'manager-top',
    component: ManagerTop,
    meta: { isPublic: true }
  },
  {
    // このpathはdevise_token_auth依存なので触らないこと
    path: '/auth/manager/confirm',
    name: 'auth-manager-confirm',
    component: ManagerAuthConfirm,
    meta: { isPublic: true }
  },
  {
    path: '/manager/signup',
    name: 'manager-signup',
    component: ManagerSignup,
    meta: { isPublic: true }
  },
  {
    path: '/manager/signup/email',
    name: 'manager-signup-email',
    component: ManagerSignupEmail,
    meta: { isPublic: true }
  },
  {
    path: '/manager/passwords/reset_send',
    name: 'manager-passwords-reset-send',
    component: ManagerPasswordsResetSend,
    meta: { isPublic: true }
  },
  {
    path: '/manager/passwords/reset',
    name: 'manager-passwords-reset',
    component: ManagerPasswordsReset,
    meta: { isPublic: true }
  },
  {
    path: '/manager/settings/password',
    name: 'manager-settings-password',
    component: ManagerSettingsPassword
  },
  {
    path: '/manager/notices',
    name: 'manager-notices-index',
    component: ManagerNoticesIndex
  },
  {
    path: '/manager/dashboard',
    name: 'manager-dashboard',
    component: ManagerDashBoard
  },
  {
    path: '/manager/clubs',
    name: 'manager-clubs-show',
    component: ManagerClubsShow
  },
  {
    path: '/manager/clubs/edit',
    name: 'manager-clubs-edit',
    component: ManagerClubsForm
  },
  {
    path: '/manager/users',
    name: 'manager-users-index',
    component: ManagerUsersIndex
  },
  {
    path: '/manager/users/:id',
    name: 'manager-users-show',
    component: ManagerUsersShow
  },
  {
    path: '/manager/murmurs',
    name: 'manager-murmurs-index',
    component: ManagerMurmursIndex
  },
  {
    path: '/manager/murmurs/new',
    name: 'manager-murmurs-new',
    component: ManagerMurmursForm
  },
  {
    path: '/manager/murmurs/:id/edit',
    name: 'manager-murmurs-edit',
    component: ManagerMurmursForm
  },
  {
    path: '/manager/murmurs/:id',
    name: 'manager-murmurs-show',
    component: ManagerMurmursShow
  },
  {
    path: '/manager/infos',
    name: 'manager-infos-index',
    component: ManagerInfosIndex
  },
  {
    path: '/manager/infos/new',
    name: 'manager-infos-new',
    component: ManagerInfosForm
  },
  {
    path: '/manager/infos/:id/edit',
    name: 'manager-infos-edit',
    component: ManagerInfosForm
  },
  {
    path: '/manager/infos/:id',
    name: 'manager-infos-show',
    component: ManagerInfosShow
  },
  {
    path: '/manager/galleries',
    name: 'manager-galleries-index',
    component: ManagerGalleriesIndex
  },
  {
    path: '/manager/galleries/new',
    name: 'manager-galleries-new',
    component: ManagerGalleriesForm
  },
  {
    path: '/manager/galleries/:id/edit',
    name: 'manager-galleries-edit',
    component: ManagerGalleriesForm
  },
  {
    path: '/manager/galleries/:id',
    name: 'manager-galleries-show',
    component: ManagerGalleriesShow
  },
  {
    path: '/manager/activities',
    name: 'manager-activities-index',
    component: ManagerActivitiesIndex
  },
  {
    path: '/manager/activities/new',
    name: 'manager-activities-new',
    component: ManagerActivitiesForm
  },
  {
    path: '/manager/activities/:id/edit',
    name: 'manager-activities-edit',
    component: ManagerActivitiesForm
  },
  {
    path: '/manager/activities/:id',
    name: 'manager-activities-show',
    component: ManagerActivitiesShow
  },
  {
    path: '/manager/voices',
    name: 'manager-voices-index',
    component: ManagerVoicesIndex
  },
  {
    path: '/manager/voices/:id',
    name: 'manager-voices-show',
    component: ManagerVoicesShow
  },
  {
    path: '/manager/message_boxes',
    name: 'manager-message-boxes-index',
    component: ManagerMessageBoxesIndex
  },
  {
    path: '/manager/message_boxes/new',
    name: 'manager-message-boxes-new',
    component: ManagerMessageBoxesForm
  },
  {
    path: '/manager/message_boxes/:id/edit',
    name: 'manager-message-boxes-edit',
    component: ManagerMessageBoxesForm
  },
  {
    path: '/manager/message_boxes/:id',
    name: 'manager-message-boxes-show',
    component: ManagerMessageBoxesShow
  },
  {
    path: '/manager/club_bank',
    name: 'manager-club-banks-edit',
    component: ManagerClubBanksForm
  },
  {
    path: '/manager/profile',
    name: 'manager-profiles-show',
    component: ManagerProfilesShow
  },
  {
    path: '/manager/profile/edit',
    name: 'manager-profiles-edit',
    component: ManagerProfilesForm
  },
  {
    path: '/manager/address',
    name: 'manager-address-form',
    component: ManagerAddressForm
  }
];
