<template>
  <v-dialog
    v-model='internalValue'
    :max-width='400'
  >
    <validation-observer ref='observer'>
      <v-form v-on:submit.prevent='save'>
        <v-card>
          <v-card-title>
            カテゴリー登録
          </v-card-title>
          <VDivider />
          <v-card-text>
            <validation-provider
              v-slot='{ errors }'
              name='カテゴリー名'
              rules='required|max:50'
            >
              <v-text-field
                v-model='cloneCategory.name'
                label='カテゴリー名'
                :error-messages='errors'
                :counter='50'
              ></v-text-field>
            </validation-provider>
          </v-card-text>
          <v-card-actions>
            <VSpacer />
            <v-btn
              v-on:click='$emit("cancel")'
            >
              キャンセル
            </v-btn>
            <v-btn
              type='submit'
              color='primary'
            >
              登録
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </validation-observer>
  </v-dialog>
</template>

<script>
  export default {
    props: {
      value: { type: Boolean, required: true },
      category: { type: Object, required: true },
      loading: { type: Boolean, required: true },
    },
    data: () => ({
      cloneCategory: {},
    }),
    computed: {
      internalValue: {
        get() { return this.value },
        set(value) { this.$emit('input', value) }
      },
    },
    watch: {
      category(value) {
        if (this.$refs.observer) { this.$refs.observer.reset() }
        this.cloneCategory = JSON.parse(JSON.stringify(value))
      }
    },
    methods: {
      async save() {
        if (this.loading) { return }
        const isValid = await this.$refs.observer.validate()
        if (!isValid) { return this.$toasted.error(this.$t('errors.params')) }
        this.$emit("save", this.cloneCategory)
      }
    }
  }
</script>
