<template>
  <div class='pa-10'>
    <v-row class='mb-4'>
      <v-col cols='12' md='8'>
        <h2>コミュニティ詳細</h2>
      </v-col>
      <v-col cols='12' md='4' align='right'>
        <v-btn
          color='primary'
          v-on:click='toClubShow'
          outlined
        >
          コミュニティ詳細に戻る
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <validation-observer ref='observer'>
      <v-form v-on:submit.prevent='save'>
        <v-card
          :loading='loading'
          class='mb-6'
        >
          <v-card-text>
            <validation-provider
              v-slot='{ errors }'
              name='コミュニティ紹介文'
              rules='required'
            >
              <v-textarea
                v-model='club.desc'
                label='コミュニティ紹介文'
                :error-messages='errors'
                outlined
                counter='1000'
              />
            </validation-provider>
            <validation-provider
              v-slot='{ errors }'
              name='タグ'
            >
              <v-combobox
                v-model='club.sub_tags'
                label='タグ'
                hint='最大10個まで'
                multiple
                small-chips
                persistent-hint
                item-text='name'
                item-value='name'
                :errors-messages='errors'
              />
            </validation-provider>
            <div class='mt-8'>アイコン</div>
            <v-card
              :max-height='200'
              :max-width='200'
            >
              <FilePondForSingle
                v-model='club.icon'
                :accepted-file-types='acceptedFileTypes'
              />
            </v-card>
            <div class='mt-8'>背景画像</div>
            <v-card
              :max-height='300'
              :max-width='500'
            >
              <FilePondForSingle
                v-model='club.back_image'
                :accepted-file-types='acceptedFileTypes'
              />
            </v-card>
            <div class='mt-8'>デジタル会員証</div>
            <v-card
              :max-height='300'
              :max-width='500'
            >
              <FilePondForSingle
                v-model='club.membership_card_image'
                :accepted-file-types='acceptedFileTypes'
              />
            </v-card>
          </v-card-text>
        </v-card>
        <v-card class='mb-8'>
          <v-card-title>公開日時</v-card-title>
          <v-card-subtitle class='red--text'>
            <div v-if='$$blank(club.bank)'>
              Clubの振込先銀行口座を入力後に登録できるようになります
            </div>
            <div>
              公開されたあとは公開日時は変更できなくなります
            </div>
          </v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-col cols='12' md='6'>
                <validation-provider
                  v-slot='{ errors }'
                  name='開設状況'
                  rules='required'
                >
                  <datetime
                    v-model='club.open_at'
                    :errors-messages='errors'
                    :disabled='disabledOpenAt'
                  />
                </validation-provider>
              </v-col>
              <v-col cols='12' md='6'></v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-row justify='center'>
          <v-btn
            :loading='loading'
            type='submit'
            color='primary'
            class='px-16'
            outlined
          >
            編集内容を保存する
          </v-btn>
        </v-row>
      </v-form>
    </validation-observer>
  </div>
</template>

<script>
  import FilePondForSingle from '@/components/plugins/FilePondForSingle'
  export default {
    components: {
      FilePondForSingle,
    },
    data: () => ({
      loading: false,
      isPublished: false,
      club: {
        sub_tags: [],
        icon: {},
        back_image: {},
        membership_card_image: {},
        bank: {},
      },
    }),
    watch: {
      'club.sub_tags' (val, prev) {
        if(val.length === prev.length) return

        if (val.length > 10) {
          this.$nextTick(() => this.club.sub_tags.pop())
          return
        }

        this.club.sub_tags = val.map(v =>{
          if (typeof v === 'string') {
            v = { name: v }
          }
          return v
        })
      }
    },
    computed: {
      acceptedFileTypes() {
        return 'image/jpg,image/jpeg,image/png'
      },
      disabledOpenAt() {
        return this.$$blank(this.club.bank) || this.isPublished
      },
    },
    created() {
      this.getClub()
    },
    methods: {
      getClub() {
        this.loading = true
        const clubId = this.$store.getters.club.id
        this.axios.get(
          `/api/v1/manager/clubs/${clubId}`
        ).then(res => {
          this.club = res.data.club
          if (this.club.open_at && new Date(this.club.open_at > new Date())) {
            this.isPublished = true
          }
        }).catch(err =>{
          console.log(err)
        }).finally(() => {
          this.loading = false
        })
      },
      toClubShow() {
        this.$router.push({ name: 'manager-clubs-show' })
      },
      async save() {
        if (this.loading) { return }
        this.loading = true
        const clubId = this.$store.getters.club.id
        this.axios.patch(
          `/api/v1/manager/clubs/${clubId}`,
          this.$$castToFormData({ club: this.club }),
          { headers: { "content-type": "multipart/form-data" } }
        ).then(res => {
          this.$router.push({ name: 'manager-clubs-show' }),
          this.$toasted.success(this.$t('success.update'));
          this.club = res.data.club
          this.syncStore()
        }).catch(err => {
          if (err.response.status !== 422) { return }
          this.$toasted.error(err.response.data.errors.join("\n"))
        }).finally(() => {
          this.loading = false
        })
      },
      syncStore() {
        const storeClub = {}
        Object.keys(this.$store.getters.club).forEach(key => {
          storeClub[key] = this.club[key]
        })
        this.$store.dispatch('setClub', storeClub)
      },
    },
    metaInfo() { return this.$t(`meta_tags.${this.$route.name}`) },
  }
</script>
