<template>
  <v-dialog
    v-model='internalValue'
    :max-width='400'
  >
    <v-card>
      <v-card-title>
        カテゴリーの削除確認
      </v-card-title>
      <VDivider class='mb-4' />
      <v-card-text>
        カテゴリーを削除します。よろしいですか？
      </v-card-text>
      <v-card-actions>
        <VSpacer />
        <v-btn
          v-on:click='$emit("cancel")'
        >
          キャンセル
        </v-btn>
        <v-btn
          color='error'
          v-on:click='save'
        >
          削除
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: {
      value: { type: Boolean, required: true },
      loading: { type: Boolean, required: true },
    },
    computed: {
      internalValue: {
        get() { return this.value },
        set(value) { this.$emit('input', value) }
      },
    },
    methods: {
      save() {
        if (this.loading) { return }
        this.$emit("save")
      },
    },
  }
</script>
