<template>
  <v-dialog
    v-model='internalValue'
    :max-width='400'
  >
    <v-card>
      <v-card-title>
        default club
      </v-card-title>
      <VDivider class='mb-4' />
      <v-card-text>
        <v-select
          v-model='selectedItem'
          label='コミュニティ名'
          :items='items'
          clearable
        />
      </v-card-text>
      <v-card-actions>
        <VSpacer/>
        <v-btn
          v-on:click='internalValue = false'
        >
          キャンセル
        </v-btn>
        <v-btn
          color='primary'
          v-on:click='save'
        >
          選択する
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: {
      value: { type: Boolean, required: true },
      club: { type: Object, required: true },
      clubs: { type: Array, required: true },
    },
    data: () => ({
      selectedItem: {},
    }),
    computed: {
      internalValue: {
        get() { return this.value },
        set(value) { this.$emit('input', value) }
      },
      items() {
        return this.clubs.map(club => ({ text: club.name, value: club.id }))
      },
    },
    watch: {
      value(val) {
        if (!val) { return }
        this.selectedItem = this.club.id
      },
    },
    methods: {
      save() {
        this.internalValue = false
        this.$emit('save', this.selectedItem)
      }
    }
  }
</script>
