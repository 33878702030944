<template>
  <div class='pa-10'>
    <v-row class='mb-4'>
      <v-col cols='12' md='8'>
        <h2>パスワード変更</h2>
      </v-col>
    </v-row>

    <v-card class='mb-8 pa-8'>
      <validation-observer ref='observer'>
        <v-form v-on:submit.prevent='save'>
          <validation-provider
            v-slot='{ errors }'
            name='現在のパスワード'
            rules='required|min:8'
          >
            <v-text-field
              v-model='password.current_password'
              label='現在のパスワード'
              :append-icon="currentPasswordShow ? 'mdi-eye' : 'mdi-eye-off'"
              :error-messages='errors'
              outlined
              counter
              :type="currentPasswordShow ? 'text' : 'password'"
              @click:append="currentPasswordShow = !currentPasswordShow"
            ></v-text-field>
          </validation-provider>
          <validation-provider
            v-slot='{ errors }'
            name='新しいパスワード'
            rules='required|min:8|confirmed:confirmation'
          >
            <v-text-field
              v-model='password.password'
              label='新しいパスワード'
              :append-icon="newPasswordShow ? 'mdi-eye' : 'mdi-eye-off'"
              :error-messages='errors'
              outlined
              counter
              :type="newPasswordShow ? 'text' : 'password'"
              @click:append="newPasswordShow = !newPasswordShow"
            ></v-text-field>
          </validation-provider>
          <validation-provider
            v-slot='{ errors }'
            vid='confirmation'
            name='新しいパスワード(確認)'
            rules='required|min:8'
          >
            <v-text-field
              v-model='password.password_confirmation'
              label='新しいパスワード(確認)'
              :append-icon="newPasswordConfirmationShow ? 'mdi-eye' : 'mdi-eye-off'"
              :error-messages='errors'
              outlined
              counter
              :type="newPasswordConfirmationShow ? 'text' : 'password'"
              @click:append="newPasswordConfirmationShow = !newPasswordConfirmationShow"
            ></v-text-field>
          </validation-provider>
          <v-row justify='center'>
            <v-btn
              type='submit'
              color='primary'
              class='px-16 font-weight-bold'
            >
              パスワードを変更する
            </v-btn>
          </v-row>
        </v-form>
      </validation-observer>
    </v-card>
  </div>
</template>

<script>
  export default {
    data: () => ({
      currentPasswordShow: false,
      newPasswordShow: false,
      newPasswordConfirmationShow: false,
      password: {
        current_password: '',
        password: '',
        password_confirmation: ''
      },
    }),
    methods: {
      async save() {
        const isValid = await this.$refs.observer.validate()
        if (!isValid) { return this.$toasted.error(this.$t('errors.params')) }
        this.axios.patch(
          '/api/v1/manager/auth/password', this.password
        ).then(() => {
          this.$router.push({ name: 'manager-dashboard' })
          this.$toasted.success(this.$t('success.update'))
        }).catch(err => {
          if (err.response.status !== 422) { return }
          this.$toasted.error(err.response.data.errors.full_messages.join("\n"))
        })
      }
    },
    metaInfo() { return this.$t(`meta_tags.${this.$route.name}`) },
  }
</script>
