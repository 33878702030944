<template>
  <v-btn
    small
    outlined
    v-on:click='logout'
  >
    logout
  </v-btn>
</template>

<script>
  export default {
    methods: {
      logout() {
        this.axios.delete(
          '/api/v1/admin/auth/sign_out'
        ).then(() => {
          this.$store.dispatch('logout')
          this.$cookies.remove('authInfos')
          this.$router.push({ name: 'admin-top' })
        }).catch(err =>{
          console.log(err)
        })
      }
    }
  };
</script>
