<template>
  <v-card :loading='loading'>
    <v-card-title>
      <v-row>
        <v-col cols='12' md='8'>
          <h4>コミュニティ基本情報</h4>
        </v-col>
        <v-col
          v-if='edit'
          cols='12'
          md='4'
          align='right'
        >
          <v-btn
            color='primary'
            v-on:click='$emit("to-edit")'
            outlined
            small
          >
            編集する
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider />
    <v-simple-table
      class='mouse-over-highlight-disabled'
    >
      <tbody>
        <tr>
          <td class='font-weight-bold'>コミュニティ名</td>
          <td>{{ club.name }}</td>
        </tr>
        <tr>
          <td class='font-weight-bold'>コミュニティ名（ひらがな）</td>
          <td>{{ club.name_hira }}</td>
        </tr>
        <tr>
          <td class='font-weight-bold'>コミュニティカテゴリー</td>
          <td>{{ club.category.name }}</td>
        </tr>
        <tr>
          <td class='font-weight-bold'>アーティスト名/作品名</td>
          <td>{{ club.main_tag.name }}</td>
        </tr>
        <tr>
          <td class='font-weight-bold'>コミュニティ会費</td>
          <td>
            <v-chip
              v-for='(clubs_plan, index) in club.clubs_plans'
              :key='index'
              class='my-2 mr-2'
              label
              v-on:click='priceClick(clubs_plan)'
            >
              {{ $$getEnumText('payment_type', clubs_plan.payment_type) }}
              ：
              <v-icon small>
                mdi-currency-jpy
              </v-icon>
              {{ clubs_plan.price }}
            </v-chip>
            <div
              v-if='admin'
              class='red--text mb-1'
            >
              登録した価格をクリックするとプロダクトIDが表示されます。<br />
              各プロダクトIDをSTOREに登録してからManagerのTOKENを発行してください。
            </div>
          </td>
        </tr>
        <tr>
          <td class='font-weight-bold'>入会者数</td>
          <td>{{ club.users_count }}</td>
        </tr>
        <tr>
          <td class='font-weight-bold'>ステータス</td>
          <td>{{ $$getEnumText('club_item_status', club.club_status) }}</td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-card>
</template>

<script>
  export default {
    props: {
      club: { type: Object, required: true },
      loading: { type: Boolean, required: true },
      edit: { type: Boolean, default: false },
      admin: { type: Boolean, default: false },
    },
    methods: {
      priceClick(clubsPlan) {
        if (this.admin) { this.$emit('click:price', clubsPlan) }
      }
    }
  }
</script>
