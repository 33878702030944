<template>
  <v-card :loading='loading'>
    <v-card-title>
      <v-row>
        <v-col cols='12' md='8'>
          <h4>振込先銀行口座</h4>
        </v-col>
        <v-col
          v-if='edit'
          cols='12'
          md='4'
          align='right'
        >
          <v-btn
            color='primary'
            v-on:click='$emit("to-edit")'
            outlined
            small
          >
            編集する
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider />
    <v-simple-table
      v-if='$$exist(club.bank)'
      class='mouse-over-highlight-disabled'
    >
      <tbody>
        <tr>
          <td class='font-weight-bold'>金融機関</td>
          <td>{{ club.bank.bank_name }}</td>
        </tr>
        <tr>
          <td class='font-weight-bold'>支店</td>
          <td>{{ club.bank.branch_name }}</td>
        </tr>
        <tr>
          <td class='font-weight-bold'>支店コード</td>
          <td>{{ club.bank.branch_cd }}</td>
        </tr>
        <tr>
          <td class='font-weight-bold'>口座番号</td>
          <td>{{ club.bank.account_cd }}</td>
        </tr>
        <tr>
          <td class='font-weight-bold'>口座名義</td>
          <td>{{ club.bank.account_name }}</td>
        </tr>
        <tr>
          <td class='font-weight-bold'>預金種目</td>
          <td>{{ $$getEnumText('deposit_type', club.bank.deposit_type) }}</td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-card-text v-else>
      まだ登録されていません
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    props: {
      club: { type: Object, required: true },
      loading: { type: Boolean, required: true },
      edit: { type: Boolean, default: false },
    },
  }
</script>
