<template>
  <div class='pa-10'>
    <v-row class='mb-4'>
      <v-col cols='12' md='8'>
        <h2>Timeline 一覧</h2>
      </v-col>
    </v-row>

    <v-form
      v-on:submit.prevent='searchTimelines'
      class='mb-10'
    >
      <v-row>
        <v-col cols='12' md='6'>
          <v-text-field
            v-model='search.name'
            label='コミュニティ名'
            hide-details
            clearable
          />
        </v-col>
        <v-col cols='12' md='3'>
          <v-select
            v-model='search.timeline_type'
            label='タイムラインタイプ'
            :items='selectItems.timeline_type'
            hide-details
            clearable
          />
        </v-col>
        <v-col cols='12' md='3'>
          <v-select
            v-model='search.gallery_type'
            label='メディア'
            :items='selectItems.gallery_type'
            hide-details
            clearable
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols='12' md='3'>
          <datetime
            v-model='search.disp_st_at_from'
            label='公開日時From'
            hide-details
            clearable
            type='datetime'
          />
        </v-col>
        <v-col cols='12' md='3'>
          <datetime
            v-model='search.disp_st_at_to'
            label='公開日時To'
            hide-details
            clearable
            type='datetime'
          />
        </v-col>
        <v-col cols='12' md='3'>
          <v-select
            v-model='search.club_item_status'
            label='ステータス'
            hide-details
            clearable
            :items='selectItems.club_item_status'
          />
        </v-col>
        <v-col cols='12' md='3' align='right'>
          <v-btn
            type='submit'
            class='mt-3'
          >
            絞り込む
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <v-card>
      <v-data-table
        :loading='loading'
        :headers='headers'
        :items='timelines'
        v-on:click:row='clickRow'
      >
        <template #item.club='{ item }'>
          <v-avatar size='36' class='mr-2'>
            <v-img :src='item.club.icon.url' />
          </v-avatar>
          {{ item.club.name }}
        </template>
        <template #item.desc='{ item }'>
          <div class='word-breaker'>
            {{ timelineDesc(item) }}
          </div>
        </template>
        <template #item.disp_st_at='{ item }'>
          {{ item.disp_st_at | moment_format('LLL') }}
        </template>
        <template #item.gallery_type='{ item }'>
          {{ $$getEnumText('gallery_type', item.gallery_type) }}
        </template>
        <template #item.club_item_status='{ item }'>
          {{ $$getEnumText('club_item_status', item.club_item_status) }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
  export default {
    data: () => ({
      loading: false,
      search: {
        club_item_status: 'active'
      },
      selectItems: {},
      headers: [],
      timelines: [],
    }),
    computed: {
      PATH() {
        const path = {}
        path.index = `/api/v1/admin/timelines`
        path.search = `${path.index}/search`
        return path
      }
    },
    created() {
      this.init()
      this.getTimelines(this.PATH.index)
    },
    methods: {
      init() {
        this.headers = [
          { text: 'コミュニティ', value: 'club' },
          { text: '内容', value: 'desc' },
          { text: 'メディア', value: 'gallery_type' },
          { text: '公開日時', value: 'disp_st_at' },
          { text: 'ステータス', value: 'club_item_status' }
        ]
        this.selectItems = {
          timeline_type: this.$$selectEnums('timeline_type'),
          gallery_type: this.$$selectEnums('gallery_type'),
          club_item_status: this.$$selectEnums('club_item_status')
        }
      },
      getTimelines(path, params) {
        this.loading = true
        this.axios.get(
          path, { params: params }
        ).then(res => {
          this.timelines = res.data.timelines
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          this.loading = false
        })
      },
      timelineDesc(timeline) {
        switch(timeline.timeline_type) {
          case 'murmur': return timeline.murmur.comment
          case 'info': return timeline.info.title
          case 'gallery': return timeline.gallery.comment
          case 'activity': return timeline.activity.title
        }
      },
      searchTimelines() {
        this.getTimelines(this.PATH.search, this.search)
      },
      clickRow(item) {
        this.$router.push({ name: 'admin-timelines-show', params: { id: item.id } })
      }
    },
    metaInfo() { return this.$t(`meta_tags.${this.$route.name}`) },
  }
</script>
