<template>
  <div class='pa-10'>
    <v-row>
      <v-col cols='12' align='center'>
        <h1>404</h1>
      </v-col>
      <v-col cols='12' align='center'>
        <h2>PAGE NOT FOUND</h2>
      </v-col>
      <v-col cols='12' align='center'>
        ページが見つかりませんでした
      </v-col>
      <v-col cols='12' align='center'>
        <v-btn
          v-on:click='$router.push({ name: pathName })'
          outlined
        >トップへ</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    data: () => ({
      pathName: 'top'
    }),
    created() {
      if (this.$route.path.match(/^\/admin/)) {
        if (this.$store.getters.admin) {
          this.pathName = 'admin-dashboard'
        } else {
          this.pathName = 'admin-top'
        }
      }
      if (this.$route.path.match(/^\/manager/)) {
        if (this.$store.getters.manager) {
          this.pathName = 'manager-dashboard'
        } else {
          this.pathName = 'manager-top'
        }
      }
    },
    metaInfo() { return this.$t(`meta_tags.${this.$route.name}`) },
  }
</script>
