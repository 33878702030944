<template>
  <div class='pa-10'>
    <v-row class='mb-4'>
      <v-col cols='12' md='8'>
        <h2>会員詳細</h2>
      </v-col>
      <v-col cols='12' md='4' align='right'>
        <v-btn
          color='primary'
          text
          v-on:click='toIndex'
        >
          一覧に戻る
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <user-detail
      class='mb-8'
      :user='user'
      :loading='loading'
    />

    <v-row>
      <v-col
        cols='12'
        align='right'
      >
        <btn-dialog-destroy
          v-if='user.user_item_status === "active"'
          :loading='loading'
          title='BAN'
          v-on:save='accountStop'
        />
        <btn-dialog-restore
          v-if='user.user_item_status === "manager_stop"'
          :loading='loading'
          v-on:save='accountStart'
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import UserDetail from './Show/UserDetail'
  import BtnDialogDestroy from '@/components/parts/Btn/Dialog/Destroy'
  import BtnDialogRestore from '@/components/parts/Btn/Dialog/Restore'
  export default {
    components: {
      UserDetail,
      BtnDialogDestroy,
      BtnDialogRestore,
    },
    data: () => ({
      loading: false,
      user: {
        user: {
          profile: {
            icon: {}
          }
        }
      },
    }),
    created() {
      this.getUser()
    },
    methods: {
      getUser() {
        this.loading = true
        const clubId = this.$store.getters.club.id
        this.axios.get(
          `/api/v1/manager/clubs/${clubId}/users/${this.$route.params.id}`
        ).then(res => {
          this.user = res.data.user
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          this.loading = false
        })
      },
      accountStop() {
        if (this.loading) { return }
        this.loading = true
        const clubId = this.$store.getters.club.id
        this.axios.post(
          `/api/v1/manager/clubs/${clubId}/users/${this.$route.params.id}/stop`
        ).then(res => {
          this.user = res.data.user
          this.$toasted.success(this.$t('success.update'))
        }).catch(err => {
          if (err.response.status !== 422) { return }
          this.$toasted.error(err.response.data.errors.join("\n"))
        }).finally(() => {
          this.loading = false
        })
      },
      accountStart() {
        if (this.loading) { return }
        this.loading = true
        const clubId = this.$store.getters.club.id
        this.axios.post(
          `/api/v1/manager/clubs/${clubId}/users/${this.$route.params.id}/start`
        ).then(res => {
          this.user = res.data.user
          this.$toasted.success(this.$t('success.update'))
        }).catch(err => {
          if (err.response.status !== 422) { return }
          this.$toasted.error(err.response.data.errors.join("\n"))
        }).finally(() => {
          this.loading = false
        })
      },
      toIndex() {
        this.$router.push({ name: 'manager-users-index' })
      },
    },
    metaInfo() { return this.$t(`meta_tags.${this.$route.name}`) },
  }
</script>
