import Vue from 'vue'
import VueI18n from 'vue-i18n'
import models_ja from 'json-loader!yaml-loader!@/locales/models/ja.yml'
import enums_ja from 'json-loader!yaml-loader!@/locales/enums/ja.yml'
import vues_ja from 'json-loader!yaml-loader!@/locales/vues/ja.yml'
import meta_tags_ja from 'json-loader!yaml-loader!@/locales/meta_tags/ja.yml'

Vue.use(VueI18n)

// ja配下をmergeする
const jaMessages = {
  ja: {
    ...models_ja.ja,
    ...enums_ja.ja,
    ...vues_ja.ja,
    ...meta_tags_ja.ja,
  }
}
const i18n = new VueI18n({
  locale: 'ja',
  messages: jaMessages,
  fallbackLocale: 'ja',
  silentTranslationWarn: true,
})

export default i18n
