var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-10"},[_c('v-row',{staticClass:"mb-4"},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('h2',[_vm._v("Event投稿")])]),_c('v-col',{attrs:{"cols":"12","md":"4","align":"right"}},[_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.toIndex}},[_vm._v(" 一覧に戻る "),_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)],1),(_vm.$$exist(_vm.timeline))?_c('validation-observer',{ref:"observer"},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.save($event)}}},[_c('v-card',{staticClass:"mb-8",attrs:{"loading":_vm.loading}},[_c('v-card-text',{staticClass:"pb-0"},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":"タイトル","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"タイトル","error-messages":errors,"outlined":"","counter":"100"},model:{value:(_vm.timeline.activity.title),callback:function ($$v) {_vm.$set(_vm.timeline.activity, "title", $$v)},expression:"timeline.activity.title"}})]}}],null,false,921086598)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":"サブタイトル","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"サブタイトル","error-messages":errors,"outlined":"","counter":"100"},model:{value:(_vm.timeline.activity.subtitle),callback:function ($$v) {_vm.$set(_vm.timeline.activity, "subtitle", $$v)},expression:"timeline.activity.subtitle"}})]}}],null,false,460683585)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"イベント開催日","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('datetime',{attrs:{"label":"イベント開催日","error-messages":errors,"outlined":"","type":"date"},model:{value:(_vm.timeline.activity.open_st_at),callback:function ($$v) {_vm.$set(_vm.timeline.activity, "open_st_at", $$v)},expression:"timeline.activity.open_st_at"}})]}}],null,false,1757047293)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"イベント終了日","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('datetime',{attrs:{"label":"イベント終了日","error-messages":errors,"outlined":"","type":"date"},model:{value:(_vm.timeline.activity.open_ed_at),callback:function ($$v) {_vm.$set(_vm.timeline.activity, "open_ed_at", $$v)},expression:"timeline.activity.open_ed_at"}})]}}],null,false,1686859672)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"チケットお申し込み開始日","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('datetime',{attrs:{"label":"チケットお申し込み開始日","error-messages":errors,"outlined":"","type":"date"},model:{value:(_vm.timeline.activity.ticket_st_at),callback:function ($$v) {_vm.$set(_vm.timeline.activity, "ticket_st_at", $$v)},expression:"timeline.activity.ticket_st_at"}})]}}],null,false,2776430362)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"チケットお申し込み終了日","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('datetime',{attrs:{"label":"チケットお申し込み終了日","error-messages":errors,"outlined":"","type":"date"},model:{value:(_vm.timeline.activity.ticket_ed_at),callback:function ($$v) {_vm.$set(_vm.timeline.activity, "ticket_ed_at", $$v)},expression:"timeline.activity.ticket_ed_at"}})]}}],null,false,1645889720)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":"投稿内容","rules":"required|max:1000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"投稿内容","error-messages":errors,"rows":3,"auto-grow":"","outlined":"","counter":"1000"},model:{value:(_vm.timeline.activity.desc),callback:function ($$v) {_vm.$set(_vm.timeline.activity, "desc", $$v)},expression:"timeline.activity.desc"}})]}}],null,false,880425940)})],1)],1)],1)],1),_c('FormTransmission',{staticClass:"mb-8",attrs:{"loading":_vm.loading,"initial-content-type":_vm.timeline.gallery_type,"max-photo-files":4},model:{value:(_vm.timeline.transmissions),callback:function ($$v) {_vm.$set(_vm.timeline, "transmissions", $$v)},expression:"timeline.transmissions"}}),_c('FormReserveSend',{staticClass:"mb-8",attrs:{"loading":_vm.loading},model:{value:(_vm.timeline.disp_st_at),callback:function ($$v) {_vm.$set(_vm.timeline, "disp_st_at", $$v)},expression:"timeline.disp_st_at"}}),_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"px-16",attrs:{"loading":_vm.loading,"type":"submit","color":"primary","outlined":""}},[_vm._v(" 投稿する ")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }