var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-10"},[_c('v-row',{staticClass:"mb-4"},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('h2',[_vm._v("コミュニティ")])]),_c('v-col',{attrs:{"cols":"12","md":"4","align":"right"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.toIndex}},[_vm._v(" 一覧に戻る "),_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)],1),_c('validation-observer',{ref:"observer"},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.save($event)}}},[_c('v-card',{staticClass:"mb-8",attrs:{"loading":_vm.loading}},[_c('v-card-text',[_c('validation-provider',{attrs:{"name":"コミュニティ名","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"コミュニティ名","error-messages":errors,"outlined":"","counter":"100"},model:{value:(_vm.club.name),callback:function ($$v) {_vm.$set(_vm.club, "name", $$v)},expression:"club.name"}})]}}])}),_c('validation-provider',{attrs:{"name":"コミュニティ名（ひらがな）","rules":"required|max:100|zenHira"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"コミュニティ名（ひらがな）","error-messages":errors,"outlined":"","counter":"100"},model:{value:(_vm.club.name_hira),callback:function ($$v) {_vm.$set(_vm.club, "name_hira", $$v)},expression:"club.name_hira"}})]}}])}),_c('validation-provider',{attrs:{"name":"カテゴリー","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"カテゴリー","error-messages":errors,"items":_vm.selectItems.categories,"outlined":"","counter":"100"},model:{value:(_vm.club.category_id),callback:function ($$v) {_vm.$set(_vm.club, "category_id", $$v)},expression:"club.category_id"}})]}}])}),_c('validation-provider',{attrs:{"name":"アーティスト名","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"アーティスト名","error-messages":errors,"outlined":"","counter":"100"},model:{value:(_vm.club.main_tag.name),callback:function ($$v) {_vm.$set(_vm.club.main_tag, "name", $$v)},expression:"club.main_tag.name"}})]}}])}),_c('v-row',[_c('v-col',{staticClass:"my-0",attrs:{"md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"name":"月額","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"月額","items":_vm.PRICES.month,"error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.club.clubs_plans[0].price),callback:function ($$v) {_vm.$set(_vm.club.clubs_plans[0], "price", $$v)},expression:"club.clubs_plans[0].price"}})]}}])})],1),_c('v-col',{staticClass:"my-0",attrs:{"md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"name":"年額","rules":_vm.yearPriceRule},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"年額","items":_vm.PRICES.year,"error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.club.clubs_plans[1].price),callback:function ($$v) {_vm.$set(_vm.club.clubs_plans[1], "price", $$v)},expression:"club.clubs_plans[1].price"}})]}}])})],1)],1)],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"px-16",attrs:{"loading":_vm.loading,"type":"submit","color":"primary","outlined":""}},[_vm._v(" 登録する ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }