<template>
  <v-card>
    <v-data-table
      id='categories-table'
      :loading='loading'
      :headers='headers'
      :items='categories'
      :items-per-page='-1'
      v-on:click:row='edit'
    >
      <template #item.actions='{ item }'>
        <v-btn
          icon
          v-on:click.stop='edit(item)'
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          icon
          color='error'
          v-on:click.stop='destroy(item)'
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-btn
          icon
          class='sortHandle'
          v-on:click.stop
        >
          <v-icon>mdi-drag-vertical</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
  import Sortable from 'sortablejs'
  export default {
    props: {
      categories: { type: Array, required: true },
      loading: { type: Boolean, required: true },
    },
    data: () => ({
      headers: [],
    }),
    created() {
      this.init()
    },
    mounted() {
      this.sortable('#categories-table tbody', this.categories)
    },
    methods: {
      init() {
        this.headers = [
          { text: 'カテゴリー名', value: 'name' },
          { text: 'コミュニティ数', value: 'clubs_count' },
          { text: 'アクション', value: 'actions' },
        ]
      },
      edit(item) {
        this.$emit('edit', item)
      },
      destroy(item) {
        this.$emit('destroy', item)
      },
      sortable(selector) {
        const table = document.querySelector(selector)
        const _this = this
        Sortable.create(table, {
          handle: '.sortHandle',
          animation: 200,
          onEnd({ newIndex, oldIndex }) {
            _this.$emit('sort', _this.categories[oldIndex], newIndex + 1)
          }
        })
      },
    }
  }
</script>
