<template>
  <div class='pa-10'>
    <v-row class='mb-4'>
      <v-col cols='12' md='8'>
        <h2>Voice投稿詳細</h2>
      </v-col>
      <v-col cols='12' md='4' align='right'>
        <v-btn
          color='primary'
          v-on:click='toIndex'
        >
          一覧に戻る
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <voice-detail
      class='mb-8'
      :loading='loading'
      :voice='voice'
    />
    <v-row>
      <v-col align='right'>
        <btn-dialog-destroy
          v-if='voice.user_item_status === "active"'
          :loading='loading'
          v-on:save='destroySave'
        />
        <btn-dialog-restore
          v-if='voice.user_item_status === "manager_stop"'
          :loading='loading'
          v-on:save='restoreSave'
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import VoiceDetail from '@/views/common/voices/Show/Detail'
  import BtnDialogDestroy from '@/components/parts/Btn/Dialog/Destroy'
  import BtnDialogRestore from '@/components/parts/Btn/Dialog/Restore'
  export default {
    components: {
      VoiceDetail,
      BtnDialogDestroy,
      BtnDialogRestore,
    },
    data: () => ({
      loading: false,
      dialog: false,
      voice: {
        user: {
          profile: {
            icon: {}
          },
        },
        voice_tags: [],
        transmissions: []
      },
    }),
    computed: {
      PATH() {
        const clubId = this.$store.getters.club.id
        const voiceId = this.$route.params.id
        const path = {}
        path.show = `/api/v1/manager/clubs/${clubId}/voices/${voiceId}`
        path.stop = `${path.show}/stop`
        path.start = `${path.show}/start`
        return path
      }
    },
    created() {
      this.getVoice()
    },
    methods: {
      getVoice() {
        this.loading = true
        this.axios.get(
          this.PATH.show
        ).then(res => {
          this.voice = res.data.voice
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          this.loading = false
        })
      },
      destroySave() {
        if (this.loading) { return }
        this.loading = true
        this.axios.post(
          this.PATH.stop
        ).then(res => {
          this.dialog = false
          this.voice = res.data.voice
          this.$toasted.success(this.$t('success.update'))
        }).catch(err => {
          if (err.response.status !== 422) { return }
          this.$toasted.error(err.response.data.errors.join("\n"))
        }).finally(() => {
          this.loading = false
        })
      },
      restoreSave() {
        if (this.loading) { return }
        this.loading = true
        this.axios.post(
          this.PATH.start
        ).then(res => {
          this.dialog = false
          this.voice = res.data.voice
          this.$toasted.success(this.$t('success.update'))
        }).catch(err => {
          if (err.response.status !== 422) { return }
          this.$toasted.error(err.response.data.errors.join("\n"))
        }).finally(() => {
          this.loading = false
        })
      },
      toIndex() {
        this.$router.push( {name: 'manager-voices-index'} )
      },
    },
    metaInfo() { return this.$t(`meta_tags.${this.$route.name}`) },
  }
</script>
