var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-10"},[_c('v-row',{staticClass:"mb-4"},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('h2',[_vm._v("振込先銀行口座編集")])]),_c('v-col',{attrs:{"cols":"12","md":"4","align":"right"}},[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":_vm.toClubShow}},[_vm._v(" コミュニティ詳細に戻る "),_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)],1),_c('VDivider'),_c('v-card',{staticClass:"mb-8 pa-8"},[_c('validation-observer',{ref:"observer"},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.save($event)}}},[_c('validation-provider',{attrs:{"name":"銀行名","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"銀行名","error-messages":errors,"outlined":"","counter":"100"},model:{value:(_vm.bank.bank_name),callback:function ($$v) {_vm.$set(_vm.bank, "bank_name", $$v)},expression:"bank.bank_name"}})]}}])}),_c('validation-provider',{attrs:{"name":"支店コード","rules":"required|max:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"支店コード","type":"number","min":"0","error-messages":errors,"outlined":"","counter":"3"},model:{value:(_vm.bank.branch_cd),callback:function ($$v) {_vm.$set(_vm.bank, "branch_cd", $$v)},expression:"bank.branch_cd"}})]}}])}),_c('validation-provider',{attrs:{"name":"支店名","rules":"required|max:60"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"支店名","error-messages":errors,"outlined":"","counter":"60"},model:{value:(_vm.bank.branch_name),callback:function ($$v) {_vm.$set(_vm.bank, "branch_name", $$v)},expression:"bank.branch_name"}})]}}])}),_c('validation-provider',{attrs:{"name":"口座番号","rules":"required|max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"口座番号","type":"number","min":"0","error-messages":errors,"outlined":"","counter":"20"},model:{value:(_vm.bank.account_cd),callback:function ($$v) {_vm.$set(_vm.bank, "account_cd", $$v)},expression:"bank.account_cd"}})]}}])}),_c('validation-provider',{attrs:{"name":"名義","rules":"required|max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"名義","error-messages":errors,"outlined":"","counter":"30"},model:{value:(_vm.bank.account_name),callback:function ($$v) {_vm.$set(_vm.bank, "account_name", $$v)},expression:"bank.account_name"}})]}}])}),_c('validation-provider',{attrs:{"name":"預金項目","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.depositType,"label":"預金種目","error-messages":errors,"outlined":""},model:{value:(_vm.bank.deposit_type),callback:function ($$v) {_vm.$set(_vm.bank, "deposit_type", $$v)},expression:"bank.deposit_type"}})]}}])}),_c('v-row',[_c('v-col',{attrs:{"align":"center"}},[_c('v-btn',{staticClass:"px-16",attrs:{"type":"submit","color":"primary","outlined":""}},[_vm._v(" 編集内容を保存する ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }