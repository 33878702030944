<template>
  <div>
    <div class="d-flex justify-center my-6">
      <h1>Fums</h1>
    </div>
    <v-row justify='center'>
      <v-col cols='12' md='8'>
        <v-form
          v-on:submit.prevent='login'
        >
          <v-text-field
            v-model="auth.email"
            type="email"
            label="E-mail"
            placeholder="Please Enter E-mail."
            outlined
          ></v-text-field>
          <v-text-field
            v-model="auth.password"
            type="password"
            label="Password"
            placeholder="Please Enter Password."
            outlined
          ></v-text-field>
          <v-btn
            type='submit'
            color="black"
            block
            dark
            x-large
          >LOGIN</v-btn>
        </v-form>
        <v-row class='mt-5'>
          <v-col align='right'>
            <v-btn
              text
              v-on:click='toPasswordsResetSend'
            >
              パスワードを忘れた方
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    data: () => ({
      auth: {
        email: '',
        password: ''
      }
    }),
    methods: {
      login() {
        this.axios.post(
          '/api/v1/manager/auth/sign_in/', this.auth
        ).then(res => {
          this.$store.dispatch('loginManager', res.data.manager)
          this.$store.dispatch('loginManagerClub')
          this.$router.push({ name: 'manager-dashboard' })
        }).catch(() => {
        })
      },
      toPasswordsResetSend() {
        this.$router.push({ name: 'manager-passwords-reset-send' })
      }
    },
    metaInfo() { return this.$t(`meta_tags.${this.$route.name}`) },
  }
</script>
