<template>
  <div class='pa-10'>
    <v-row class='mb-4'>
      <v-col cols='12' md='8'>
        <h2>MessageBox投稿内容一覧</h2>
      </v-col>
      <v-col cols='12' md='4' align='right'>
        <v-btn
          color='primary'
          v-on:click='newForm'
        >
          新規投稿する
        </v-btn>
      </v-col>
    </v-row>
    <v-form
      v-on:submit.prevent='searchMessageBoxes'
      class='mb-10'
    >
      <v-row>
        <v-col cols='12' md='6'>
          <v-text-field
            v-model='search.keyword'
            label='キーワード'
            hide-details
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols='12' md='3'>
          <v-select
            v-model='search.gallery_type'
            label='メディア'
            hide-details
            clearable
            :items='selectItems.gallery_type'
          ></v-select>
        </v-col>
      </v-row>
      <v-row class='mb-4'>
        <v-col cols='12' md='3'>
          <datetime
            v-model='search.disp_st_at_from'
            label='投稿日時 (from)'
            hide-details
            type='datetime'
          />
        </v-col>
        <v-col cols='12' md='3'>
          <datetime
            v-model='search.disp_st_at_to'
            label='投稿日時 (to)'
            hide-details
            type='datetime'
          />
        </v-col>
        <v-col cols='12' md='3'>
          <v-select
            v-model='search.club_item_status'
            label='投稿状況'
            hide-details
            clearable
            :items='selectItems.club_item_status'
          ></v-select>
        </v-col>
        <v-col cols='12' md='3' align='right'>
          <v-btn
            outlined
            type='submit'
            class='mt-3'
          >
            絞り込む
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-card>
      <v-data-table
        :loading='loading.messageBoxes'
        :headers='headers'
        :items='messageBoxes'
        v-on:click:row='clickRow'
      >
        <template #item.users='{ item }'>
          <select-users
            title='送信先を確認'
            :loading='loading.users'
            :users='messageBoxesUsers'
            v-on:init='getUsers(item.id)'
          />
        </template>
        <template #item.gallery_type='{ item }'>
          {{ $$getEnumText('gallery_type', item.gallery_type) }}
        </template>
        <template #item.club_item_status='{ item }'>
          {{ $$getEnumText('club_item_status', item.club_item_status) }}
        </template>
        <template #item.disp_st_at='{ item }'>
          {{ item.disp_st_at | moment_format('lll') }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
  import SelectUsers from './Common/Users'
  export default {
    components: {
      SelectUsers,
    },
    data: () => ({
      loading: {
        messageBoxes: false,
        users: false,
      },
      selectItems: {},
      search: {
        club_item_status: 'active'
      },
      headers: [],
      messageBoxes: [],
      messageBoxesUsers: [],
    }),
    computed: {
      PATH() {
        const path = {}
        path.index = `/api/v1/manager/clubs/${this.$store.getters.club.id}/message_boxes`
        path.search = `${path.index}/search`
        return path
      }
    },
    created() {
      this.init()
      this.getMessageBoxes(this.PATH.index)
    },
    methods: {
      init() {
        this.selectItems = {
          gallery_type: this.$$selectEnums('gallery_type'),
          club_item_status: this.$$selectEnums('club_item_status'),
        }
        this.headers = [
          { text: '送信先', value: 'users' },
          { text: 'タイトル', value: 'title' },
          { text: 'メディア', value: 'gallery_type' },
          { text: '投稿日時', value: 'disp_st_at' },
          { text: '投稿状況', value: 'club_item_status' },
        ]
      },
      getMessageBoxes(path, params = null) {
        this.loading.messageBoxes = true
        this.axios.get(
          path, { params: params }
        ).then(res => {
          this.messageBoxes = res.data.message_boxes
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          this.loading.messageBoxes = false
        })
      },
      searchMessageBoxes() {
        this.getMessageBoxes(this.PATH.search, this.search)
      },
      getUsers(message_box_id) {
        this.loading.users = true
        const clubId = this.$store.getters.club.id
        this.axios.get(
          `/api/v1/manager/clubs/${clubId}/message_boxes/${message_box_id}/users`
        ).then(res => {
          this.messageBoxesUsers = res.data.users_clubs
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          this.loading.users = false
        })
      },
      newForm() {
        this.$router.push({ name: 'manager-message-boxes-new' })
      },
      clickRow(item) {
        this.$router.push({ name: 'manager-message-boxes-show', params: { id: item.id } })
      },
    },
    metaInfo() { return this.$t(`meta_tags.${this.$route.name}`) },
  }
</script>
