<template>
  <div class='pa-10'>
    <v-row class='mb-4'>
      <v-col cols='12' md='8'>
        <h2>MessageBox投稿</h2>
      </v-col>
      <v-col cols='12' md='4' align='right'>
        <v-btn
          color='primary'
          text
          v-on:click='toIndex'
        >
          一覧に戻る
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <validation-observer ref='observer' v-if='$$exist(messageBox)'>
      <v-form v-on:submit.prevent='save'>
        <v-card
          :loading='loading.messageBox'
          class='mb-8'
        >
          <v-card-subtitle>送信先設定</v-card-subtitle>
          <v-card-text>
            <select-users
              title='送信先を選択'
              :loading='loading.users'
              :selectedUsers='messageBox.users'
              :users='users'
              show-select
              v-on:decide='selectUsers'
            />
          </v-card-text>
          <v-divider class='my-2' />
          <v-card-text class='pb-0'>
            <validation-provider
              v-slot='{ errors }'
              name='タイトル'
              rules='required|max:100'
            >
              <v-text-field
                v-model='messageBox.title'
                label='タイトル'
                :error-messages='errors'
                outlined
                counter='100'
              ></v-text-field>
            </validation-provider>
            <validation-provider
              v-slot='{ errors }'
              name='投稿内容'
              rules='required|max:1000'
            >
              <v-textarea
                v-model='messageBox.desc'
                label='投稿内容'
                :error-messages='errors'
                :rows='3'
                auto-grow
                outlined
                counter='1000'
              ></v-textarea>
            </validation-provider>
          </v-card-text>
        </v-card>
        <FormTransmission
          class='mb-8'
          v-model='messageBox.transmissions'
          :loading='loading.messageBox'
          :initial-content-type='messageBox.gallery_type'
          :max-photo-files='4'
        />
        <FormReserveSend
          class='mb-8'
          v-model='messageBox.disp_st_at'
          :loading='loading.messageBox'
        />
        <v-row justify='center'>
          <v-btn
            :loading='loading.messageBox'
            type='submit'
            color='primary'
            class='px-16'
            outlined
          >
            投稿する
          </v-btn>
        </v-row>
      </v-form>
    </validation-observer>
  </div>
</template>

<script>
  import FormTransmission from '@/components/parts/Form/Transmission'
  import FormReserveSend from '@/components/parts/Form/ReserveSend'
  import SelectUsers from './Common/Users'
  export default {
    components: {
      FormTransmission,
      FormReserveSend,
      SelectUsers,
    },
    data: () => ({
      loading: {
        messageBox: false,
        users: false
      },
      dialog: false,
      messageBox: {
        title: '',
        desc: '',
        gallery_type: 'nothing',
        disp_st_at: '',
        transmissions: [],
        users: [],
      },
      users: [],
    }),
    created() {
      this.getMessageBoxes()
      this.getUsers()
    },
    computed: {
      isNewForm() {
        return !this.$route.params.id
      },
      PATH() {
        const clubId = this.$store.getters.club.id
        return {
          users: `/api/v1/manager/clubs/${clubId}/users`,
          create: `/api/v1/manager/clubs/${clubId}/message_boxes`,
          edit:   `/api/v1/manager/clubs/${clubId}/message_boxes/${this.$route.params.id}`,
          update: `/api/v1/manager/clubs/${clubId}/message_boxes/${this.$route.params.id}`,
        }
      }
    },
    methods: {
      getMessageBoxes() {
        if (this.isNewForm) { return }
        this.loading.messageBox = true
        this.axios.get(this.PATH.edit).then(res => {
          this.messageBox = res.data.message_box
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          this.loading.messageBox = false
        })
      },
      getUsers() {
        this.loading.users = true
        this.axios.get(this.PATH.users).then(res => {
          this.users = res.data.users
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          this.loading.users = false
        })
      },
      selectUsers(userIds) {
        this.messageBox.users = userIds
      },
      async save() {
        if (this.loading.messageBox) { return }
        const isValid = await this.$refs.observer.validate()
        if (!isValid) { return this.$toasted.error(this.$t('errors.params')) }
        this.loading.messageBox = true
        const path = this.isNewForm ? this.PATH.create : this.PATH.update
        const saveFunction = this.isNewForm ? this.axios.post : this.axios.patch
        saveFunction(
          path,
          this.$$castToFormData({ message_box: this.messageBox }),
          { headers: { "content-type": "multipart/form-data" } }
        ).then(res => {
          this.messageBox = res.data.message_box
          this.$router.push({ name: 'manager-message-boxes-index' })
          this.$toasted.success(this.$t('success.update'))
        }).catch(err => {
          if (err.response.status !== 422) { return }
          this.$toasted.error(err.response.data.errors.join("\n"))
        }).finally(() => {
          this.loading.messageBox = false
        })
      },
      toIndex() {
        this.$router.push({ name: 'manager-message-boxes-index' })
      },
    },
    metaInfo() { return this.$t(`meta_tags.${this.$route.name}`) },
  }
</script>
