<template>
  <v-card :loading='loading'>
    <v-card-title>
      <v-row>
        <v-col cols='12' md='8'>
          <h3>コミュニティ会員数</h3>
        </v-col>
        <v-col cols='12' md='4' align='right'>
          <v-btn
            outlined
            v-on:click='$router.push({ name: "manager-users-index" })'
          >
            会員一覧を確認
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-simple-table
        class='mouse-over-highlight-disabled'
      >
        <thead class='grey lighten-2'>
          <th class='pa-3 text-left'>対象月</th>
          <th class='pa-3'>ユーザ数</th>
        </thead>
        <tbody>
          <tr
            v-for='data in monthlyAnalysisUsersData'
            :key='data.month'
          >
            <td>{{ data.month | moment_format('YYYY年MM月') }}</td>
            <td class='text-center'>{{ data.count }}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    props: {
      monthlyAnalysisUsersData: { type: Array, required: true },
      loading: { type: Boolean, required: true },
    },
  }
</script>
