<template>
  <div class="pa-10">
    <v-row class='mb-4'>
      <v-col cols='12' md='8'>
        <h2>ダッシュボード</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <MonthlyUsersCounts
          :loading='loading.monthlyUsersCounts'
          :monthly-users-counts='monthlyUsersCounts'
        />
      </v-col>
      <v-col>
        <MonthlyVoicesCounts
          :loading='loading.monthlyVoicesCounts'
          :monthly-voices-counts='monthlyVoicesCounts'
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <MonthlyClubsCounts
          :loading='loading.monthlyClubsCounts'
          :monthly-clubs-counts='monthlyClubsCounts'
        />
      </v-col>
      <v-col>
        <MonthlyTimelinesCounts
          :loading='loading.monthlyTimelinesCounts'
          :monthly-timelines-counts='monthlyTimelinesCounts'
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import MonthlyUsersCounts from './DashBoard/MonthlyUsersCounts'
  import MonthlyVoicesCounts from './DashBoard/MonthlyVoicesCounts'
  import MonthlyClubsCounts from './DashBoard/MonthlyClubsCounts'
  import MonthlyTimelinesCounts from './DashBoard/MonthlyTimelinesCounts'
  export default {
    components: {
      MonthlyUsersCounts,
      MonthlyVoicesCounts,
      MonthlyClubsCounts,
      MonthlyTimelinesCounts,
    },
    data: () => ({
      loading: {
        monthlyUsersCounts: false,
        monthlyVoicesCounts: false,
        monthlyClubsCounts: false,
        monthlyTimelinesCounts: false,
      },
      monthlyUsersCounts: [],
      monthlyVoicesCounts: [],
      monthlyClubsCounts: [],
      monthlyTimelinesCounts: [],
    }),
    computed: {
      PATH() {
        return {
          monthlyUsersCounts: '/api/v1/admin/monthly_report/users_counts',
          monthlyVoicesCounts: '/api/v1/admin/monthly_report/voices_counts',
          monthlyClubsCounts: '/api/v1/admin/monthly_report/clubs_counts',
          monthlyTimelinesCounts: '/api/v1/admin/monthly_report/timelines_counts',
        }
      }
    },
    created() {
      this.getMonthlyUsersCounts()
      this.getMonthlyVoicesCounts()
      this.getMonthlyClubsCounts()
      this.getMonthlyTimelinesCounts()
    },
    methods: {
      getMonthlyUsersCounts() {
        this.loading.monthlyUsersCounts = true
        this.axios.get(
          this.PATH.monthlyUsersCounts
        ).then(res => {
          this.monthlyUsersCounts = res.data.monthly_users_counts
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          this.loading.monthlyUsersCounts = false
        })
      },
      getMonthlyVoicesCounts() {
        this.loading.monthlyVoicesCounts = true
        this.axios.get(
          this.PATH.monthlyVoicesCounts
        ).then(res => {
          this.monthlyVoicesCounts = res.data.monthly_voices_counts
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          this.loading.monthlyVoicesCounts = false
        })
      },
      getMonthlyClubsCounts() {
        this.loading.monthlyClubsCounts = true
        this.axios.get(
          this.PATH.monthlyClubsCounts
        ).then(res => {
          this.monthlyClubsCounts = res.data.monthly_clubs_counts
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          this.loading.monthlyClubsCounts = false
        })
      },
      getMonthlyTimelinesCounts() {
        this.loading.monthlyTimelinesCounts = true
        this.axios.get(
          this.PATH.monthlyTimelinesCounts
        ).then(res => {
          this.monthlyTimelinesCounts = res.data.monthly_timelines_counts
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          this.loading.monthlyTimelinesCounts = false
        })
      },
    },
    metaInfo() { return this.$t(`meta_tags.${this.$route.name}`) },
  }
</script>
