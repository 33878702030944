import Vue from 'vue';
import axios from 'axios';
import router from '@/router';
import store from '@/stores';
import i18n from '@/plugins/vue-i18n';
import VueToasted from '@/plugins/vue-toasted';
import VueCookies from 'vue-cookies';

Vue.use(VueToasted)

const http = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT
});

http.interceptors.request.use(request => {
  const authInfos = store.getters.auth

  if (authInfos) {
    request.headers[request.method] = {
      'access-token': authInfos['access-token'],
      'client': authInfos['client'],
      'uid': authInfos['uid']
    }
  }
  return request
}, err => {
  return Promise.reject(err)
})

http.interceptors.response.use(response => {
  setAuthInfos(response)
  return response
}, err => {
  setAuthInfos(err.response)
  switch (err.response.status) {
    case 401: {
      const currentPath = location.pathname
      const managerTopPath = router.resolve({ name: 'manager-top' }).href
      const adminTopPath = router.resolve({ name: 'admin-top' }).href
      if (currentPath !== managerTopPath && currentPath !== adminTopPath) {
        Vue.toasted.error(i18n.tc('errors.login'))
        if (store.getters.admin) {
          router.push({ name: 'admin-top' })
        } else if (store.getters.manager) {
          router.push({ name: 'manager-top' })
        } else {
          router.push({ name: 'top' })
        }
        VueCookies.remove('authInfos')
        store.dispatch('logout')
      } else {
        Vue.toasted.error(i18n.tc('errors.email_or_password'))
      }
      break
    }
    case 404:
      Vue.toasted.error(i18n.tc('errors.notfound'))
      break
    case 422:
      break
    default:
      Vue.toasted.error(i18n.tc('errors.syntax'))
      break
  }
  return Promise.reject(err)
})

function setAuthInfos(response) {
  if (response.headers['access-token']) {
    const authInfos = VueCookies.get('authInfos') || {}
    const tokens = {
      tokens: {
        'access-token': response.headers['access-token'],
        'client': response.headers['client'],
        'uid': response.headers['uid'],
      }
    }
    const newAuthInfos = Object.assign(authInfos, tokens)
    store.dispatch('setAuth', newAuthInfos.tokens)
    VueCookies.set('authInfos', newAuthInfos)
  }
}

export default http
