<template>
  <div class='pa-10'>
    <v-row class='mb-4'>
      <v-col cols='12' md='8'>
        <h2>お知らせ投稿</h2>
      </v-col>
      <v-col cols='12' md='4' align='right'>
        <v-btn
          color='primary'
          text
          v-on:click='toIndex'
        >
          一覧に戻る
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <validation-observer ref='observer'>
      <v-form v-on:submit.prevent='save'>
        <v-card
          :loading='loading'
          class='mb-8'
        >
          <v-card-text>
            <validation-provider
              v-slot='{ errors }'
              name='タイトル'
              rules='required|max:100'
            >
              <v-text-field
                v-model='notice.title'
                label='タイトル'
                :error-messages='errors'
                outlined
                counter='100'
              ></v-text-field>
            </validation-provider>
            <validation-provider
              v-slot='{ errors }'
              name='詳細'
              rules='required|max:1000'
            >
              <v-textarea
                v-model='notice.desc'
                label='詳細'
                :error-messages='errors'
                :rows='3'
                auto-grow
                outlined
                counter='1000'
              ></v-textarea>
            </validation-provider>
            <validation-provider
              v-slot='{ errors }'
              name='表示対象'
              rules='required'
            >
              <v-select
                v-model='notice.target_type'
                label='表示対象'
                :error-messages='errors'
                :items='selectItems.target_type'
                outlined
              ></v-select>
            </validation-provider>
            <validation-provider
              v-slot='{ errors }'
              name='表示日時'
              rules='required'
            >
              <datetime
                v-model='notice.disp_st_at'
                label='表示日時'
                :error-messages='errors'
                outlined
              />
            </validation-provider>
          </v-card-text>
        </v-card>
        <FormTransmission
          class='mb-8'
          v-model='notice.transmissions'
          :loading='loading'
          :initial-content-type='notice.gallery_type'
          :max-photo-files='4'
        />
        <v-row justify='center'>
          <v-btn
            :loading='loading'
            type='submit'
            color='primary'
            class='px-16'
            outlined
          >
            投稿する
          </v-btn>
        </v-row>
      </v-form>
    </validation-observer>
  </div>
</template>

<script>
  import FormTransmission from '@/components/parts/Form/Transmission'
  export default {
    components: {
      FormTransmission,
    },
    data: () => ({
      loading: false,
      selectItems: {},
      notice: {
        title: '',
        desc: '',
        target_type: 'all',
        disp_st_at: new Date().toISOString(),
        transmissions: [],
      },
    }),
    created() {
      this.init()
      this.getNotice()
    },
    computed: {
      isNewForm() {
        return !this.$route.params.id
      },
      PATH() {
        return {
          create: `/api/v1/admin/notices`,
          edit:   `/api/v1/admin/notices/${this.$route.params.id}`,
          update: `/api/v1/admin/notices/${this.$route.params.id}`,
        }
      }
    },
    methods: {
      init() {
        this.selectItems = {
          target_type: this.$$selectEnums('target_type'),
        }
      },
      getNotice() {
        if (this.isNewForm) { return }
        this.loading = true
        this.axios.get(this.PATH.edit).then(res => {
          this.notice = res.data.notice
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          this.loading = false
        })
      },
      async save() {
        if (this.loading) { return }
        const isValid = await this.$refs.observer.validate()
        if (!isValid) { return this.$toasted.error(this.$t('errors.params')) }
        this.loading = true
        const path = this.isNewForm ? this.PATH.create : this.PATH.update
        const saveFunction = this.isNewForm ? this.axios.post : this.axios.patch
        saveFunction(
          path,
          this.$$castToFormData({ notice: this.notice }),
          { headers: { "content-type": "multipart/form-data" } }
        ).then(res => {
          this.notice = res.data.notice
          this.$router.push({ name: 'admin-notices-index' })
          this.$toasted.success(this.$t('success.update'))
        }).catch(err => {
          if (err.response.status !== 422) { return }
          this.$toasted.error(err.response.data.errors.join("\n"))
        }).finally(() => {
          this.loading = false
        })
      },
      toIndex() {
        this.$router.push({ name: 'admin-notices-index' })
      },
    },
    metaInfo() { return this.$t(`meta_tags.${this.$route.name}`) },
  }
</script>
