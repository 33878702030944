<template>
  <v-card :loading='loading'>
    <v-card-title>
      <v-row>
        <v-col cols='12' md='8'>
          <h4>マネージャー情報</h4>
        </v-col>
        <v-col
          cols='12'
          md='4'
          align='right'
        >
          <v-btn
            color='primary'
            v-on:click='$emit("edit")'
            outlined
            small
          >
            編集する
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider />
    <v-simple-table
      class='mouse-over-highlight-disabled'
    >
      <tbody>
        <tr>
          <td class='font-weight-bold'>担当者名</td>
          <td>{{ profile.staff_name }}</td>
        </tr>
        <tr>
          <td class='font-weight-bold'>会社名</td>
          <td>{{ profile.corporation_name }}</td>
        </tr>
        <tr>
          <td class='font-weight-bold'>電話番号</td>
          <td>{{ profile.phone }}</td>
        </tr>
        <tr>
          <td class='font-weight-bold'>ホームページURL</td>
          <td>{{ profile.homepage_url }}</td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-card>
</template>

<script>
  export default {
    props: {
      profile: { type: Object, required: true },
      loading: { type: Boolean, required: true }
    },
  };
</script>
