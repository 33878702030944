<template>
  <div class='pa-10'>
    <v-row class='mb-4'>
      <v-col cols='12' md='8'>
        <h2>Info投稿内容一覧</h2>
      </v-col>
      <v-col cols='12' md='4' align='right'>
        <v-btn
          color='primary'
          v-on:click='newForm'
        >
          新規投稿する
        </v-btn>
      </v-col>
    </v-row>
    <v-form
      v-on:submit.prevent='searchTimelines'
      class='mb-10'
    >
      <v-row>
        <v-col cols='12' md='6'>
          <v-text-field
            v-model='search.keyword'
            label='キーワード'
            hide-details
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols='12' md='3'>
          <v-select
            v-model='search.hashtag'
            label='ハッシュタグ'
            hide-details
            clearable
            :items='selectItems.hashtags'
          ></v-select>
        </v-col>
        <v-col cols='12' md='3'>
          <v-select
            v-model='search.gallery_type'
            label='メディア'
            hide-details
            clearable
            :items='selectItems.gallery_type'
          ></v-select>
        </v-col>
      </v-row>
      <v-row class='mb-4'>
        <v-col cols='12' md='3'>
          <datetime
            v-model='search.disp_st_at_from'
            label='投稿日時 (from)'
            hide-details
            type='datetime'
          />
        </v-col>
        <v-col cols='12' md='3'>
          <datetime
            v-model='search.disp_st_at_to'
            label='投稿日時 (to)'
            hide-details
            type='datetime'
          />
        </v-col>
        <v-col cols='12' md='3'>
          <v-select
            v-model='search.club_item_status'
            label='投稿状況'
            hide-details
            clearable
            :items='selectItems.club_item_status'
          ></v-select>
        </v-col>
        <v-col cols='12' md='3' align='right'>
          <v-btn
            outlined
            type='submit'
            class='mt-3'
          >
            絞り込む
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-card>
      <v-data-table
        :loading='loading'
        :headers='headers'
        :items='timelines'
        v-on:click:row='clickRow'
      >
        <template #item.timeline_tags='{ item }'>
          <v-chip
            v-for='timeline_tag in item.timeline_tags'
            :key='timeline_tag.name'
            class='mr-1'
            small
          >{{ timeline_tag.name }}</v-chip>
        </template>
        <template #item.gallery_type='{ item }'>
          {{ $$getEnumText('gallery_type', item.gallery_type) }}
        </template>
        <template #item.club_item_status='{ item }'>
          {{ $$getEnumText('club_item_status', item.club_item_status) }}
        </template>
        <template #item.disp_st_at='{ item }'>
          {{ item.disp_st_at | moment_format('lll') }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
  export default {
    data: () => ({
      loading: false,
      selectItems: {},
      search: {
        club_item_status: 'active'
      },
      headers: [],
      timelines: [],
    }),
    computed: {
      PATH() {
        const path = {}
        path.index = `/api/v1/manager/clubs/${this.$store.getters.club.id}/timelines/infos`
        path.search = `${path.index}/search`
        path.hashtags = `/api/v1/manager/clubs/${this.$store.getters.club.id}/timeline_tags`
        return path
      }
    },
    created() {
      this.init()
      this.getTimelines(this.PATH.index)
    },
    methods: {
      init() {
        this.selectItems = {
          gallery_type: this.$$selectEnums('gallery_type'),
          club_item_status: this.$$selectEnums('club_item_status'),
        }
        this.getHashtags()
        this.headers = [
          { text: 'タイトル', value: 'info.title', sortable: false },
          { text: 'ハッシュタグ', value: 'timeline_tags', sortable: false },
          { text: 'メディア', value: 'gallery_type' },
          { text: '投稿日時', value: 'disp_st_at' },
          { text: '投稿状況', value: 'club_item_status' },
        ]
      },
      getHashtags() {
        this.axios.get(
          this.PATH.hashtags
        ).then(res => {
          this.selectItems.hashtags = res.data.timeline_tags.map(timeline_tag => timeline_tag.name)
        }).catch(err => {
          console.log(err)
        })
      },
      getTimelines(path, params = null) {
        this.loading = true
        this.axios.get(
          path, { params: params }
        ).then(res => {
          this.timelines = res.data.timelines
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          this.loading = false
        })
      },
      searchTimelines() {
        this.getTimelines(this.PATH.search, this.search)
      },
      newForm() {
        this.$router.push({ name: 'manager-infos-new' })
      },
      clickRow(item) {
        this.$router.push({ name: 'manager-infos-show', params: { id: item.id } })
      },
      searchStart() {
        return false
      },
    },
    metaInfo() { return this.$t(`meta_tags.${this.$route.name}`) },
  }
</script>
