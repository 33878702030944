<template>
  <div class='pa-10'>
    <v-row class='mb-4'>
      <v-col cols='12' md='8'>
        <h2>振込先銀行口座編集</h2>
      </v-col>
      <v-col cols='12' md='4' align='right'>
        <v-btn
          color='primary'
          v-on:click='toClubShow'
          outlined
        >
          コミュニティ詳細に戻る
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <VDivider />

    <v-card class='mb-8 pa-8'>
      <validation-observer ref='observer'>
        <v-form v-on:submit.prevent='save'>
          <validation-provider
            v-slot='{ errors }'
            name='銀行名'
            rules='required|max:100'
          >
            <v-text-field
              v-model='bank.bank_name'
              label='銀行名'
              :error-messages='errors'
              outlined
              counter='100'
            />
          </validation-provider>
          <validation-provider
            v-slot='{ errors }'
            name='支店コード'
            rules='required|max:3'
          >
            <v-text-field
              v-model='bank.branch_cd'
              label='支店コード'
              type='number'
              min='0'
              :error-messages='errors'
              outlined
              counter='3'
            />
          </validation-provider>
          <validation-provider
            v-slot='{ errors }'
            name='支店名'
            rules='required|max:60'
          >
            <v-text-field
              v-model='bank.branch_name'
              label='支店名'
              :error-messages='errors'
              outlined
              counter='60'
            />
          </validation-provider>
          <validation-provider
            v-slot='{ errors }'
            name='口座番号'
            rules='required|max:20'
          >
            <v-text-field
              v-model='bank.account_cd'
              label='口座番号'
              type='number'
              min='0'
              :error-messages='errors'
              outlined
              counter='20'
            />
          </validation-provider>
          <validation-provider
            v-slot='{ errors }'
            name='名義'
            rules='required|max:30'
          >
            <v-text-field
              v-model='bank.account_name'
              label='名義'
              :error-messages='errors'
              outlined
              counter='30'
            />
          </validation-provider>
          <validation-provider
            v-slot='{ errors }'
            name='預金項目'
            rules='required'
          >
            <v-select
              v-model='bank.deposit_type'
              :items='depositType'
              label='預金種目'
              :error-messages='errors'
              outlined
            />
          </validation-provider>
          <v-row>
            <v-col align='center'>
              <v-btn
                type='submit'
                color='primary'
                class='px-16'
                outlined
              >
                編集内容を保存する
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </validation-observer>
    </v-card>
  </div>
</template>

<script>
  export default {
    data: () => ({
      isNewForm: true,
      bank: {
        bank_name: '',
        branch_cd: '',
        branch_name: '',
        account_cd: '',
        account_name: '',
      },
      depositType: [],
    }),
    created() {
      this.init()
      this.getClubBank()
    },
    methods: {
      init() {
        this.depositType = this.$$selectEnums('deposit_type')
      },
      getClubBank() {
        const clubId = this.$store.getters.club.id
        this.axios.get(`/api/v1/manager/clubs/${clubId}/bank`).then(res => {
          if (res.data.bank) {
            this.bank = res.data.bank
            this.isNewForm = false
          } else {
            this.isNewForm = true
          }
        }).catch(err => {
          console.log(err)
        })
      },
      async save() {
        const isValid = await this.$refs.observer.validate()
        if (!isValid) { return this.$toasted.error(this.$t('errors.params')) }
        const saveFunction = this.isNewForm ? this.axios.post : this.axios.patch
        const clubId = this.$store.getters.club.id
        saveFunction(
          `/api/v1/manager/clubs/${clubId}/bank`, this.bank
        ).then(res => {
          this.bank = res.data.bank
          this.$router.push({ name: 'manager-clubs-show' })
          this.$toasted.success(this.$t('success.update'))
        }).catch(err => {
          if (err.response.status !== 422) { return }
          this.$toasted.error(err.response.data.errors.join("\n"))
        })
      },
      toClubShow() {
        this.$router.push({ name: 'manager-clubs-show' })
      }
    },
    metaInfo() { return this.$t(`meta_tags.${this.$route.name}`) },
  }
</script>
