<template>
  <div class='pa-10'>
    <v-row class='mb-4'>
      <v-col cols='12' md='8'>
        <h2>コミュニティ詳細</h2>
      </v-col>
      <v-col cols='12' md='4' align='right'>
        <v-btn
          color='primary'
          v-on:click='toIndex'
        >
          一覧に戻る
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <club-base
      class='mb-6'
      :loading='loading'
      :club='club'
      edit
      admin
      v-on:to-edit='toEdit'
      v-on:click:price='clickPrice'
    />

    <dialog-product-id
      v-model='dialog'
      :productId='productId'
    />

    <club-detail
      class='mb-6'
      :loading='loading'
      :club='club'
    />

    <club-bank
      class='mb-6'
      :loading='loading'
      :club='club'
    />

    <v-row>
      <v-col md='4' cols='12'>
      </v-col>
      <v-col md='4' cols='12' align='center'>
        <club-token
          :token-url='tokenUrl'
          v-on:create-token='createToken'
        />
      </v-col>
      <v-col md='4' cols='12' align='right'>
        <btn-dialog-destroy
          v-if='club.club_status === "active"'
          :loading='loading'
          title='停止'
          v-on:save='stopClub'
        />
        <btn-dialog-restore
          v-else
          :loading='loading'
          title='再開'
          v-on:save='startClub'
        />
      </v-col>
    </v-row>

  </div>
</template>

<script>
  import ClubBase from '@/views/common/clubs/Show/ClubBase'
  import ClubDetail from '@/views/common/clubs/Show/ClubDetail'
  import ClubBank from '@/views/common/clubs/Show/ClubBank'
  import ClubToken from './Show/ClubToken'
  import DialogProductId from './Show/DialogProductId'
  import BtnDialogDestroy from '@/components/parts/Btn/Dialog/Destroy'
  import BtnDialogRestore from '@/components/parts/Btn/Dialog/Restore'
  export default {
    components: {
      ClubBase,
      ClubDetail,
      ClubBank,
      ClubToken,
      DialogProductId,
      BtnDialogDestroy,
      BtnDialogRestore,
    },
    data: () => ({
      loading: false,
      dialog: false,
      club: {
        icon: {},
        back_image: {},
        membership_card_image: {},
        category: {},
        bank: {},
        clubs_plans: [],
        main_tag: {},
        sub_tags: []
      },
      tokenUrl: '',
      productId: '',
    }),
    computed: {
      PATH() {
        const clubId = this.$route.params.id
        const path = {}
        path.show = `/api/v1/admin/clubs/${clubId}`
        path.token = `/api/v1/admin/manager_tokens`
        path.stop = `${path.show}/stop`
        path.start = `${path.show}/start`
        return path
      }
    },
    created() {
      this.getClub()
    },
    methods: {
      getClub() {
        this.loading = true
        this.axios.get(
          this.PATH.show
        ).then(res => {
          this.club = res.data.club
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          this.loading = false
        })
      },
      createToken() {
        this.axios.post(
          this.PATH.token,
          { club_id: this.$route.params.id }
        ).then(res => {
          this.tokenUrl = res.data.url
        }).catch(err => {
          console.log(err)
        })
      },
      stopClub() {
        this.axios.post(
          this.PATH.stop
        ).then(res => {
          this.club = res.data.club
          this.$toasted.success(this.$t('success.update'))
        }).catch(err => {
          if (err.response.status !== 422) { return }
          this.$toasted.error(err.response.data.errors.join("\n"))
        })
      },
      startClub() {
        this.axios.post(
          this.PATH.start
        ).then(res => {
          this.club = res.data.club
          this.$toasted.success(this.$t('success.update'))
        }).catch(err => {
          if (err.response.status !== 422) { return }
          this.$toasted.error(err.response.data.errors.join("\n"))
        })
      },
      clickPrice(clubsPlan) {
        this.productId = clubsPlan.product_id_for_subscription
        this.dialog = true
      },
      toIndex() {
        this.$router.push({ name: 'admin-clubs-index' })
      },
      toEdit() {
        this.$router.push({ name: 'admin-clubs-edit', params: { id: this.$route.params.id } })
      },
    },
    metaInfo() { return this.$t(`meta_tags.${this.$route.name}`) },
  }
</script>
