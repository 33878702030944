<template>
  <div class='pa-10'>
    <v-row class='mb-4'>
      <v-col cols='12' md='8'>
        <h2>Timeline 参照</h2>
      </v-col>
    </v-row>

    <v-card
      v-if='!timeline.timeline_type'
      class='mb-8'
      :loading='loading'
    >
      <v-card-text>loading...</v-card-text>
    </v-card>
    <timeline-murmur
      v-if='timeline.timeline_type === "murmur"'
      class='mb-8'
      :loading='loading'
      :timeline='timeline'
    />
    <timeline-info
      v-if='timeline.timeline_type === "info"'
      class='mb-8'
      :loading='loading'
      :timeline='timeline'
    />
    <timeline-gallery
      v-if='timeline.timeline_type === "gallery"'
      class='mb-8'
      :loading='loading'
      :timeline='timeline'
    />
    <timeline-activity
      v-if='timeline.timeline_type === "activity"'
      class='mb-8'
      :loading='loading'
      :timeline='timeline'
    />
    <v-row>
      <v-col align='right'>
        <btn-dialog-destroy
          v-if='timeline.club_item_status === "active"'
          title='停止'
          :loading='loading'
          v-on:save='destroySave'
        />
        <btn-dialog-restore
          v-else
          :loading='loading'
          v-on:save='restoreSave'
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import TimelineMurmur from '@/views/common/timelines/Show/Murmur'
  import TimelineInfo from '@/views/common/timelines/Show/Info'
  import TimelineGallery from '@/views/common/timelines/Show/Gallery'
  import TimelineActivity from '@/views/common/timelines/Show/Activity'
  import BtnDialogDestroy from '@/components/parts/Btn/Dialog/Destroy'
  import BtnDialogRestore from '@/components/parts/Btn/Dialog/Restore'
  export default {
    components: {
      TimelineMurmur,
      TimelineInfo,
      TimelineGallery,
      TimelineActivity,
      BtnDialogDestroy,
      BtnDialogRestore,
    },
    data: () => ({
      loading: false,
      timeline: {},
    }),
    computed: {
      PATH() {
        const path = {}
        path.show = `/api/v1/admin/timelines/${this.$route.params.id}`
        path.stop = `${path.show}/stop`
        path.start = `${path.show}/start`
        return path
      }
    },
    created() {
      this.getTimeline()
    },
    methods: {
      getTimeline() {
        this.loading = true
        this.axios.get(
          this.PATH.show
        ).then(res => {
          this.timeline = res.data.timeline
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          this.loading = false
        })
      },
      destroySave() {
        if (this.loading) { return }
        this.loading = true
        this.axios.post(
          this.PATH.stop
        ).then(res => {
          this.dialog = false
          this.timeline = res.data.timeline
          this.$toasted.success(this.$t('success.update'))
        }).catch(err => {
          if (err.response.status !== 422) { return }
          this.$toasted.error(err.response.data.errors.join("\n"))
        }).finally(() => {
          this.loading = false
        })
      },
      restoreSave() {
        if (this.loading) { return }
        this.loading = true
        this.axios.post(
          this.PATH.start
        ).then(res => {
          this.dialog = false
          this.timeline = res.data.timeline
          this.$toasted.success(this.$t('success.update'))
        }).catch(err => {
          if (err.response.status !== 422) { return }
          this.$toasted.error(err.response.data.errors.join("\n"))
        }).finally(() => {
          this.loading = false
        })
      },
    },
    metaInfo() { return this.$t(`meta_tags.${this.$route.name}`) },
  }
</script>
