<template>
  <div class='pa-10'>
    <notices
      class='mb-8'
      :loading='loading.notices'
      :notices='notices'
    />
    <voices
      class='mb-8'
      :loading='loading.voices'
      :voices='voices'
    />
    <monthly-registers
      :loading='loading.monthly'
      :monthly-analysis-users-data='monthlyAnalysisUsersData'
    />
  </div>
</template>

<script>
  import Notices from './DashBoard/Notices'
  import Voices from './DashBoard/Voices'
  import MonthlyRegisters from './DashBoard/MonthlyRegisters'
  export default {
    components: {
      Notices,
      Voices,
      MonthlyRegisters
    },
    data: () => ({
      loading: {
        notices: false,
        voices: false,
        monthly: false
      },
      noticesCount: 3,
      voicesCount: 3,
      notices: [],
      voices: [],
      monthlyAnalysisUsersData: [],
    }),
    created() {
      this.getNotices()
      this.getVoices()
      this.getMonthlyRegisters()
    },
    methods: {
      getNotices() {
        this.loading.notices = true
        this.axios.get(
          '/api/v1/manager/notices', {
            params: { count: this.noticesCount }
          }
        ).then(res => {
          this.notices = res.data.notices
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          this.loading.notices = false
        })
      },
      getVoices() {
        this.loading.voices = true
        const clubId = this.$store.getters.club.id
        this.axios.get(
          `/api/v1/manager/clubs/${clubId}/voices`, {
            params: { count: this.voicesCount }
          }
        ).then(res => {
          this.voices = res.data.voices
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          this.loading.voices = false
        })
      },
      getMonthlyRegisters() {
        this.loading.monthly = true
        const clubId = this.$store.getters.club.id
        this.axios.get(
          `/api/v1/manager/clubs/${clubId}/users/monthly_registrations`
        ).then(res => {
          this.monthlyAnalysisUsersData = res.data.monthly_analysis_users_data
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          this.loading.monthly = false
        })
      },
    },
    metaInfo() { return this.$t(`meta_tags.${this.$route.name}`) },
  }
</script>
