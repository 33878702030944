<template>
  <div class='pa-10'>
    <v-row class='mb-4'>
      <v-col cols='12' md='8'>
        <h2>カテゴリー一覧</h2>
      </v-col>
      <v-col cols='12' md='4' align='right'>
        <v-btn
          color='primary'
          v-on:click='openNewForm'
        >
          新規登録
        </v-btn>
      </v-col>
    </v-row>

    <categories-table
      :categories='categories'
      :loading='loading'
      v-on:edit='openEditForm'
      v-on:destroy='openDestroyDialog'
      v-on:sort='sort'
    />

    <categories-form
      v-model='dialog.form'
      :category='category'
      :loading='loading'
      v-on:save='save'
      v-on:cancel='dialog.form = false'
    />

    <categories-destroy
      v-model='dialog.destroy'
      :loading='loading'
      v-on:save='destroyCategory'
      v-on:cancel='dialog.destroy = false'
    />
  </div>
</template>

<script>
  import CategoriesTable from './Index/Table.vue'
  import CategoriesForm from './Index/Form.vue'
  import CategoriesDestroy from './Index/Destroy.vue'
  export default {
    components: {
      CategoriesTable,
      CategoriesForm,
      CategoriesDestroy,
    },
    data: () => ({
      loading: false,
      dialog: {
        form: false,
        destroy: false
      },
      categories: [],
      category: {
        name: ''
      },
    }),
    computed: {
      isNewRecord() {
        return !this.category.id
      },
      PATH() {
        return {
          index: '/api/v1/admin/categories',
          create: '/api/v1/admin/categories',
          update: `/api/v1/admin/categories/${this.category.id}`,
          destroy: `/api/v1/admin/categories/${this.category.id}`,
        }
      }
    },
    created() {
      this.getCategories()
    },
    methods: {
      getCategories() {
        this.loading = true
        this.axios.get(
          this.PATH.index
        ).then(res => {
          this.categories = res.data.categories
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          this.loading = false
        })
      },
      openNewForm() {
        this.category = { name: '' }
        this.dialog.form = true
      },
      openEditForm(item) {
        this.category = item
        this.dialog.form = true
      },
      openDestroyDialog(item) {
        this.category = item
        this.dialog.destroy = true
      },
      async save(cloneCategory) {
        this.loading = true
        const path = this.isNewRecord ? this.PATH.create : this.PATH.update
        const saveFunction = this.isNewRecord ? this.axios.post : this.axios.patch
        saveFunction(
          path, { category: cloneCategory }
        ).then(() => {
          this.getCategories()
          this.dialog.form = false
          this.$toasted.success(this.$t('success.update'))
        }).catch(err => {
          if (err.response.status !== 422) { return }
          this.$toasted.error(err.response.data.errors.join("\n"))
          this.loading = false
        })
      },
      async destroyCategory() {
        this.loading = true
        this.axios.delete(
          this.PATH.destroy
        ).then(() => {
          this.getCategories()
          this.dialog.destroy = false
          this.$toasted.success(this.$t('success.destroy'))
        }).catch(err => {
          if (err.response.status !== 422) { return }
          this.$toasted.error(err.response.data.errors.join("\n"))
          this.loading = false
        })
      },
      async sort(item, position) {
        this.loading = true
        this.axios.post(
          `${this.PATH.index}/${item.id}/sort`,
          { position: position }
        ).then(() => {
          this.$toasted.success('並べ替えました')
          this.getCategories()
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          this.loading = false
        })
      },
    },
    metaInfo() { return this.$t(`meta_tags.${this.$route.name}`) },
  }
</script>
