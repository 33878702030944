<template>
  <v-card :loading='loading'>
    <v-card-subtitle>
      メディア添付
    </v-card-subtitle>
    <VDivider />
    <v-card-text>
      <v-tabs
        v-model='selectTab'
        grow
      >
        <v-tab
          v-for='tab in tabs'
          :key='tab.text'
          :href='`#${tab.value}`'
        >
          {{ tab.text }}
        </v-tab>
      </v-tabs>
      <v-tabs-items
        v-model='selectTab'
      >
        <v-tab-item
          v-for='tab in tabs'
          :key='tab.text'
          :value='tab.value'
          class='pa-8'
        >
          <FilePondForTransmissions
            v-model='internalValue'
            :max-files='tab.maxFiles'
            :accepted-file-types='tab.accept'
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
  import FilePondForTransmissions from '@/components/plugins/FilePondForTransmissions'
  export default {
    components: {
      FilePondForTransmissions,
    },
    props: {
      value: { type: Array, required: true },
      loading: { type: Boolean, required: true },
      maxPhotoFiles: { type: Number, required: true },
      initialContentType: { type: String, default: 'photo' },
    },
    data: () => ({
      selectTab: 'photo',
      skipClear: false,
    }),
    computed: {
      internalValue: {
        get() { return this.value },
        set(value) { this.$emit('input', value) }
      },
      tabs() {
        return [
          { text: 'PHOTO', value: 'photo', accept: 'image/jpg,image/jpeg,image/png', maxFiles: this.maxPhotoFiles },
          { text: 'MOVIE', value: 'movie', accept: 'video/mp4,video/quicktime', maxFiles: 1 },
          { text: 'SOUND', value: 'sound', accept: 'audio/mpeg,audio/x-m4a', maxFiles: 1 },
        ]
      },
    },
    watch: {
      initialContentType(val) {
        if (val !== 'photo') { this.skipClear = true }
        this.selectTab = val
      },
      selectTab() {
        if (!this.skipClear) { this.internalValue = [] }
        this.skipClear = false
      }
    }
  }
</script>
