<template>
  <v-card :loading='loading'>
    <v-simple-table
      class='mouse-over-highlight-disabled'
    >
      <tbody>
        <tr>
          <td class='font-weight-bold'>送信先</td>
          <td>
            <select-users
              title='送信先を確認'
              :loading='loadingUsers'
              :users='users'
              v-on:init='$emit("get-users")'
            />
          </td>
        </tr>
        <tr>
          <td class='font-weight-bold'>タイトル</td>
          <td class='word-breaker'>{{ messageBox.title }}</td>
        </tr>
        <tr>
          <td class='font-weight-bold'>投稿内容</td>
          <td class='word-breaker'>{{ messageBox.desc }}</td>
        </tr>
        <tr>
          <td class='font-weight-bold'>メディア</td>
          <td>
            <FilePondForTransmissions
              v-if='messageBox.transmissions.length > 0'
              v-model='messageBox.transmissions'
              :maxFiles='messageBox.transmissions.length'
              :disabled='true'
            />
          </td>
        </tr>
        <tr>
          <td class='font-weight-bold'>投稿日時</td>
          <td>{{ messageBox.disp_st_at | moment_format('lll') }}</td>
        </tr>
        <tr>
          <td class='font-weight-bold'>ステータス</td>
          <td>{{ $$getEnumText('club_item_status', messageBox.club_item_status) }}</td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-card>
</template>

<script>
  import FilePondForTransmissions from '@/components/plugins/FilePondForTransmissions'
  import SelectUsers from './../Common/Users'
  export default {
    components: {
      FilePondForTransmissions,
      SelectUsers,
    },
    props: {
      messageBox: { type: Object, required: true },
      users: { type: Array, required: true },
      loading: { type: Boolean, required: true },
      loadingUsers: { type: Boolean, required: true },
    }
  }
</script>
