import Vue  from 'vue';
import Vuex from 'vuex';
import VueCookies from 'vue-cookies';

Vue.use(Vuex);
Vue.use(VueCookies);

const store = new Vuex.Store({
  state: {
    admin: null,
    manager: null,
    club: null,
    auth: {},
  },
  getters: {
    admin: state => state.admin,
    manager: state => state.manager,
    club: state => state.club,
    auth: state => state.auth,
  },
  mutations: {
    admin(state, value) {
      state.admin = value
    },
    manager(state, value) {
      state.manager = value
    },
    club(state, value) {
      state.club = value
    },
    auth(state, value) {
      state.auth = value
    },
  },
  actions: {
    loginAdmin(context, payload) {
      context.commit('admin', payload)
      context.commit('manager', null)
      context.commit('club', null)
      const authInfos = VueCookies.get('authInfos')
      authInfos.admin = payload
      delete authInfos.manager
      VueCookies.set('authInfos', authInfos)
    },
    loginManager(context, payload) {
      context.commit('admin', null)
      context.commit('manager', payload)
      context.commit('club', null)
      const authInfos = VueCookies.get('authInfos')
      delete authInfos.admin
      authInfos.manager = payload
      VueCookies.set('authInfos', authInfos)
    },
    loginManagerClub(context) {
      const clubs = context.state.manager.clubs
      const clubInfo = VueCookies.get('clubInfo')
      const clubId = clubInfo ? clubInfo.id : null
      const findClub = clubs.find(club => club.id === clubId)
      if (findClub) {
        context.commit('club', findClub)
        VueCookies.set('clubInfo', findClub)
      } else {
        context.commit('club', clubs[0])
        VueCookies.set('clubInfo', clubs[0])
      }
    },
    setClub(context, payload) {
      context.commit('club', payload)
    },
    setAuth(context, payload) {
      context.commit('auth', payload)
    },
    logout(context) {
      context.commit('admin', null)
      context.commit('manager', null)
      context.commit('club', null)
      context.commit('auth', {})
    }
  }
});

export default store;
