<template>
  <v-card
    :loading='loading'
    class='mb-8'
  >
    <v-card-title>
      <h4>住所情報</h4>
    </v-card-title>
    <v-divider />
    <v-simple-table
      v-if='manager.address'
      class='mouse-over-highlight-disabled'
    >
      <tbody>
        <tr>
          <td class='font-weight-bold'>郵便番号</td>
          <td>{{ manager.address.zip }}</td>
        </tr>
        <tr>
          <td class='font-weight-bold'>都道府県</td>
          <td>{{ $$getEnumText('pref', manager.address.pref) }}</td>
        </tr>
        <tr>
          <td class='font-weight-bold'>市区町村・番地</td>
          <td>{{ manager.address.city }}</td>
        </tr>
        <tr>
          <td class='font-weight-bold'>建物名・号室</td>
          <td>{{ manager.address.sub_address }}</td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-card-text v-else>住所はまだ登録されていません</v-card-text>
  </v-card>
</template>

<script>
  export default {
    props: {
      manager: { type: Object, required: true },
      loading: { type: Boolean, required: true },
    }
  }
</script>
