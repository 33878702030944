<template>
  <div>
    <div class="d-flex justify-center mt-6 mb-6">
      <h1>Fums</h1>
    </div>

    <v-row>
      <v-col align='center'>
        登録処理を行っています。このまま暫くお待ち下さい
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    created() {
      this.authConfirm()
    },
    methods: {
      authConfirm() {
        const token = this.$route.query.confirmation_token
        this.axios.get(
          '/api/v1/manager/auth/confirmation',
          { params: { confirmation_token: token } }
        ).then(() => {
          this.$toasted.success('ご登録が完了しました')
          this.$router.push({ name: 'manager-top' })
        }).catch(() => {
          this.$toasted.error('ご登録に失敗しました。管理者にお問い合わせください')
          this.$router.push({ name: 'manager-top' })
        })
      }
    },
    metaInfo() { return this.$t(`meta_tags.${this.$route.name}`) },
  }
</script>
