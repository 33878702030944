<template>
  <div class='pa-10'>
    <v-row class='mb-4'>
      <v-col cols='12' md='8'>
        <h2>ユーザー 詳細</h2>
      </v-col>
      <v-col cols='12' md='4' align='right'>
        <v-btn
          color='primary'
          text
          v-on:click='toIndex'
        >
          一覧に戻る
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <user-base
      class='mb-8'
      :loading='loading'
      :user='user'
    />
    <v-card
      class='mb-8'
    >
      <v-card-title>アカウントステータスの操作</v-card-title>
      <v-card-text>
        <btn-dialog-destroy
          v-if='user.account_status === "active"'
          btn-class='mr-2'
          :loading='loading'
          title='停止'
          v-on:save='stopUser'
        />
        <btn-dialog-restore
          v-else
          btn-class='mr-2'
          :loading='loading'
          title='再開'
          v-on:save='startUser'
        />
        <btn-dialog-destroy
          :loading='loading'
          title='退会'
          v-on:save='withdrawUser'
        />
      </v-card-text>
    </v-card>
    <user-type
      class='mb-8'
      :user='user'
      v-on:user-type:save='updateUserType'
    >
      <template #user-type-normal>
        <btn-dialog-select-clubs
          :loading='loading'
          :clubs='clubs'
          :joined-clubs='user.clubs'
          v-on:init='getClubs'
          v-on:save='testJoinClubs'
        />
      </template>
    </user-type>
  </div>
</template>

<script>
  import UserBase from './Show/UserBase'
  import UserType from './Show/UserType'
  import BtnDialogSelectClubs from './Show/BtnDialogSelectClubs'
  import BtnDialogDestroy from '@/components/parts/Btn/Dialog/Destroy'
  import BtnDialogRestore from '@/components/parts/Btn/Dialog/Restore'
  export default {
    components: {
      UserBase,
      UserType,
      BtnDialogSelectClubs,
      BtnDialogDestroy,
      BtnDialogRestore,
    },
    data: () => ({
      loading: false,
      stopDialog: false,
      deleteDialog: false,
      user: {
        profile: {
          icon: {}
        },
        clubs: {}
      },
      clubs: [],
    }),
    computed: {
      PATH() {
        const userId = this.$route.params.id
        const path = {}
        path.show = `/api/v1/admin/users/${userId}`
        path.stop = `${path.show}/stop`
        path.start = `${path.show}/start`
        path.withdraw = `${path.show}`
        path.clubs = `/api/v1/admin/clubs`
        path.testJoinClubs = `${path.show}/test_join_clubs`
        path.updateUserType = `${path.show}/user_type`
        return path
      }
    },
    created() {
      this.getUser()
    },
    methods: {
      getUser() {
        this.loading = true
        this.axios.get(
          this.PATH.show
        ).then(res => {
          this.user = res.data.user
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          this.loading = false
        })
      },
      updateUserType(userType) {
        if (this.loading) { return }
        this.loading = true
        this.axios.patch(
          this.PATH.updateUserType,
          { user_type: userType }
        ).then(res => {
          this.dialog = false
          this.user = res.data.user
          this.$toasted.success(this.$t('success.update'))
        }).catch(err => {
          if (err.response.status !== 422) { return }
          this.$toasted.error(err.response.data.errors.join("\n"))
        }).finally(() => {
          this.loading = false
        })
      },
      stopUser() {
        if (this.loading) { return }
        this.loading = true
        this.axios.post(
          this.PATH.stop
        ).then(res => {
          this.dialog = false
          this.user = res.data.user
          this.$toasted.success(this.$t('success.update'))
        }).catch(err => {
          if (err.response.status !== 422) { return }
          this.$toasted.error(err.response.data.errors.join("\n"))
        }).finally(() => {
          this.loading = false
        })
      },
      startUser() {
        if (this.loading) { return }
        this.loading = true
        this.axios.post(
          this.PATH.start
        ).then(res => {
          this.dialog = false
          this.user = res.data.user
          this.$toasted.success(this.$t('success.update'))
        }).catch(err => {
          if (err.response.status !== 422) { return }
          this.$toasted.error(err.response.data.errors.join("\n"))
        }).finally(() => {
          this.loading = false
        })
      },
      withdrawUser() {
        if (this.loading) { return }
        this.loading = true
        this.axios.delete(
          this.PATH.withdraw
        ).then(res => {
          this.dialog = false
          this.user = res.data.user
          this.$toasted.success(this.$t('success.update'))
        }).catch(err => {
          if (err.response.status !== 422) { return }
          this.$toasted.error(err.response.data.errors.join("\n"))
        }).finally(() => {
          this.loading = false
        })
      },
      getClubs() {
        this.loading = true
        this.axios.get(
          this.PATH.clubs
        ).then(res => {
          this.clubs = res.data.clubs
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          this.loading = false
        })
      },
      testJoinClubs(clubs) {
        const clubIds = clubs.map(club => club.id)
        this.loading = true
        this.axios.post(
          this.PATH.testJoinClubs,
          { club_ids: clubIds }
        ).then(res => {
          this.$toasted.success(this.$t('success.update'))
          this.user = res.data.user
        }).catch(err => {
          if (err.response.status !== 422) { return }
          this.$toasted.error(err.response.data.errors.join("\n"))
        }).finally(() => {
          this.loading = false
        })
      },
      toIndex() {
        this.$router.push({ name: 'admin-users-index' })
      },
    },
    metaInfo() { return this.$t(`meta_tags.${this.$route.name}`) },
  }
</script>
