<template>
  <div class='pa-10'>
    <v-row class='mb-8'>
      <v-col align='center'>
        <h2>Fums 運営アカウント登録</h2>
      </v-col>
    </v-row>

    <v-sheet class='text-center'>
      <div class='mb-8'>
        ご登録いただいたメールアドレスにメールを送信しました。
        <br />
        メールにあるリンクから本登録を完了させてください。
      </div>
      <v-btn
        outlined
        v-on:click='$router.push({ name: "manager-top" })'
      >
        LOGIN画面へ
      </v-btn>
    </v-sheet>

  </div>
</template>

<script>
  export default {
    data: () => ({
      auth: {
        email: '',
        password: '',
        password_confirmation: '',
        staff_name: '',
        phone: '',
        club_id: ''
      }
    }),
    created() {
      this.auth.club_id = this.$route.query.club_id
    },
    methods: {
      signup() {
        this.axios.post(
          '/api/v1/manager/auth', this.auth
        ).then(() => {
          this.$router.push({ name: 'manager-top' })
          this.$toasted.success(this.$t('success.email'))
        }).catch(err => {
          if (err.response.status !== 422) { return }
          this.$toasted.error(err.response.data.errors.join("\n"))
        })
      }
    },
    metaInfo() { return this.$t(`meta_tags.${this.$route.name}`) },
  }
</script>
