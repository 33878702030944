<template>
  <file-pond-with-plugins
    ref='pond'
    :allow-multiple='false'
    :accepted-file-types='acceptedFileTypes'
    :files='valueForFilepond'
    :server='server'
    :disabled='disabled'
    v-on:updatefiles='updatefiles'
  />
</template>

<script>
  import VueFilePond from 'vue-filepond'
  import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
  import FilePondPluginMediaPreview from 'filepond-plugin-media-preview'
  import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
  import 'filepond/dist/filepond.min.css'
  import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'
  import 'filepond-plugin-media-preview/dist/filepond-plugin-media-preview.min.css'

  const FilePondWithPlugins = VueFilePond(
    FilePondPluginImagePreview,
    FilePondPluginMediaPreview,
    FilePondPluginFileValidateType,
  )

  export default {
    components: {
      FilePondWithPlugins
    },
    props: {
      value: { required: true },
      acceptedFileTypes: { type: String, default: null },
      disabled: { type: Boolean, default: false },
    },
    computed: {
      internalValue: {
        get() { return this.value },
        set(value) { this.$emit('input', value) }
      },
      server() {
        return {
          process: null,
          load: async (source, load) => {
            const headers = { 'Cache-Control': 'no-cache' }
            await this.axios.get(
              source, { responseType: 'blob', headers: headers }
            ).then(res => {
              load(res.data)
            })
          }
        }
      },
      valueForFilepond() {
        const klass = Object.prototype.toString.call(this.internalValue).slice(8, -1)
        const contentPaths = []
        if (klass === 'File' || this.$$exist(this.internalValue)) {
          const content = 'url' in this.internalValue
            ? { source: this.internalValue.url, options: { type: 'local' } }
            : this.internalValue
          contentPaths.push(content)
        }
        return contentPaths
      },
    },
    methods: {
      updatefiles(filePondObjects) {
        if (filePondObjects.length > 0) {
          const s3url = filePondObjects[0].serverId
          if (s3url) { return }
          this.internalValue = filePondObjects[0].file
        } else {
          this.internalValue = {}
        }
      }
    },
  }
</script>

<style scope lang='scss'>
</style>
