<template>
  <v-card :loading='loading'>
    <v-card-title>
      <v-row>
        <v-col cols='12' md='8'>
          <h4>コミュニティ詳細情報</h4>
        </v-col>
        <v-col
          v-if='edit'
          cols='12'
          md='4'
          align='right'
        >
          <v-btn
            color='primary'
            v-on:click='$emit("to-edit")'
            outlined
            small
          >
            編集する
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider />
    <v-simple-table
      class='mouse-over-highlight-disabled'
    >
      <tbody>
        <tr>
          <td class='font-weight-bold'>開設年月日</td>
          <td>{{ club.open_at | moment_format('lll') }}</td>
        </tr>
        <tr>
          <td class='font-weight-bold'>ハッシュタグ</td>
          <td>
            <v-chip
              v-for='tag in club.sub_tags'
              :key='tag.name'
              class='ma-1 black white--text'
              samll
            >
              {{ tag.name }}
            </v-chip>
          </td>
        </tr>
        <tr>
          <td class='font-weight-bold'>コミュニティ説明文</td>
          <td class='word-breaker'>{{ club.desc }}</td>
        </tr>
        <tr>
          <td class='font-weight-bold'>アイコン</td>
          <td class='pa-4'>
            <v-avatar
              class='mr-2'
              size='144'
            >
              <v-img :src='club.icon.url' />
            </v-avatar>
          </td>
        </tr>
        <tr>
          <td class='font-weight-bold'>背景画像</td>
          <td class='pa-4'>
            <v-img
              :src='club.back_image.url'
              height='270'
              width='480'
            />
          </td>
        </tr>
        <tr>
          <td class='font-weight-bold'>デジタル会員証</td>
          <td class='pa-4'>
            <v-img
              :src='club.membership_card_image.url'
              height='270'
              width='480'
            />
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-card>
</template>

<script>
  export default {
    props: {
      club: { type: Object, required: true },
      loading: { type: Boolean, required: true },
      edit: { type: Boolean, default: false },
    },
  }
</script>
