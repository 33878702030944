<template>
  <div class='pa-10'>
    <v-row class='mb-8'>
      <v-col align='center'>
        <h2>Fums 運営アカウント登録</h2>
      </v-col>
    </v-row>

    <v-row justify='center'>
      <v-col cols='12' md='8'>
        <validation-observer ref='observer'>
          <v-form v-on:submit.prevent='signup'>
            <validation-provider
              v-slot='{ errors }'
              name='email'
              rules='required|max:256'
            >
              <v-text-field
                v-model="auth.email"
                label="email"
                :error-messages='errors'
                outlined
              />
            </validation-provider>
            <validation-provider
              v-slot='{ errors }'
              name='担当者名'
              rules='required|max:30'
            >
              <v-text-field
                v-model="auth.staff_name"
                label="担当者名"
                :error-messages='errors'
                outlined
              />
            </validation-provider>
            <validation-provider
              v-slot='{ errors }'
              name='電話番号'
              rules='required|min:10|max:13'
            >
              <v-text-field
                v-model="auth.phone"
                label="電話番号"
                :error-messages='errors'
                outlined
              />
            </validation-provider>
            <validation-provider
              v-slot='{ errors }'
              name='会社名'
              rules='max:50'
            >
              <v-text-field
                v-model="auth.corporation_name"
                label="会社名"
                :error-messages='errors'
                outlined
              />
            </validation-provider>
            <validation-provider
              v-slot='{ errors }'
              name='ホームページ'
              rules='max:2048'
            >
              <v-text-field
                v-model="auth.homepage_url"
                label="ホームページ"
                :error-messages='errors'
                outlined
              />
            </validation-provider>
            <v-divider class='mb-8' />
            <validation-provider
              v-slot='{ errors }'
              name='password'
              rules='required|min:8|max:256|confirmed:confirmation'
            >
              <v-text-field
                v-model="auth.password"
                type="password"
                label="password"
                :error-messages='errors'
                outlined
              />
            </validation-provider>
            <validation-provider
              v-slot='{ errors }'
              vid='confirmation'
              name='password（再入力）'
              rules='required|min:8|max:256'
            >
              <v-text-field
                v-model="auth.password_confirmation"
                type="password"
                label="password（再入力）"
                :error-messages='errors'
                outlined
              />
            </validation-provider>
            <v-row justify='center'>
              <v-col cols="4">
                <v-btn
                  type='submit'
                  color="primary"
                  block
                  outlined
                >SIGNUP</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </validation-observer>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    data: () => ({
      auth: {
        email: '',
        password: '',
        password_confirmation: '',
        staff_name: '',
        phone: '',
        managers_club_id: ''
      }
    }),
    created() {
      this.auth.managers_club_id = this.$route.query.token
    },
    methods: {
      signup() {
        this.axios.post(
          '/api/v1/manager/auth', this.auth
        ).then(() => {
          this.$router.push({ name: 'manager-signup-email' })
        }).catch(err => {
          if (err.response.status !== 422) { return }
          this.$toasted.error(err.response.data.errors.join("\n"))
        })
      }
    },
    metaInfo() { return this.$t(`meta_tags.${this.$route.name}`) },
  }
</script>
