<template>
  <div class='pa-10'>
    <v-row class='mb-4'>
      <v-col cols='12' md='8'>
        <h2>ユーザー 一覧</h2>
      </v-col>
    </v-row>

    <v-form
      v-on:submit.prevent='searchUsers'
      class='mb-10'
    >
      <v-row>
        <v-col cols='12' md='6'>
          <v-text-field
            v-model='search.nickname'
            label='ニックネーム'
            hide-details
            clearable
          />
        </v-col>
        <v-col cols='12' md='6'>
          <v-text-field
            v-model='search.email'
            label='メールアドレス'
            hide-details
            clearable
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols='12' md='3'>
          <v-select
            v-model='search.gender'
            label='性別'
            :items='selectItems.gender'
            hide-details
            clearable
          />
        </v-col>
        <v-col cols='12' md='3'>
          <v-select
            v-model='search.pref'
            label='都道府県'
            :items='selectItems.pref'
            hide-details
            clearable
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols='12' md='3'>
          <datetime
            v-model='search.created_at_from'
            label='作成日From'
            hide-details
            type='datetime'
          />
        </v-col>
        <v-col cols='12' md='3'>
          <datetime
            v-model='search.created_at_to'
            label='作成日To'
            hide-details
            type='datetime'
          />
        </v-col>
        <v-col cols='12' md='3'>
          <v-select
            v-model='search.account_status'
            label='ステータス'
            hide-details
            clearable
            :items='selectItems.account_status'
          />
        </v-col>
        <v-col cols='12' md='3' align='right'>
          <v-btn
            type='submit'
            class='mt-3'
          >
            絞り込む
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <v-card>
      <v-data-table
        :loading='loading'
        :headers='headers'
        :items='users'
        v-on:click:row='clickRow'
      >
        <template #item.profile.nickname='{ item }'>
          <v-avatar
            class='mr-2'
            size='36'
          >
            <v-img :src='item.profile.icon.url' />
          </v-avatar>
          {{ item.profile.nickname }}
        </template>
        <template #item.profile.gender='{ item }'>
          {{ $$getEnumText('gender', item.profile.gender) }}
        </template>
        <template #item.profile.pref='{ item }'>
          {{ $$getEnumText('pref', item.profile.pref) }}
        </template>
        <template #item.created_at='{ item }'>
          {{ item.created_at | moment_format('lll') }}
        </template>
        <template #item.account_status='{ item }'>
          {{ $$getEnumText('account_status', item.account_status) }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
  export default {
    data: () => ({
      loading: false,
      search: {
        account_status: 'active'
      },
      selectItems: {},
      headers: [],
      users: [],
    }),
    computed: {
      PATH() {
        const path = {}
        path.index = `/api/v1/admin/users`
        path.search = `${path.index}/search`
        return path
      }
    },
    created() {
      this.init()
      this.getUsers(this.PATH.index)
    },
    methods: {
      init() {
        this.headers = [
          { text: 'ニックネーム', value: 'profile.nickname' },
          { text: 'メールアドレス', value: 'email' },
          { text: '性別', value: 'profile.gender' },
          { text: 'お住まいの都道府県', value: 'profile.pref' },
          { text: '作成日', value: 'created_at'},
          { text: 'ステータス', value: 'account_status' }
        ]
        this.selectItems = {
          gender: this.$$selectEnums('gender'),
          pref: this.$$selectEnums('pref'),
          account_status: this.$$selectEnums('account_status')
        }
      },
      getUsers(path, params) {
        this.loading = true
        this.axios.get(
          path, { params: params }
        ).then(res => {
          this.users = res.data.users
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          this.loading = false
        })
      },
      searchUsers() {
        this.getUsers(this.PATH.search, this.search)
      },
      clickRow(item) {
        this.$router.push({ name: 'admin-users-show', params: { id: item.id } })
      }
    },
    metaInfo() { return this.$t(`meta_tags.${this.$route.name}`) },
  }
</script>
