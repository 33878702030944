<template>
  <v-card :loading='loading'>
    <v-simple-table
      class='mouse-over-highlight-disabled'
    >
      <tbody>
        <tr v-if='dispClub'>
          <td class='font-weight-bold'>コミュニティ</td>
          <td>
            <v-avatar
              class='mr-2'
              size='36'
            >
              <v-img :src='voice.club.icon.url' />
            </v-avatar>
            {{ voice.club.name }}
          </td>
        </tr>
        <tr>
          <td class='font-weight-bold'>投稿者</td>
          <td>
            <v-avatar
              class='mr-2'
              size='36'
            >
              <v-img :src='voice.user.profile.icon.url' />
            </v-avatar>
            {{ voice.user.profile.nickname }}
          </td>
        </tr>
        <tr>
          <td class='font-weight-bold'>投稿内容</td>
          <td class='word-breaker'>{{ voice.msg }}</td>
        </tr>
        <tr>
          <td class='font-weight-bold'>ハッシュタグ</td>
          <td>
            <v-chip
              v-for='voice_tag in voice.voice_tags'
              class='ma-1'
              :key='voice_tag.name'
              small
            >
              {{ voice_tag.name }}
            </v-chip>
          </td>
        </tr>
        <tr>
          <td class='font-weight-bold'>メディア</td>
          <td>
            <FilePondForTransmissions
              v-if='voice.transmissions.length > 0'
              v-model='voice.transmissions'
              :maxFiles='voice.transmissions.length'
              :disabled='true'
            />
          </td>
        </tr>
        <tr>
          <td class='font-weight-bold'>通報件数</td>
          <td>{{ voice.voices_violation_reports_count }}</td>
        </tr>
        <tr>
          <td class='font-weight-bold'>投稿日時</td>
          <td>{{ voice.created_at | moment_format('lll') }}</td>
        </tr>
        <tr>
          <td class='font-weight-bold'>ステータス</td>
          <td>{{ $$getEnumText('user_item_status', voice.user_item_status) }}</td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-card>
</template>

<script>
  import FilePondForTransmissions from '@/components/plugins/FilePondForTransmissions'
  export default {
    components: {
      FilePondForTransmissions,
    },
    props: {
      voice: { type: Object, required: true },
      loading: { type: Boolean, required: true },
      dispClub: { type: Boolean, default: false }
    }
  }
</script>
