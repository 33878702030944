<template>
  <v-card class='mb-8'>
    <v-card-title>アカウントタイプの操作</v-card-title>
    <v-card-subtitle class='red--text'>
      このエリアは管理者用のテストユーザー以外は触らないでください。
      <br />
      操作前に１度developerより説明を受けた上で操作するようにしてください。
    </v-card-subtitle>
    <v-divider />
    <v-card-text>
      <v-row>
        <v-col v-if='isNormal'>
          <v-btn
            v-if='user.profile.user_type === "normal"'
            class='mr-2'
            outlined
            v-on:click='$emit("user-type:save", "test")'
          >
            テストアカウントに変更
          </v-btn>
        </v-col>
        <v-col v-if='isTest'>
          <v-btn
            v-if='user.profile.user_type === "test"'
            class='mr-2'
            outlined
            v-on:click='$emit("user-type:save", "normal")'
          >
            通常アカウントに戻す
          </v-btn>
          <slot name='user-type-normal'></slot>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    props: {
      user: { type: Object, required: true },
    },
    computed: {
      isNormal() {
        return this.user.profile.user_type === 'normal'
      },
      isTest() {
        return this.user.profile.user_type === 'test'
      },
    }
  }
</script>
