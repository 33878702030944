import AdminTop from '@/views/admin/bases/Top.vue';
import AdminDashBoard from '@/views/admin/bases/DashBoard.vue';
import AdminSetting from '@/views/admin/bases/Setting.vue';
import AdminCategoriesIndex from '@/views/admin/categories/Index.vue';
import AdminClubsIndex from '@/views/admin/clubs/Index.vue';
import AdminClubsShow from '@/views/admin/clubs/Show.vue';
import AdminClubsForm from '@/views/admin/clubs/Form.vue';
import AdminClubsUpdateHistoriesIndex from '@/views/admin/clubs_update_histories/Index.vue';
import AdminTimelinesIndex from '@/views/admin/timelines/Index.vue';
import AdminTimelinesShow from '@/views/admin/timelines/Show.vue';
import AdminVoicesIndex from '@/views/admin/voices/Index.vue';
import AdminVoicesShow from '@/views/admin/voices/Show.vue';
import AdminManagersIndex from '@/views/admin/managers/Index.vue';
import AdminManagersShow  from '@/views/admin/managers/Show.vue';
import AdminUsersIndex from '@/views/admin/users/Index.vue';
import AdminUsersShow from '@/views/admin/users/Show.vue';
import AdminNoticesIndex from '@/views/admin/notices/Index.vue';
import AdminNoticesShow from '@/views/admin/notices/Show.vue';
import AdminNoticesForm from '@/views/admin/notices/Form.vue';

export default [
  {
    path: '/admin',
    name: 'admin-top',
    component: AdminTop,
    meta: { isPublic: true }
  },
  {
    path: '/admin/dashboard',
    name: 'admin-dashboard',
    component: AdminDashBoard
  },
  {
    path: '/admin/setting',
    name: 'admin-setting',
    component: AdminSetting
  },
  {
    path: '/admin/categories',
    name: 'admin-categories-index',
    component: AdminCategoriesIndex
  },
  {
    path: '/admin/clubs',
    name: 'admin-clubs-index',
    component: AdminClubsIndex
  },
  {
    path: '/admin/clubs/new',
    name: 'admin-clubs-new',
    component: AdminClubsForm
  },
  {
    path: '/admin/clubs/:id/edit',
    name: 'admin-clubs-edit',
    component: AdminClubsForm
  },
  {
    path: '/admin/clubs/:id',
    name: 'admin-clubs-show',
    component: AdminClubsShow
  },
  {
    path: '/admin/clubs_update_histories',
    name: 'admin-clubs-update-histories',
    component: AdminClubsUpdateHistoriesIndex
  },
  {
    path: '/admin/timelines',
    name: 'admin-timelines-index',
    component: AdminTimelinesIndex
  },
  {
    path: '/admin/timelines/:id',
    name: 'admin-timelines-show',
    component: AdminTimelinesShow
  },
  {
    path: '/admin/voices',
    name: 'admin-voices-index',
    component: AdminVoicesIndex
  },
  {
    path: '/admin/voices/:id',
    name: 'admin-voices-show',
    component: AdminVoicesShow
  },
  {
    path: '/admin/managers',
    name: 'admin-managers-index',
    component: AdminManagersIndex
  },
  {
    path: '/admin/managers/:id',
    name:  'admin-managers-show',
    component: AdminManagersShow
  },
  {
    path: '/admin/users',
    name: 'admin-users-index',
    component: AdminUsersIndex
  },
  {
    path: '/admin/users/:id',
    name: 'admin-users-show',
    component: AdminUsersShow
  },
  {
    path: '/admin/notices',
    name: 'admin-notices-index',
    component: AdminNoticesIndex
  },
  {
    path: '/admin/notices/new',
    name: 'admin-notices-new',
    component: AdminNoticesForm
  },
  {
    path: '/admin/notices/:id/edit',
    name: 'admin-notices-edit',
    component: AdminNoticesForm
  },
  {
    path: '/admin/notices/:id',
    name: 'admin-notices-show',
    component: AdminNoticesShow
  },
]
