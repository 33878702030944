<template>
  <div>
    <v-text-field
      :value='formattedValue'
      :label='label'
      autocomplete='off'
      prepend-icon='mdi-calendar'
      :error-messages='errorMessages'
      :disabled='disabled'
      :outlined='outlined'
      :hide-details='hideDetails'
      clearable
      v-on:keydown.prevent
      v-on:click='click'
      v-on:click:clear='clear'
    />
    <vue-datetime
      ref='VueDatetime'
      v-model="internalValue"
      input-class='d-none'
      :type='type'
      :phrases='{ ok: "ok", cancel: "cancel" }'
      :week-start='7'
    />
  </div>
</template>

<script>
  // ----------------------------------------------------
  // https://vuetifyjs.com/en/api/v-text-field/#props
  // https://github.com/mariomka/vue-datetime
  // ----------------------------------------------------
  export default {
    name: 'Datetime',
    props: {
      value: { required: true },
      label: { type: String, default: '' },
      disabled: { type: Boolean, default: false },
      errorMessages: { type: Array, default: () => { [] } },
      outlined: { type: Boolean, default: false },
      type: { type: String, default: 'datetime' },
      hideDetails: { type: Boolean, default: false },
    },
    computed: {
      internalValue: {
        get() { return this.value },
        set(value) { this.$emit('input', value) }
      },
      formattedValue() {
        if (!this.internalValue) { return }
        let format = 'lll'
        switch (this.type) {
          case 'datetime': format = 'lll'; break
          case 'date': format = 'll'; break
          case 'time': format = 'LT'; break
        }
        return this.$options.filters.moment_format(this.internalValue, format)
      },
    },
    methods: {
      click() {
        this.$refs.VueDatetime.isOpen = true
      },
      clear() {
        this.internalValue = null
      },
    },
  }
</script>

<style scoped>
</style>
