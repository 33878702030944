<template>
  <div class='pa-10'>
    <v-row class='mb-4'>
      <v-col cols='12' md='8'>
        <h2>コミュニティ詳細</h2>
      </v-col>
    </v-row>

    <club-base
      class='mb-6'
      :loading='loading'
      :club='club'
    />

    <club-detail
      class='mb-6'
      :loading='loading'
      :club='club'
      edit
      v-on:to-edit='toClubEdit'
    />

    <club-bank
      class='mb-6'
      :loading='loading'
      :club='club'
      edit
      v-on:to-edit='toClubBankEdit'
    />
  </div>
</template>

<script>
  import ClubBase from '@/views/common/clubs/Show/ClubBase'
  import ClubDetail from '@/views/common/clubs/Show/ClubDetail'
  import ClubBank from '@/views/common/clubs/Show/ClubBank'
  export default {
    components: {
      ClubBase,
      ClubDetail,
      ClubBank,
    },
    data: () => ({
      loading: false,
      club: {
        category: {},
        main_tag: {},
        sub_tags: [],
        clubs_plans: [],
        bank: {},
        icon: {},
        back_image: {},
        membership_card_image: {}
      }
    }),
    created() {
      this.getClub()
    },
    methods: {
      getClub() {
        this.loading = true
        const clubId = this.$store.getters.club.id
        this.axios.get(
          `/api/v1/manager/clubs/${clubId}`
        ).then(res => {
          this.club = res.data.club
        }).catch(err =>{
          console.log(err)
        }).finally(() => {
          this.loading = false
        })
      },
      toClubEdit() {
        this.$router.push({ name: 'manager-clubs-edit' })
      },
      toClubBankEdit() {
        this.$router.push({ name: 'manager-club-banks-edit' })
      }
    },
    metaInfo() { return this.$t(`meta_tags.${this.$route.name}`) },
  }
</script>
