<template>
  <div class='pa-10'>
    <v-row class='mb-4'>
      <v-col cols='12' md='8'>
        <h2>コミュニティ更新履歴</h2>
      </v-col>
    </v-row>

    <v-card>
      <v-data-table
        :loading='loading'
        :headers='headers'
        :items='clubsUpdateHistories'
        v-on:click:row='clickRow'
      >
        <template #item.club.name='{ item }'>
          <v-avatar
            class='mr-2'
            size='36'
          >
            <v-img :src='item.club.icon.url ? item.club.icon.url : defaultIcon' />
          </v-avatar>
          {{ item.club.name }}
        </template>
        <template #item.created_at='{ item }'>
          {{ item.created_at | moment_format('lll') }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
  export default {
    data: () => ({
      loading: false,
      headers: [],
      clubsUpdateHistories: [],
      defaultIcon: 'http://placehold.jp/20/cc9999/993333/200x200.png'
    }),
    created() {
      this.init()
      this.getClubsUpdateHistories()
    },
    methods: {
      init() {
        this.headers = [
          { text: '更新時間', value: 'created_at' },
          { text: 'コミュニティ名', value: 'club.name' },
          { text: 'コミュニティ名（ひらがな）', value: 'club.name_hira' }
        ]
      },
      getClubsUpdateHistories() {
        this.loading = true
        this.axios.get('/api/v1/admin/clubs_update_histories').then(res => {
          this.clubsUpdateHistories = res.data.clubs_update_histories
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          this.loading = false
        })
      },
      clickRow(item) {
        this.$router.push({ name: 'admin-clubs-show', params: { id: item.club.id }})
      }
    },
    metaInfo() { return this.$t(`meta_tags.${this.$route.name}`) },
  }
</script>
