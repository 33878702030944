<template>
  <div class='pa-10'>
    <v-row class='mb-4'>
      <v-col cols='12' md='8'>
        <h2>マネージャー詳細</h2>
      </v-col>
      <v-col cols='12' md='4' align='right'>
        <v-btn
          color='primary'
          text
          v-on:click='toIndex'
        >
          一覧に戻る
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <manager-base
      class='mb-8'
      :loading='loading'
      :manager='manager'
    />

    <manager-address
      class='mb-8'
      :loading='loading'
      :manager='manager'
    />

    <v-row>
      <v-col cols='12' align='right'>
        <btn-dialog-destroy
          v-if='manager.account_status === "active"'
          :loading='loading'
          title='停止'
          v-on:save='stopManager'
        />
        <btn-dialog-restore
          v-else
          :loading='loading'
          title='再開'
          v-on:save='startManager'
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import ManagerBase from './Show/ManagerBase'
  import ManagerAddress from './Show/ManagerAddress'
  import BtnDialogDestroy from '@/components/parts/Btn/Dialog/Destroy'
  import BtnDialogRestore from '@/components/parts/Btn/Dialog/Restore'
  export default {
    components: {
      ManagerBase,
      ManagerAddress,
      BtnDialogDestroy,
      BtnDialogRestore,
    },
    data: () => ({
      loading: false,
      dialog: false,
      manager: {
        profile: {},
        address: {},
        clubs: []
      }
    }),
    computed: {
      PATH() {
        const managerId = this.$route.params.id
        const path = {}
        path.show = `/api/v1/admin/managers/${managerId}`
        path.stop = `${path.show}/stop`
        path.start = `${path.show}/start`
        return path
      }
    },
    created() {
      this.getManager();
    },
    methods: {
      getManager() {
        this.loading = true
        this.axios.get(
          this.PATH.show
        ).then(res => {
          this.manager = res.data.manager
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          this.loading = false
        })
      },
      stopManager() {
        if (this.loading) { return }
        this.loading = true
        this.axios.post(
          this.PATH.stop
        ).then(res => {
          this.dialog = false
          this.manager = res.data.manager
          this.$toasted.success(this.$t('success.update'))
        }).catch(err => {
          if (err.response.status !== 422) { return }
          this.$toasted.error(err.response.data.errors.join("\n"))
        }).finally(() => {
          this.loading = false
        })
      },
      startManager() {
        if (this.loading) { return }
        this.loading = true
        this.axios.post(
          this.PATH.start
        ).then(res => {
          this.dialog = false
          this.manager = res.data.manager
          this.$toasted.success(this.$t('success.update'))
        }).catch(err => {
          if (err.response.status !== 422) { return }
          this.$toasted.error(err.response.data.errors.join("\n"))
        }).finally(() => {
          this.loading = false
        })
      },
      toIndex() {
        this.$router.push({ name: 'admin-managers-index' })
      },
    },
    metaInfo() { return this.$t(`meta_tags.${this.$route.name}`) },
  }
</script>
