<template>
  <v-menu left bottom>
    <template v-slot:activator='{ on, attrs }'>
      <v-chip
        pill
        v-bind='attrs'
        v-on='on'
      >
        <v-avatar left>
          <img
            v-if='$store.getters.club'
            :src='$store.getters.club.icon.url'
          />
        </v-avatar>
        {{ $store.getters.club.name }}
      </v-chip>
    </template>

    <v-list>
      <v-list-item
        v-for='club in clubs'
        :key='club.id'
        v-on:click='selectClub(club)'
      >
        <v-list-item-title>{{ club.name }}</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-on:click='logout'
      >
        logout
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
  export default {
    computed: {
      clubs() {
        return this.$store.getters.manager.clubs
      }
    },
    methods: {
      selectClub(club) {
        this.$cookies.set('clubInfo', club)
        this.$store.dispatch('setClub', club)
        this.$router.go({ path: this.$router.currentRoute.path, force: true })
      },
      logout() {
        this.axios.delete(
          '/api/v1/manager/auth/sign_out'
        ).then(() =>{
          this.$store.dispatch('logout')
          this.$cookies.remove('authInfos')
          this.$cookies.remove('clubInfo')
          this.$router.push({ name: 'manager-top' })
        }).catch(err => {
          console.log(err)
        })
      }
    }
  };
</script>
