<template>
  <v-card :loading='loading'>
    <v-card-title>
      <v-row>
        <v-col cols='12' md='8'>
          <h3>最新のVoice投稿内容</h3>
        </v-col>
        <v-col cols='12' md='4' align='right'>
          <v-btn
            outlined
            v-on:click='$router.push({ name: "manager-voices-index" })'
          >
            Voice投稿内容一覧を確認
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-simple-table>
        <thead class='grey lighten-2'>
          <th class='pa-3 text-left'>ニックネーム</th>
          <th class='pa-3'>投稿内容</th>
          <th>投稿日時</th>
        </thead>
        <tbody>
          <tr
            v-for='voice in voices'
            :key='voice.id'
            v-on:click='$router.push({ name: "manager-voices-show", params: { id: voice.id } })'
          >
            <td>
              <v-avatar
                class='mr-2'
                size="36"
              >
                <v-img :src='voice.user.profile.icon.url' />
              </v-avatar>
              {{ voice.user.profile.nickname }}
            </td>
            <td>{{ voice.msg }}</td>
            <td class='text-right'>{{ voice.created_at | moment_format('lll') }}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    props: {
      voices: { type: Array, required: true },
      loading: { type: Boolean, required: true },
    },
  }
</script>
