<template>
  <v-app>
    <global-header v-if="isSignIn" v-model='drawer' />
    <global-drawer v-if="isSignIn" v-model='drawer' />

    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
  import GlobalHeader from './components/globals/Header.vue';
  import GlobalDrawer from './components/globals/Drawer.vue';

  export default {
    components: {
      GlobalHeader,
      GlobalDrawer
    },
    data: () => ({
      drawer: null
    }),
    computed: {
      isSignIn() {
        return this.$store.getters.admin || this.$store.getters.manager
      }
    },
    beforeCreate() {
      const existingAuth = this.$cookies.get('authInfos')

      if (existingAuth) {
        this.$store.dispatch('setAuth', existingAuth.tokens)
        if (existingAuth.admin) { this.$store.dispatch('loginAdmin', existingAuth.admin) }
        if (existingAuth.manager) {
          this.$store.dispatch('loginManager', existingAuth.manager)
          this.$store.dispatch('loginManagerClub')
        }
      }
    },
    created() {
      if (this.$store.getters.manager) { this.getManager() }
    },
    methods: {
      getManager() {
        this.axios.get(
          '/api/v1/manager/profile/reload'
        ).then(res => {
          this.$store.dispatch('loginManager', res.data.manager)
          this.$store.dispatch('loginManagerClub')
        }).catch(err => {
          console.log(err)
        })
      },
    },
    metaInfo() {
      return { titleTemplate: titleChunk => titleChunk ? `${titleChunk} - Fums` : 'Fums' }
    },
  };
</script>
