<template>
  <v-card :loading='loading'>
    <v-card-title>
      <v-row>
        <v-col cols='12' md='8'>
          <h3>Fumsからのお知らせ</h3>
        </v-col>
        <v-col cols='12' md='4' align='right'>
          <v-btn
            outlined
            v-on:click='noticesIndex'
          >
            Fumsからのお知らせ一覧を確認
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-simple-table>
        <thead class='grey lighten-2'>
          <th class='pa-3 text-left'>日時</th>
          <th class='pa-3 text-left'>タイトル</th>
        </thead>
        <tbody>
          <tr
            v-for='notice in notices'
            :key='notice.id'
            v-on:click='clickRow(notice)'
          >
            <td>{{ notice.disp_st_at | moment_format('lll') }}</td>
            <td>{{ notice.title }}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>
    <v-dialog
      v-model='dialog'
      :max-width='600'
    >
      <v-card>
        <v-card-title>{{ selectedNotice.title }}</v-card-title>
        <v-divider />
        <v-card-text>
          <v-chip
            class='my-4'
            small
          >
            {{ selectedNotice.disp_st_at | moment_format('lll') }}
          </v-chip>
          <div class='word-breaker'>{{ selectedNotice.desc }}</div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn v-on:click='dialog=false'>close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
  export default {
    props: {
      notices: { type: Array, required: true },
      loading: { type: Boolean, required: true },
    },
    data: () => ({
      dialog: false,
      selectedNotice: {}
    }),
    methods: {
      noticesIndex() {
        this.$router.push({ name: 'manager-notices-index' })
      },
      clickRow(notice) {
        this.selectedNotice = notice
        this.dialog = true
      },
    }
  }
</script>
