<template>
  <div>
    <div class='d-flex justify-center mt-6 mb-6'>
      <h1>Fums</h1>
    </div>

    <v-row justify='center'>
      <v-col cols='12' md='8'>
        <h2>パスワードの再設定</h2>
      </v-col>
      <v-col cols='12' md='8'>
        <validation-observer ref='observer'>
          <v-form v-on:submit.prevent='save'>
            <validation-provider
              v-slot='{ errors }'
              name='新しいパスワード'
              rules='required|min:8|confirmed:confirmation'
            >
              <v-text-field
                v-model='password.password'
                label='新しいパスワード'
                :append-icon="newPasswordShow ? 'mdi-eye' : 'mdi-eye-off'"
                :error-messages='errors'
                outlined
                counter
                :type="newPasswordShow ? 'text' : 'password'"
                @click:append="newPasswordShow = !newPasswordShow"
              ></v-text-field>
            </validation-provider>
            <validation-provider
              v-slot='{ errors }'
              vid='confirmation'
              name='新しいパスワード(確認)'
              rules='required|min:8'
            >
              <v-text-field
                v-model='password.password_confirmation'
                label='新しいパスワード(確認)'
                :append-icon="newPasswordConfirmationShow ? 'mdi-eye' : 'mdi-eye-off'"
                :error-messages='errors'
                outlined
                counter
                :type="newPasswordConfirmationShow ? 'text' : 'password'"
                @click:append="newPasswordConfirmationShow = !newPasswordConfirmationShow"
              ></v-text-field>
            </validation-provider>
            <v-row justify='center'>
              <v-btn
                type='submit'
                color='primary'
                class='px-16 font-weight-bold'
              >
                パスワードを変更する
              </v-btn>
            </v-row>
          </v-form>
        </validation-observer>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    data: () => ({
      newPasswordShow: false,
      newPasswordConfirmationShow: false,
      password: {
        password: '',
        password_confirmation: ''
      }
    }),
    methods: {
      save() {
        let headers = {
          'access-token': this.$route.query.token,
          'client': this.$route.query.client,
          'uid': this.$route.query.uid,
        }
        this.axios.put(
          '/api/v1/auth/password', this.password, { headers: headers }
        ).then(() => {
          this.$router.push({ name: 'user-passwords-reset-done' })
        }).catch(err => {
          console.log(err)
        })
      }
    },
    metaInfo() { return this.$t(`meta_tags.${this.$route.name}`) },
  }
</script>
