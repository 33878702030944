<template>
  <div class='pa-10'>
    <v-row class='mb-4'>
      <v-col cols='12' md='8'>
        <h2>お知らせ 一覧</h2>
      </v-col>
      <v-col cols='12' md='4' align='right'>
        <v-btn
          color='primary'
          v-on:click='$router.push({ name: "admin-notices-new" })'
        >
          新規登録
        </v-btn>
      </v-col>
    </v-row>

    <v-form
      v-on:submit.prevent='searchNotices'
      class='mb-10'
    >
      <v-row>
        <v-col cols='12' md='6'>
          <v-text-field
            v-model='search.title'
            label='タイトル'
            hide-details
            clearable
          />
        </v-col>
        <v-col cols='12' md='3'>
          <v-select
            v-model='search.gallery_type'
            label='メディア'
            hide-details
            clearable
            :items='selectItems.gallery_type'
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols='12' md='3'>
          <v-select
            v-model='search.target_type'
            label='送信対象'
            hide-details
            clearable
            :items='selectItems.target_type'
          />
        </v-col>
        <v-col cols='12' md='3'>
          <datetime
            v-model='search.disp_st_at_from'
            label='作成日From'
            hide-details
            type='datetime'
          />
        </v-col>
        <v-col cols='12' md='3'>
          <datetime
            v-model='search.disp_st_at_to'
            label='作成日To'
            hide-details
            type='datetime'
          />
        </v-col>
        <v-col cols='12' md='3' align='right'>
          <v-btn
            outlined
            type='submit'
            class='mt-3'
          >
            絞り込む
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <v-card>
      <v-data-table
        :loading='loading'
        :headers='headers'
        :items='notices'
        v-on:click:row='clickRow'
      >
        <template #item.target_type='{ item }'>
          {{ $$getEnumText('target_type', item.target_type) }}
        </template>
        <template #item.disp_st_at='{ item }'>
          {{ item.disp_st_at | moment_format('lll') }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
  export default {
    data: () => ({
      loading: false,
      search: {},
      selectItems: {},
      headers: [],
      notices: [],
    }),
    computed: {
      PATH() {
        const path = {}
        path.index = `/api/v1/admin/notices`
        path.search = `${path.index}/search`
        return path
      }
    },
    created() {
      this.init()
      this.getNotices(this.PATH.index)
    },
    methods: {
      init() {
        this.headers = [
          { text: 'タイトル', value: 'title' },
          { text: 'メディア', value: 'gallery_type' },
          { text: '送信対象', value: 'target_type' },
          { text: '表示開始日時', value: 'disp_st_at' },
        ]
        this.selectItems.gallery_type = this.$$selectEnums('gallery_type')
        this.selectItems.target_type = [
          { text: 'manager', value: 'manager' },
          { text: 'user', value: 'user' },
        ]
      },
      getNotices(path, params) {
        this.loading = true
        this.axios.get(
          path, { params: params }
        ).then(res => {
          this.notices = res.data.notices
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          this.loading = false
        })
      },
      searchNotices() {
        this.getNotices(this.PATH.search, this.search)
      },
      clickRow(item) {
        this.$router.push({ name: 'admin-notices-show', params: { id: item.id } })
      }
    },
    metaInfo() { return this.$t(`meta_tags.${this.$route.name}`) },
  }
</script>
