<template>
  <div class='pa-10'>
    <v-row class='mb-4'>
      <v-col cols='12' md='8'>
        <h2>コミュニティ</h2>
      </v-col>
      <v-col cols='12' md='4' align='right'>
        <v-btn
          color='primary'
          v-on:click='toIndex'
        >
          一覧に戻る
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <validation-observer ref='observer'>
      <v-form v-on:submit.prevent='save'>
        <v-card
          :loading='loading'
          class='mb-8'
        >
          <v-card-text>
            <validation-provider
              v-slot='{ errors }'
              name='コミュニティ名'
              rules='required|max:100'
            >
              <v-text-field
                v-model='club.name'
                label='コミュニティ名'
                :error-messages='errors'
                outlined
                counter='100'
              />
            </validation-provider>
            <validation-provider
              v-slot='{ errors }'
              name='コミュニティ名（ひらがな）'
              rules='required|max:100|zenHira'
            >
              <v-text-field
                v-model='club.name_hira'
                label='コミュニティ名（ひらがな）'
                :error-messages='errors'
                outlined
                counter='100'
              />
            </validation-provider>
            <validation-provider
              v-slot='{ errors }'
              name='カテゴリー'
              rules='required'
            >
              <v-select
                v-model='club.category_id'
                label='カテゴリー'
                :error-messages='errors'
                :items='selectItems.categories'
                outlined
                counter='100'
              />
            </validation-provider>
            <validation-provider
              v-slot='{ errors }'
              name='アーティスト名'
              rules='required|max:100'
            >
              <v-text-field
                v-model='club.main_tag.name'
                label='アーティスト名'
                :error-messages='errors'
                outlined
                counter='100'
              />
            </validation-provider>
            <v-row>
              <v-col class='my-0' md='6' cols='12'>
                <validation-provider
                  v-slot='{ errors }'
                  name='月額'
                  rules='required'
                >
                  <v-select
                    v-model='club.clubs_plans[0].price'
                    label='月額'
                    :items='PRICES.month'
                    :error-messages='errors'
                    clearable
                    outlined
                  />
                </validation-provider>
              </v-col>
              <v-col class='my-0' md='6' cols='12'>
                <validation-provider
                  v-slot='{ errors }'
                  name='年額'
                  :rules='yearPriceRule'
                >
                  <v-select
                    v-model='club.clubs_plans[1].price'
                    label='年額'
                    :items='PRICES.year'
                    :error-messages='errors'
                    clearable
                    outlined
                  />
                </validation-provider>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-row justify='center'>
          <v-btn
            :loading='loading'
            type='submit'
            color='primary'
            class='px-16'
            outlined
          >
            登録する
          </v-btn>
        </v-row>
      </v-form>
    </validation-observer>
  </div>
</template>

<script>
  export default {
    data: () => ({
      loading: false,
      selectItems: {
        categories: [],
      },
      club: {
        name: '',
        name_hira: '',
        main_tag: {
          name: '',
        },
        clubs_plans: [
          { price: '', payment_type: 'month' },
          { price: '', payment_type: 'year' },
        ]
      },
    }),
    created() {
      this.init()
      this.getClub()
    },
    computed: {
      isNewForm() {
        return !this.$route.params.id
      },
      yearPriceRule() {
        if (!this.club.clubs_plans[0].price) { return }
        const minValue = this.club.clubs_plans[0].price * 12
        return `max_value:${minValue}`
      },
      PATH() {
        return {
          categories: `/api/v1/admin/categories`,
          create: `/api/v1/admin/clubs`,
          edit:   `/api/v1/admin/clubs/${this.$route.params.id}`,
          update: `/api/v1/admin/clubs/${this.$route.params.id}`,
        }
      },
      PRICES() {
        return {
          month: [0, 300, 400, 500, 600, 700, 800, 900, 1000, 1200, 1400, 1600, 1800, 2000, 2500, 3000, 3500, 4000, 4500, 5000, 6000, 7000, 8000, 9000, 10000],
          year: [3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000, 12000, 14000, 15800, 17800, 19800, 24800, 29800, 34800, 38800, 44800, 48800, 58800, 68800, 78800, 88800, 98800],
        }
      },
    },
    methods: {
      init() {
        this.getCategories()
      },
      getCategories() {
        this.axios.get(this.PATH.categories).then(res => {
          this.selectItems.categories = res.data.categories.map(category => {
            return { text: category.name, value: category.id }
          })
        }).catch(err => {
          console.log(err)
        })
      },
      getClub() {
        if (this.isNewForm) { return }
        this.loading = true
        this.axios.get(this.PATH.edit).then(res => {
          this.club = res.data.club
          const yearPriceExist = this.club.clubs_plans.some(clubs_plan => {
            return clubs_plan.payment_type === 'year'
          })
          if (!yearPriceExist) {
            this.club.clubs_plans.push({ price: null, payment_type: 'year' })
          }
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          this.loading = false
        })
      },
      async save() {
        if (this.loading) { return }
        const isValid = await this.$refs.observer.validate()
        if (!isValid) { return this.$toasted.error(this.$t('errors.params')) }
        this.loading = true
        const path = this.isNewForm ? this.PATH.create : this.PATH.update
        const saveFunction = this.isNewForm ? this.axios.post : this.axios.patch
        saveFunction(
          path,
          this.$$castToFormData({ club: this.club }),
          { headers: { "content-type": "multipart/form-data" } }
        ).then(res => {
          this.club = res.data.club
          this.$router.push({ name: 'admin-clubs-show', params: { id: this.club.id } })
          this.$toasted.success(this.$t('success.update'))
        }).catch(err => {
          if (err.response.status !== 422) { return }
          this.$toasted.error(err.response.data.errors.join("\n"))
        }).finally(() => {
          this.loading = false
        })
      },
      toIndex() {
        this.$router.push({ name: 'admin-clubs-index' })
      },
    },
    metaInfo() { return this.$t(`meta_tags.${this.$route.name}`) },
  }
</script>
