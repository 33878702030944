import {
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
  localize,
  extend,
} from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import ja from "vee-validate/dist/locale/ja.json"

const VeeValidate = {
  install(Vue) {
    Vue.component('ValidationProvider', ValidationProvider)
    Vue.component('ValidationObserver', ValidationObserver)

    setInteractionMode('eager')
    localize("ja", ja)

    Object.keys(rules).forEach(rule => {
      extend(rule, rules[rule])
    })

    // ----------------------------------
    // 全角ひらがな
    // ----------------------------------
    extend('zenHira', {
      message: `{_field_}はひらがなで入力してください`,
      validate(value) {
        return value.match(/^[ぁ-んー－]+$/)
      }
    })
  }
}

export default VeeValidate
