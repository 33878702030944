import UserAuthConfirm from '@/views/auth/user/Confirm';
import UserPasswordsReset from '@/views/user/passwords/Reset.vue';
import UserPasswordsResetDone from '@/views/user/passwords/ResetDone.vue';

export default [
  {
    // このpathはdevise_token_auth依存なので触らないこと
    path: '/auth/user/confirm',
    name: 'auth-user-confirm',
    component: UserAuthConfirm,
    meta: { isPublic: true }
  },
  {
    path: '/user/passwords/reset',
    name: 'user-passwords-reset',
    component: UserPasswordsReset,
    meta: { isPublic: true }
  },
  {
    path: '/user/passwords/reset_done',
    name: 'user-passwords-reset-done',
    component: UserPasswordsResetDone,
    meta: { isPublic: true }
  },
];
