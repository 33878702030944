<template>
  <div>
    <div class="d-flex justify-center mt-6 mb-6">
      <h1>Fums for Admin</h1>
    </div>

    <v-row justify='center'>
      <v-col cols='12' md='8'>
        <v-form
          v-on:submit.prevent='login'
        >
          <v-text-field
            v-model="auth.email"
            type="email"
            label="E-mail"
            placeholder="Please Enter E-mail."
            outlined
          ></v-text-field>
          <v-text-field
            v-model="auth.password"
            type="password"
            label="Password"
            placeholder="Please Enter Password."
            outlined
          ></v-text-field>
          <v-row justify='center'>
            <v-col cols="8">
              <v-btn
                type='submit'
                color="black"
                block
                dark
                x-large
              >LOGIN</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    data: () => ({
      auth: {
        email: '',
        password: ''
      }
    }),
    methods: {
      login() {
        this.axios.post(
          '/api/v1/admin/auth/sign_in', this.auth
        ).then(res => {
          this.$store.dispatch('loginAdmin', res.data.data)
          this.$router.push({ name: 'admin-dashboard' })
        }).catch(() => {
        })
      }
    },
    metaInfo() { return this.$t(`meta_tags.${this.$route.name}`) },
  }
</script>
