import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'

const VueDatetime = {
  install(Vue) {
    Vue.component('VueDatetime', Datetime)
  }
}

export default VueDatetime
