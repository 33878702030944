import moment from 'moment'
export default {
  filters: {
    moment_format(value, format) {
      if (!value) return ''
      moment.locale('ja') // TODO i18n等を入れたときに合わせて変更できるようにすべき
      return moment(value).format(format)
    }
  },
  methods: {
    // =====================================
    // rails独自のformatに合わせたcastが必要
    // =====================================
    $$castToFormData(prms) {
      const form = new FormData()
      const makeAppendData = function(form, key, val) {
        const klass = Object.prototype.toString.call(val).slice(8, -1)
        if (klass === 'Array') {
          return val.forEach(childVal => { makeAppendData(form, `${key}[]`, childVal) })
        }
        if (klass !== 'Object') { return form.append(key, val) }
        for (let [k, v] of Object.entries(val)) { makeAppendData(form, `${key}[${k}]`, v) }
      }
      for (let [key, val] of Object.entries(prms)) { makeAppendData(form, key, val) }
      return form
    },
    // =====================================
    // 要素の有無チェックはよく使うので定義
    // =====================================
    $$exist(val) {
      if (!val) {return false}
      const cls = Object.prototype.toString.call(val).slice(8, -1)
      if (cls === 'Array') {return val.length > 0}
      if (cls === 'Object') {return Object.keys(val).length > 0}
      return val.length > 0
    },
    $$blank(val) {
      return !this.$$exist(val)
    },
    // =====================================
    // localeファイルからselectを構成
    // =====================================
    $$selectEnums(val) {
      const enums = []
      const locales = this.$t(`enum.${val}`)
      Object.keys(locales).forEach(key => {
        enums.push({ text: locales[key], value: key })
      })
      return enums
    },
    // =====================================
    // localeファイルからselectを構成
    // =====================================
    $$getEnumText(key, val) {
      if (!val) { return null }
      return this.$t(`enum.${key}.${val}`)
    },
  },
}
