<template>
  <v-app-bar
    app
    color="white"
    clipped-left
  >
    <v-app-bar-nav-icon @click.stop='internalValue = !internalValue' />
    <v-toolbar-title>
      <h3>Fums</h3>
    </v-toolbar-title>

    <v-spacer />

    <AdminHeaderMenu v-if='isSignInType.admin' />
    <ManagerHeaderMenu v-if='isSignInType.manager' />
  </v-app-bar>
</template>

<script>
  import AdminHeaderMenu from '../globals/admin/HeaderMenu.vue';
  import ManagerHeaderMenu from '../globals/manager/HeaderMenu.vue';

  export default {
    components: {
      AdminHeaderMenu,
      ManagerHeaderMenu
    },
    props: {
      value: { required: true },
    },
    computed: {
      internalValue: {
        get() {return this.value},
        set(value) {this.$emit('input', value)},
      },
      isSignInType() {
        return {
          admin: this.$store.getters.admin,
          manager: this.$store.getters.manager
        }
      }
    }
  };
</script>
