<template>
  <div class="pa-10">
    <v-row class='mb-4'>
      <v-col cols='12' md='8'>
        <h2>セッティング</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <default-club
          :loading='loading.defaultClub'
          :club='club'
          v-on:edit='dialog.defaultClub = true'
        />
        <default-club-select
          v-model='dialog.defaultClub'
          :club='club'
          :clubs='clubs'
          v-on:save='defaultClubSave'
        />
      </v-col>
      <v-col>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import DefaultClub from './Setting/DefaultClub'
  import DefaultClubSelect from './Setting/DefaultClubSelect'
  export default {
    components: {
      DefaultClub,
      DefaultClubSelect,
    },
    data: () => ({
      loading: {
        defaultClub: false
      },
      dialog: {
        defaultClub: false
      },
      club: {},
      clubs: [],
    }),
    computed: {
      PATH() {
        return {
          defaultClub: `/api/v1/admin/master_settings/default_club`,
          create: `/api/v1/admin/master_settings`,
          clubs: `/api/v1/admin/clubs`,
        }
      },
    },
    created() {
      this.getDefaultClub()
      this.getClubs()
    },
    methods: {
      getDefaultClub() {
        this.loading.defaultClub = true
        this.axios.get(
          this.PATH.defaultClub,
          { params: { setting_type: 'default_club' } }
        ).then(res => {
          this.club = res.data.club
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          this.loading.defaultClub = false
        })
      },
      getClubs() {
        this.axios.get(
          this.PATH.clubs
        ).then(res => {
          this.clubs = res.data.clubs
        }).catch(err => {
          console.log(err)
        })
      },
      defaultClubSave(clubId) {
        this.loading.defaultClub = true
        this.axios.post(
          this.PATH.create,
          { setting_type: 'default_club', setting_val: clubId }
        ).then(res => {
          this.getDefaultClub()
          this.$toasted.success(this.$t(`success.${res.data}`))
        }).catch(err => {
          console.log(err)
          this.loading.defaultClub = false
        })
      },
    },
    metaInfo() { return this.$t(`meta_tags.${this.$route.name}`) },
  }
</script>
