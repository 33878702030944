<template>
  <v-list
    nav
    expand
  >
    <v-list-item
      link
      v-for="list in drawerLists"
      :key="list.title"
      :to="list.url"
    >
      <v-list-item-action>
        <v-icon>{{ list.icon }}</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>{{ list.title }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
  export default {
    name: 'ManagerDrawerList',
    data: () => ({
      drawerLists: [
        { title: 'Home', icon: 'mdi-home', url: { name: 'manager-dashboard' }},
        { title: 'About', icon: 'mdi-card-account-details', url: { name: 'manager-clubs-show' }},
        { title: 'Member', icon: 'mdi-account-group', url: { name: 'manager-users-index' }},
        { title: 'Now', icon: 'mdi-chat-processing', url: { name: 'manager-murmurs-index' }},
        { title: 'Info', icon: 'mdi-file-document', url: { name: 'manager-infos-index' }},
        { title: 'Gallery', icon: 'mdi-image-area', url: { name: 'manager-galleries-index' }},
        { title: 'Event', icon: 'mdi-calendar-month', url: { name: 'manager-activities-index' }},
        { title: 'Voice', icon: 'mdi-chat', url: { name: 'manager-voices-index' }},
        { title: 'MessageBox', icon: 'mdi-email', url: { name: 'manager-message-boxes-index' }},
        { title: 'Account', icon: 'mdi-account-tie', url: { name: 'manager-profiles-show' }},
        { title: 'Password', icon: 'mdi-lock', url: { name: 'manager-settings-password' }},
      ]
    })
  };
</script>
