<template>
  <div>
    <div class='d-flex justify-center my-6'>
      <h1>Fums</h1>
    </div>

    <v-row class='mb-4 text-center' justify='center'>
      <v-col cols='12' md='8'>
        <p class='font-weight-black'>
          登録しているメールアドレスを入力してください。
        </p>
        <p>
          パスワードを再設定するためのURLをお送りいたします。
        </p>
      </v-col>

      <v-col cols='12' md='8'>
        <v-form
          v-on:submit.prevent='send'
        >
          <v-text-field
            v-model='manager.email'
            type='email'
            label='E-mail'
            placeholder='Please Enter E-mail.'
            outlined
          />
          <v-btn
            type='submit'
            class='px-8'
            color='primary'
            outlined
          >
            送信する
          </v-btn>
        </v-form>
        <v-row class='mt-5'>
          <v-col align='right'>
            <v-btn
              v-on:click='toManagerTop'
            >
              ログイン画面に戻る
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    data: () => ({
      manager: {
        email: ''
      }
    }),
    methods: {
      send() {
        this.axios.post(
          '/api/v1/manager/auth/password', this.manager
        ).then(() => {
          this.$router.push({ name: 'manager-top' })
          this.$toasted.success(this.$t('success.email'))
        }).catch(err => {
          if (err.response.status !== 422) { return }
          this.$toasted.error(err.response.data.errors.join("\n"))
        })
      },
      toManagerTop() {
        this.$router.push({ name: 'manager-top' })
      }
    },
    metaInfo() { return this.$t(`meta_tags.${this.$route.name}`) },
  }
</script>
