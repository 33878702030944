<template>
    <v-card :loading='loading'>
      <v-simple-table
        class='mouse-over-highlight-disabled'
      >
        <tbody>
          <tr>
            <td class='font-weight-bold'>投稿内容</td>
            <td class='word-breaker'>{{ timeline.gallery.comment }}</td>
          </tr>
          <tr>
            <td class='font-weight-bold'>メディア</td>
            <td>
              <FilePondForTransmissions
                v-if='timeline.transmissions.length > 0'
                v-model='timeline.transmissions'
                :maxFiles='timeline.transmissions.length'
                :disabled='true'
              />
            </td>
          </tr>
          <tr>
            <td class='font-weight-bold'>投稿日時</td>
            <td>{{ timeline.disp_st_at | moment_format('lll') }}</td>
          </tr>
          <tr>
            <td class='font-weight-bold'>ステータス</td>
            <td>{{ $$getEnumText('club_item_status', timeline.club_item_status) }}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card>
</template>

<script>
  import FilePondForTransmissions from '@/components/plugins/FilePondForTransmissions'
  export default {
    components: {
      FilePondForTransmissions,
    },
    props: {
      timeline: { type: Object, required: true },
      loading: { type: Boolean, required: true },
    },
  }
</script>
