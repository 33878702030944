var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-10"},[_c('v-row',{staticClass:"mb-4"},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('h2',[_vm._v("マネージャー情報編集")])])],1),_c('v-card',{staticClass:"mb-6 pa-6"},[_c('validation-observer',{ref:"observer"},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.update($event)}}},[_c('validation-provider',{attrs:{"name":"担当者氏名","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"担当者氏名","error-messages":errors},model:{value:(_vm.profile.staff_name),callback:function ($$v) {_vm.$set(_vm.profile, "staff_name", $$v)},expression:"profile.staff_name"}})]}}])}),_c('validation-provider',{attrs:{"name":"電話番号","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"電話番号","error-messages":errors},model:{value:(_vm.profile.phone),callback:function ($$v) {_vm.$set(_vm.profile, "phone", $$v)},expression:"profile.phone"}})]}}])}),_c('validation-provider',{attrs:{"name":"会社名","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"会社名","error-messages":errors},model:{value:(_vm.profile.corporation_name),callback:function ($$v) {_vm.$set(_vm.profile, "corporation_name", $$v)},expression:"profile.corporation_name"}})]}}])}),_c('validation-provider',{attrs:{"name":"会社名","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"ホームページURL","error-messages":errors},model:{value:(_vm.profile.homepage_url),callback:function ($$v) {_vm.$set(_vm.profile, "homepage_url", $$v)},expression:"profile.homepage_url"}})]}}])}),_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"px-16 mt-5 font-weight-bold",attrs:{"type":"submit","color":"primary"}},[_vm._v(" 編集内容を保存する ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }